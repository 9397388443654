import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'acr-promos-select-method',
  templateUrl: './promos-select-method.component.html',
})
export class PromosSelectMethodComponent {
  @Output() keyboardSelected = new EventEmitter<void>();
  @Output() scanSelected = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
}
