import { Injectable } from '@angular/core';
import {
  addToCartEvent,
  calculateButtonPrice,
  DotButton,
  DotConditionsService,
  DotSessionService,
  OrderDiscountResponse,
  PromotionQuery,
  PromotionsService,
  removeFromCartEvent,
} from 'dotsdk';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { Router } from '@angular/router';
import { generateUUID } from '@dotxix/helpers/uuid.helper';
import { AppRoutes } from '@dotxix/app-routes';
import { SessionService } from '@dotxix/services/session.service';
import { BasketProductRemovalService } from '@dotxix/services/basket-product-removal.service';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import {
  hideModifiersWithQuantityEqualToDefaultQuantityInElog,
  updateRemoveFlagForModifiersWithDefaultQuantity,
} from '@dotxix/helpers/modifiers.helper';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import {
  addBasketButton,
  areButtonsSimilar,
  removeBasketButtonByUuid,
  removeDuplicates,
  replaceBasketButtonByUuid,
  updateParentLinkUUID,
} from '@dotxix/helpers/basket.helper';
import { setButtonStateOrderDiscount } from '@dotxix/helpers/order-discount.helper';
import { RecommendationActionView } from '@dotxix/services/suggestions/models/recommendation-action-view';
import { appLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  public buttons$: BehaviorSubject<DotButton<ButtonState>[]> = new BehaviorSubject<DotButton<ButtonState>[]>([]);
  public numberOfItems$: BehaviorSubject<number> = new BehaviorSubject(0);
  public allowToCheckout$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public openBasketRequested = new Subject<void>();
  public closeBasketRequested = new Subject<void>();

  private _buttons: DotButton<ButtonState>[] = [];

  public get buttons(): DotButton<ButtonState>[] {
    return this.buttons$.value;
  }

  public get totalPrice(): number {
    return this.calculateTotalPrice(this._buttons);
  }

  constructor(
    private sessionService: SessionService,
    private applicationSettingsService: ApplicationSettingsService,
    private recoService: RecommendationService,
    private router: Router,
    private basketProductRemovalService: BasketProductRemovalService,
    private appSettings: ApplicationSettingsService
  ) {
    this.sessionService.onRestartSession.subscribe(() => this.resetBasket());
    this.buttons$.subscribe((buttons) =>
      this.numberOfItems$.next(buttons.reduce((totalQuantity: number, button: DotButton) => totalQuantity + button.quantity, 0))
    );
    this.buttons$.subscribe((buttons) => {
      this.allowToCheckout$.next(this.isCheckoutAllowed(buttons));
    });
  }

  public addButtonToBasket(button: DotButton<ButtonState>): DotButton<ButtonState> {
    appLogger.debug(`add button with Link: ${button.Link} and quantity: ${button.quantity} to basket`);
    let returnButton;
    button = _.cloneDeep(button);
    button.quantity = button.quantity || 1;
    if (this.applicationSettingsService.settings$.value.sendModifiersWithZeroQuantity) {
      updateRemoveFlagForModifiersWithDefaultQuantity(button);
    }
    if (!this.appSettings.settings$.value.addDefaultModifiersToElog) {
      hideModifiersWithQuantityEqualToDefaultQuantityInElog(button);
    }

    const existingBasketButton: DotButton<ButtonState> | undefined = this._buttons.find((b) => areButtonsSimilar(b, button));
    if (existingBasketButton) {
      existingBasketButton.quantity += button.quantity;
      if (button.state.parentLinkUUID) {
        this._buttons = updateParentLinkUUID(existingBasketButton, button.state.parentLinkUUID, this._buttons);
      }
      returnButton = this._buttons.find((button) => button.uuid === existingBasketButton.uuid) ?? existingBasketButton;
    } else {
      button.uuid = button.uuid || generateUUID();
      this._buttons = addBasketButton(button, this._buttons, this.applicationSettingsService.settings$.value.reverseBasketOrder);
      returnButton = button;
    }

    this.emitAddUpdates();
    return returnButton;
  }

  public updateButton(updatedButton: DotButton<ButtonState>) {
    appLogger.debug(`update button with Link: ${updatedButton.Link} and quantity: ${updatedButton.quantity} to basket`);
    if (this.applicationSettingsService.settings$.value.sendModifiersWithZeroQuantity) {
      updateRemoveFlagForModifiersWithDefaultQuantity(updatedButton);
    }
    if (!this.appSettings.settings$.value.addDefaultModifiersToElog) {
      hideModifiersWithQuantityEqualToDefaultQuantityInElog(updatedButton);
    }

    const indexSameUuid = this._buttons.findIndex((b) => updatedButton.uuid === b.uuid);
    const indexIdenticalButton = this._buttons.findIndex((b) => areButtonsSimilar(b, updatedButton));

    if (indexIdenticalButton >= 0 && indexSameUuid !== indexIdenticalButton) {
      const existingIdenticalBasketButtonClone = _.cloneDeep(this._buttons[indexIdenticalButton]);
      existingIdenticalBasketButtonClone.quantity += updatedButton.quantity;
      let newButtons = replaceBasketButtonByUuid(this._buttons, existingIdenticalBasketButtonClone);
      if (updatedButton.state.parentLinkUUID) {
        newButtons = updateParentLinkUUID(existingIdenticalBasketButtonClone, updatedButton.state.parentLinkUUID, this._buttons);
        newButtons = removeDuplicates(updatedButton.state.parentLinkUUID, newButtons);
      }
      newButtons = removeBasketButtonByUuid(newButtons, updatedButton);
      this._buttons = newButtons;
    } else {
      this._buttons = replaceBasketButtonByUuid(this._buttons, updatedButton);
    }
    this.emitAddUpdates();
  }

  public increaseButtonQuantity(button: DotButton<ButtonState>, addQuantity: number) {
    appLogger.debug(`increase button quantity: Link ${button.Link}`);
    const clonedButton = _.cloneDeep(button);
    clonedButton.quantity += addQuantity;

    this._buttons = replaceBasketButtonByUuid(this._buttons, clonedButton);
    this.emitAddUpdates();
    return clonedButton;
  }

  public removeButtonFromBasket(button: DotButton<ButtonState>, removeAllButtons = false) {
    appLogger.debug(`remove ${removeAllButtons ? 'completely' : ''} button from basket: Link ${button.Link}`);
    let clonedBasketButtons = _.cloneDeep(this._buttons);
    const index = clonedBasketButtons.findIndex((clonedBasketButton) => clonedBasketButton.uuid === button.uuid);
    if (index === -1) {
      if (Array.isArray(button.state.promoChildButtons) && button.state.promoChildButtons.length > 0) {
        button.state.promoChildButtons.forEach((promoChildButton) => this.removeButtonFromBasket(promoChildButton));
      }
      return;
    }

    const basketButtonClone = clonedBasketButtons[index];

    if (removeAllButtons || basketButtonClone.quantity === 1) {
      basketButtonClone.quantity = 0;
      clonedBasketButtons.splice(index, 1);
      if (button.state.parentLinkUUID) {
        clonedBasketButtons = clonedBasketButtons.filter((btn) => btn.state.childLinkUUID !== button.state.parentLinkUUID);
      }
      clonedBasketButtons = clonedBasketButtons.filter(
        (btn) => !btn.IFC || (btn.IFC && DotConditionsService.getInstance().evaluateCondition(btn.IFC, clonedBasketButtons))
      );
    } else {
      basketButtonClone.quantity--;
    }

    this._buttons = clonedBasketButtons;

    this.emitRemoveUpdates();
  }

  public async changeProductQuantity(basketButton: DotButton, quantity: 1 | -1) {
    appLogger.debug(`change button quantity (${quantity}) in basket: Link ${basketButton.Link}`);
    if (quantity > 0) {
      if (this.applicationSettingsService.settings$.value.enableRecoModule) {
        this.recoService.userAction(RecommendationActionView.BasketPlus, basketButton, 1).catch(() => {});
      }
      this.increaseButtonQuantity(basketButton, 1);
    } else {
      const completelyRemove = basketButton.quantity <= 1;
      const removalAllowed = completelyRemove ? await this.basketProductRemovalService.confirmProductRemoval(basketButton) : true;
      if (removalAllowed) {
        if (this.applicationSettingsService.settings$.value.enableRecoModule) {
          if (this.applicationSettingsService.settings$.value.productRemovalWarning && completelyRemove) {
            this.recoService.userAction(RecommendationActionView.ConfirmationMinus, basketButton, -basketButton.quantity).catch(() => {});
          } else {
            this.recoService.userAction(RecommendationActionView.BasketMinus, basketButton, -1).catch(() => {});
          }
        }
        this.removeButtonFromBasket(basketButton);
        if (completelyRemove) {
          this.removeLinkedPromotions(basketButton);
        }
        this.onProductRemovedFromCart();
      }
    }
  }

  public async removeProductWithConfirmation(basketButton: DotButton) {
    if (await this.basketProductRemovalService.confirmProductRemoval(basketButton)) {
      if (this.applicationSettingsService.settings$.value.enableRecoModule) {
        this.recoService
          .userAction(
            this.applicationSettingsService.settings$.value.productRemovalWarning
              ? RecommendationActionView.ConfirmationMinus
              : RecommendationActionView.BasketMinus,
            basketButton,
            -basketButton.quantity
          )
          .catch(() => {});
      }
      this.removeButtonFromBasket(basketButton, true);
      this.removeLinkedPromotions(basketButton);
      this.onProductRemovedFromCart();
    }
  }

  public addOrderDiscountInfoButton(button: DotButton<ButtonState>) {
    const currentOrderDiscountInfoButton = this.getOrderDiscountInfoButton();
    if (currentOrderDiscountInfoButton) {
      this._buttons = removeBasketButtonByUuid(this._buttons, currentOrderDiscountInfoButton);
    }
    this._buttons = addBasketButton(button, this._buttons, this.applicationSettingsService.settings$.value.reverseBasketOrder);
    addToCartEvent.emit(this._buttons);
    this.buttons$.next(this._buttons);
  }

  private emitAddUpdates() {
    addToCartEvent.emit(this._buttons);
    this.buttons$.next(this._buttons);
    this.checkOrderDiscount();
  }

  private emitRemoveUpdates() {
    removeFromCartEvent.emit(this._buttons);
    this.buttons$.next(this._buttons);
    this.checkOrderDiscount();
  }

  private onProductRemovedFromCart() {
    if (this.buttons.length <= 0) {
      this.closeBasket();
    }
  }

  private removeLinkedPromotions(basketButton: DotButton<ButtonState>) {
    if (basketButton.state.promoMetadata?.UUID) {
      this.buttons
        .filter((btn: DotButton<ButtonState>) => btn.state.promoMetadata?.UUID === basketButton.state.promoMetadata?.UUID)
        .forEach((b) => this.removeButtonFromBasket(b));
    }
  }

  public resetBasket(): void {
    this._buttons = [];
    this.buttons$.next(this._buttons);
    appLogger.debug(`basket reset`);
  }
  public calculateTotalPrice(buttons: DotButton[]): number {
    return buttons.reduce((acc: number, button: DotButton) => {
      return acc + Number(calculateButtonPrice(button, DotSessionService.getInstance().currentPosServingLocation)) * button.quantity;
    }, 0);
  }

  public openBasket() {
    if (this.router.url === '/' + AppRoutes.CodView) {
      return;
    }

    this.openBasketRequested.next();
  }

  public closeBasket() {
    if (this.router.url === '/' + AppRoutes.CodView) {
      return;
    }

    this.closeBasketRequested.next();
  }

  public getOrderDiscountInfoButton(): DotButton<ButtonState> | undefined {
    return this._buttons.find((btn: DotButton<ButtonState>) => Number.isInteger(btn.state.$$OrderDiscount));
  }

  private checkOrderDiscount() {
    const orderDiscountButton: DotButton<ButtonState> | undefined = this.getOrderDiscountInfoButton();
    if (orderDiscountButton) {
      const promotionQuery: PromotionQuery | null = PromotionsService.getInstance().reevaluateOrderDiscount();
      if (promotionQuery) {
        const payload = promotionQuery.promoPayload as OrderDiscountResponse;
        setButtonStateOrderDiscount(orderDiscountButton, payload);
      }
    }
  }

  public isCheckoutAllowed(buttons: DotButton[]): boolean {
    const quantityButtons = this.numberOfItems$.value;
    const discountAmountButton: DotButton<ButtonState> | undefined = this.getOrderDiscountInfoButton();
    const basketOrderTotal =
      discountAmountButton && Number.isInteger(discountAmountButton.state.$$OrderDiscount)
        ? this.totalPrice - (discountAmountButton.state.$$OrderDiscount as number)
        : this.totalPrice;
    if (quantityButtons > 1 && basketOrderTotal >= this.appSettings.settings$.getValue().minOrderAmount) {
      return true;
    }
    if (quantityButtons === 0 || basketOrderTotal < this.appSettings.settings$.getValue().minOrderAmount) {
      return false;
    }
    // Checking out with a basket containing only with the order discount button is forbidden
    return !(
      quantityButtons === 1 &&
      buttons.some(
        (button: DotButton<ButtonState>) => typeof button.state.$$OrderDiscount === 'number' && button.state.$$OrderDiscount >= 0
      )
    );
  }
}
