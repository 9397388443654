import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-glory-cash-cash-back-success',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001036'"
      [amounts]="[{ label: '2001032', value: amountRefunded }]"
    ></acr-glory-cash-message>

    <!-- Amount refunded: {amountRefunded}-->

    <!-- Your refund was processed. |Please take your cash. --> `,
})
export class GloryCashCashBackSuccessComponent {
  public amountRefunded;
  constructor(private paymentService: PaymentService) {
    this.amountRefunded = this.paymentService.state$.value.cash.cashBack.cashBackRefundedAmount;
  }
}
