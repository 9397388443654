import { Component } from '@angular/core';
import { ElectronicReceiptFlowService } from '@dotxix/services/receipt/electronic-receipt-flow.service';

@Component({
  selector: 'acr-electronic-receipt-page',
  templateUrl: './electronic-receipt-page.component.html',
})
export class ElectronicReceiptPageComponent {
  public $loading = this.electronicReceiptFlowService.$storingReceiptContent;
  public $receiptUrl = this.electronicReceiptFlowService.$electronicReceiptUrl;
  public QrUrl = 'https://ereceipt.acrelec.com/93b6195472c54a58.html?m=bucharest';
  constructor(private electronicReceiptFlowService: ElectronicReceiptFlowService) {}
  public continueClicked() {
    this.electronicReceiptFlowService.userConfirmedElectronicReceiptDelivery();
  }
  public backClicked() {
    this.electronicReceiptFlowService.userRequestedToReturnToReceiptOptions();
  }
}
