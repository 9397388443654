<main class="page-container electronic-receipt-page">
  <header class="page-header electronic-receipt-page__header">
    <img class="logo" src="/Channels/skin/logo.svg" alt="" width="200rem" height="200rem" />
  </header>

  <section class="electronic-receipt-page__container flex flex--column width100 m--auto">
    <div class="electronic-receipt-page__message flex-center width80 m--auto text-center">
      <p *ngIf="$loading | async" class="text--center fs--60 uppercase">{{ '2003006' | translate }}</p>
      <p *ngIf="$receiptUrl | async" class="text--center fs--60 uppercase">{{ '2003007' | translate }}</p>
    </div>
    <div class="electronic-receipt-page__qr-code flex-center">
      <img
        *ngIf="$loading | async"
        class="qr-code-animation"
        src="/Channels/skin/qr-code-animation.gif"
        alt=""
        width="300rem"
        height="300rem"
      />
      <ng-container *ngIf="$receiptUrl | async as receiptUrl">
        <qrcode [qrdata]="QrUrl" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
      </ng-container>
    </div>
    <div class="electronic-receipt-page__continue-button-container flex-center">
      <button
        *ngIf="$receiptUrl | async"
        class="ui-button ui-button--secondary ui-button--large fs--40"
        data-automation="Continue"
        (click)="continueClicked()"
      >
        <span class="ui-button__wrapper">
          <span>{{ '28' | translate }}</span>
        </span>
      </button>
    </div>
  </section>

  <acr-footer>
    <button *ngIf="$receiptUrl | async" left-slot class="ui-button ui-button--primary" data-automation="Back" (click)="backClicked()">
      <span class="ui-button__wrapper">
        <span>{{ '9' | translate }}</span>
      </span>
    </button>
  </acr-footer>
</main>
