<div class="popup allergen-selection" data-automation="allergen-selection-popup">
  <div class="popup__content allergen-selection__content">
    <main class="popup__page-container flex justify-center place-center flex--column">
      <h3 class="text-center color--white">{{ '3' | translate }}</h3>
      <button class="ui-button ui-button--primary" data-automation="Button Allergens Info" (click)="tabbedContentClick()">
        <span class="ui-button__wrapper">
          <span *ngIf="!displayTabbedContent" class="fs--26">{{ '2020012203' | translate }}</span>
          <span *ngIf="displayTabbedContent" class="fs--26">{{ '16' | translate }}</span>
        </span>
      </button>
      <section *ngIf="displayTabbedContent" class="pt--40 pb--40 pr--80 pl--80">
        <p class="fs--32 text-left color--white" [innerHTML]="'2020012202' | translate"></p>
      </section>
      <section *ngIf="!displayTabbedContent" class="hideScrollbar allergen-selection__list">
        <button
          *ngFor="let allergen of allergens"
          (click)="allergensClick(allergen)"
          class="allergen-selection__allergen"
          [ngClass]="{ 'allergen-selection__allergen--selected': isAllergenSelected(allergen) }"
          [attr.data-automation]="'Allergen Button: ' + (allergen | translateAllergenLabel)"
        >
          <img
            *ngIf="isAllergenIconAvailable(allergen)"
            skin-part="image"
            alt=""
            [src]="
              isAllergenSelected(allergen)
                ? '/Channels/skin/icon-allergens' + allergen.Name + '-selected.svg'
                : '/Channels/skin/icon-allergens' + allergen.Name + '.svg'
            "
            class="ui-button--icon size--64 allergen-selection__allergen--icon"
          />
          <img
            *ngIf="!isAllergenIconAvailable(allergen)"
            skin-part="image"
            alt=""
            src="/Channels/skin/icon-allergens{{ allergen.Name }}-selected.svg"
            class="ui-button--icon size--64 allergen-selection__allergen--icon"
          />
          <h5 class="allergen-selection__allergen--title">
            {{ allergen | translateAllergenLabel }}
          </h5>
          <img
            *ngIf="!isAllergenSelected(allergen)"
            skin-part="image"
            src="/Channels/skin/icon-circleOutlinePlus-allergens.svg"
            class="ui-button--icon ui-button--icon--pill-plus color--accent size--38"
            alt=""
          />
          <img
            *ngIf="isAllergenSelected(allergen)"
            skin-part="image"
            src="/Channels/skin/icon-pillCheckmark-allergens.svg"
            class="ui-button--icon modifier-checkmark size--38 color--primary"
            alt=""
          />
        </button>
        <div class="spacer--40"></div>
      </section>
    </main>
    <footer class="popup__actions">
      <button class="ui-button ui-button--primary ui-button--small" (click)="resetFilterClick()" data-automation="Button Reset Filter">
        <span class="ui-button__wrapper">
          <span>{{ '25' | translate }}</span>
        </span>
      </button>
      <button
        class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
        (click)="closeClick()"
        data-automation="Button Close"
      >
        <span class="ui-button__wrapper">
          <span>{{ '24' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
