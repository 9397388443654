import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'acr-languages',
  templateUrl: './languages.component.html',
  host: { class: 'align-self-center' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent implements OnInit, OnDestroy {
  public languages$ = combineLatest([
    this.appSettings.settings$.pipe(map((bs) => bs.languages)),
    this.translationsService.currentLanguageCode$,
  ]).pipe(map(([languages, activeCode]) => languages.map((l) => (l.code === activeCode ? { ...l, active: true } : l))));
  private subscriptions: Array<Subscription> = [];

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.subscriptions.push(this.translationsService.dictionaryReloaded.subscribe(() => this.changeDetector.markForCheck()));
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onLanguageButtonClicked(languageCode: string) {
    this.translationsService.setCurrentLanguage(languageCode);
  }
}
