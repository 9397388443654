<button
  skin-part="button"
  class="product {{ extraClasses }} button"
  [class.product--hasBackground]="displayBackground"
  [class.product--disabled]="unavailableButton"
  [class.product--displayOnlyImage]="button.DisplayMode === DotButtonDisplayMode.IMAGE_ONLY"
  [class.product--displayOnlyText]="button.DisplayMode === DotButtonDisplayMode.TEXT_ONLY"
  [attr.data-automation]="'Button: ' + button.Link"
>
  <h5 class="product__title" [class.pr--20]="showInfoButton">{{ button | translateCaption }}</h5>
  <img
    class="product__img"
    [class.logo]="!(button | translatePicture)"
    [src]="button | translatePicture"
    alt=""
    width="200rem"
    height="200rem"
  />
  <div class="product__info">
    <div class="product__info--calories flex align-center">
      <img
        *ngIf="showInfoButton"
        skin-part="image"
        alt=""
        src="/Channels/skin/icon-info.svg"
        class="lib-icon size--34 mr--10 product__info--icon button__info-button"
        (click)="onProductInfoClick($event)"
      />
      <span *ngIf="calories && price > 0" class="product__info--calories-count">
        {{ calories.Value }} {{ calories | nutritionalUnit }}
        <span>|</span>
      </span>
      <span class="product__price">{{ price | dotCurrency }}</span>
    </div>
    <div class="size--30" alt="" acrRevertOnRtl></div> 
  </div>
  <ng-container *ngIf="unavailableButton">
    <div class="disabled--overlay">{{ '20210401001' | translate }}</div>
  </ng-container>
</button>
