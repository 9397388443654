import { Injectable } from '@angular/core';
import { DemoMode } from '@dotxix/models/enums/demo-mode';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { lastValueFrom, timer } from 'rxjs';
import { IPayResponse, IPosResponse } from 'dotsdk';
import { BasketService } from '@dotxix/services/basket.service';
import { appLogger } from '@dotxix/log-manager';

@Injectable({ providedIn: 'root' })
export class DemoMockService {
  private demoPosNumberToBeUsed = 1;
  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private basketService: BasketService
  ) {}

  public async cardPayMock(amountOwned: number): Promise<IPayResponse | null> {
    if (
      this.applicationSettingsService.settings$.value.demoMode === DemoMode.BOTH ||
      this.applicationSettingsService.settings$.value.demoMode === DemoMode.ONLY_CARD
    ) {
      await this.demoDelay();
      return this.cardPayResponse(amountOwned);
    }

    return null;
  }

  public async calculateTransactionTotals(): Promise<IPosResponse | null> {
    if (this.usePosOperationMocks()) {
      await this.demoDelay();
      return this.posResponse();
    }

    return null;
  }

  public async sendOpenOrderToPos(): Promise<IPosResponse | null> {
    if (this.usePosOperationMocks()) {
      await this.demoDelay();
      return this.posResponse();
    }

    return null;
  }

  public async tenderOrderOnPos(): Promise<IPosResponse | null> {
    if (this.usePosOperationMocks()) {
      await this.demoDelay();
      const posResponse = this.posResponse();
      this.demoPosNumberToBeUsed++;
      return posResponse;
    }

    return null;
  }

  public async sendCompleteOrderToPos(): Promise<IPosResponse | null> {
    if (this.usePosOperationMocks()) {
      await this.demoDelay();
      const posResponse = this.posResponse();
      this.demoPosNumberToBeUsed++;
      return posResponse;
    }

    return null;
  }

  public async voidOrderOnPos(): Promise<IPosResponse | null> {
    if (this.usePosOperationMocks()) {
      await this.demoDelay();
      const posResponse = this.posResponse();
      this.demoPosNumberToBeUsed++;
      return posResponse;
    }

    return null;
  }

  public async unlockOrderOnPos(): Promise<IPosResponse | null> {
    if (this.usePosOperationMocks()) {
      await this.demoDelay();
      const posResponse = this.posResponse();
      this.demoPosNumberToBeUsed++;
      return posResponse;
    }

    return null;
  }

  private usePosOperationMocks() {
    return (
      this.applicationSettingsService.settings$.value.demoMode === DemoMode.ONLY_POS ||
      this.applicationSettingsService.settings$.value.demoMode === DemoMode.BOTH
    );
  }

  private async demoDelay() {
    await lastValueFrom(timer(this.applicationSettingsService.settings$.value.demoDelay));
  }

  private posResponse(): IPosResponse {
    appLogger.debug(`using pos MOCKED success response`);
    const basketTotalPrice = this.basketService.totalPrice;
    return {
      Success: true,
      SubTotal: basketTotalPrice / 100,
      SubtotalCents: basketTotalPrice,
      BusinessDay: '',
      Class: undefined,
      Elog: '',
      ErrorCode: 0,
      ErrorType: 0,
      ExecuteStarted: false,
      ForceImport: false,
      FuncErrorOccurred: undefined,
      FunctionNumber: 0,
      Injector: '',
      InternalReturnCode: 0,
      IsNewOrder: false,
      LogonInsertNumber: undefined,
      NotOpen: false,
      OrderGuid: undefined,
      OrderPOSNumber: this.demoPosNumberToBeUsed,
      POSMessage: '',
      Receipt: '',
      ReturnCode: 0,
      ReturnMessage: '',
      ReturnMessageDetail: '',
      Tax: 0,
      TaxCents: 0,
    };
  }
  private cardPayResponse(amountOwned: number) {
    appLogger.debug(`using card pay MOCKED success response`);
    return {
      TenderMediaId: '-1',
      PaidAmount: amountOwned,
      CardNumber: '',
      AuthorizationCode: '',
      CardholderName: '',
      ExtraDetails: undefined,
      HasClientReceipt: false,
      HasMerchantReceipt: false,
      PaymentMethod: '',
      TaxIdentificationNumber: '',
      TenderMediaDetails: '',
      TerminalID: '',
      TraceNumber: '',
      TransactionDate: '',
      TransactionReference: `${Math.ceil(Math.random() * 1000000)}`,
      TransactionTime: '',
      AdditionalDetails: '',
    };
  }
}
