import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BasketDisplayItem } from '@dotxix/models/interfaces/basket-display-item';
import { map } from 'rxjs/operators';
import { computeBasketDisplayItems } from '@dotxix/helpers/basket-display.helper';
import { BasketService } from '@dotxix/services/basket.service';
import { PromosService } from '@dotxix/services/promos.service';
import { DotButton } from 'dotsdk';
import { OrderFlowService } from '@dotxix/services/order-flow.service';

@Component({
  selector: 'acr-basket-product-list',
  templateUrl: './basket-product-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketProductListComponent {
  @Input() public allowModifyProduct = true;
  public basketDisplayItems$: Observable<BasketDisplayItem[]> = this.basketService.buttons$.pipe(
    map((basketButtons) => computeBasketDisplayItems(basketButtons))
  );

  constructor(
    private basketService: BasketService,
    private promosService: PromosService,
    private orderFlowService: OrderFlowService
  ) {}

  public onChangeProductQuantity(basketButton: DotButton, count: 1 | -1) {
    this.basketService.changeProductQuantity(basketButton, count).then();
  }

  public onRemoveProduct(basketButton: DotButton) {
    this.basketService.removeProductWithConfirmation(basketButton).then();
  }

  public onCustomizeProduct(basketButton: DotButton) {
    this.orderFlowService.customizeBasketProduct(basketButton);
  }

  public trackByProduct(index: number, displayItem: BasketDisplayItem) {
    return displayItem.button.uuid;
  }
}
