import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor(protected appSettings: ApplicationSettingsService) {}

  public formatNumber(value: number, decimals?: number): string {
    const signal = Math.sign(value) < 0 ? '-' : '';
    const absoluteValue = Math.abs(value);

    if (!decimals || decimals < 0) {
      decimals = 0;
    }

    const integerPart = decimals > 0 ? Math.floor(absoluteValue) : Math.round(absoluteValue);
    // const formattedIntegerPart = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.data.thousandSeparator);
    const formattedIntegerPart = integerPart.toString();

    if (decimals === 0) {
      return signal + formattedIntegerPart;
    } else {
      const decimalPart = Math.round((absoluteValue - integerPart) * Math.pow(10, decimals));
      const formattedDecimalPart = decimalPart.pad(decimals);
      return signal + formattedIntegerPart + this.appSettings.settings$.value.decimalSeparator + formattedDecimalPart;
    }
  }

  public formatCurrency(value: number | string, formatZeroValue = false): string {
    const priceAsNumber = typeof value === 'string' ? parseFloat(value) : value;

    if (priceAsNumber === 0 && !formatZeroValue) {
      return '';
    }

    const formattedNumber = this.formatNumber(priceAsNumber / 100, this.appSettings.settings$.value.currencyDecimals);

    const pattern = this.appSettings.settings$.value.currencySymbolBefore ? '{0}{1}' : '{1}{0}';
    return String.compositeFormat(pattern, this.appSettings.settings$.value.currencySymbol, formattedNumber);
  }
}
