<section>
  <main class="popup__page-container">
    <section class="width100">
      <h3 class="text-center">{{ '40' | translate }}</h3>
      <acr-keyboard (inputChanged)="barcode = $event" [text]="barcode"></acr-keyboard>
    </section>
  </main>
</section>

<footer class="popup__actions">
  <button class="ui-button ui-button--primary ui-button--small" (click)="cancel.next()" data-automation="Cancel Button">
    <span class="ui-button__wrapper">
      <span>{{ '23' | translate }}</span>
    </span>
  </button>
  <button
    class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
    [disabled]="barcode === ''"
    (click)="continue.next(barcode)"
    data-automation="Confirm Button"
  >
    <span class="ui-button__wrapper">
      <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
      <span>{{ '71' | translate }}</span>
    </span>
  </button>
</footer>
