import { Component } from '@angular/core';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';

@Component({
  selector: 'acr-table-service-entry',
  templateUrl: './table-service-entry.component.html',
})
export class TableServiceEntryComponent {
  constructor(private checkoutFlowService: CheckoutFlowService) {}

  public cancel() {
    this.checkoutFlowService.proceedToPayment().then(() => {});
  }

  public goToSelection() {
    this.checkoutFlowService.displayTableServiceSelection().then(() => {});
  }
}
