import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'acr-promos-keyboard',
  templateUrl: './promos-keyboard.component.html',
})
export class PromosKeyboardComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() continue = new EventEmitter<string>();

  public barcode = '';
}
