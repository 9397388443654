import { AdaBannersComponent } from '@dotxix/components/ada-banners/ada-banners.component';
import { AllergenSelectionComponent } from '@dotxix/components/allergen-selection/allergen-selection.component';
import { BasketComponent } from '@dotxix/components/basket/basket.component';
import { ButtonComponent } from '@dotxix/components/button/button.component';
import { ButtonDetailsComponent } from '@dotxix/components/button-details/button-details.component';
import { ButtonModifierComponent } from '@dotxix/components/button-modifier/button-modifier.component';
import { ButtonModifierListComponent } from '@dotxix/components/button-modifier-list/button-modifier-list.component';
import { ButtonModifierSubgroupComponent } from '@dotxix/components/button-modifier-subgroup/button-modifier-subgroup.component';
import { ButtonsCounterEditComponent } from '@dotxix/components/buttons-counter-edit/buttons-counter-edit.component';
import { CaloriesComponent } from '@dotxix/components/calories/calories.component';
import { CollapsibleComponent } from '@dotxix/components/collapsible/collapsible.component';
import { ComboStepperComponent } from '@dotxix/components/combo-stepper/combo-stepper.component';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { DropdownComponent } from '@dotxix/components/dropdown/dropdown.component';
import { FooterComponent } from '@dotxix/components/footer/footer.component';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { KeyboardComponent } from '@dotxix/components/keyboard/keyboard.component';
import { MakeItAMealComponent } from '@dotxix/components/make-it-a-meal/make-it-a-meal.component';
import { NavSliderComponent } from '@dotxix/components/nav-slider/nav-slider.component';
import { NgModule } from '@angular/core';
import { BasketTogglerComponent } from '@dotxix/components/basket/basket-toggler/basket-toggler.component';
import { PaymentLogosComponent } from '@dotxix/components/payment-logos/payment-logos.component';
import { PipesModule } from '@dotxix/pipes/pipes.modules';
import { ProductCardComponent } from '@dotxix/components/product-card/product-card.component';
import { ScanComponent } from '@dotxix/components/scan/scan.component';
import { SpinnerComponent } from '@dotxix/components/spinner/spinner.component';
import { StatusComponent } from '@dotxix/components/status/status.component';
import { SuggestionSalesComponent } from '@dotxix/components/suggestion-sales/suggestion-sales.component';
import { VoucherInfoComponent } from '@dotxix/components/voucher-info/voucher-info.component';
import { PreorderComponent } from '@dotxix/pages/checkout/preorder/preorder.component';
import { SlideShowComponent } from '@dotxix/components/slide-show/slide-show.component';
import { StartOverComponent } from '@dotxix/components/action-buttons/start-over/start-over.component';
import { ConfirmOrderComponent } from '@dotxix/components/action-buttons/confirm-order/confirm-order.component';
import { AdminZoneComponent } from '@dotxix/components/admin-zone/admin-zone.component';
import { GoBackComponent } from '@dotxix/components/action-buttons/go-back/go-back.component';
import { PayOrderComponent } from '@dotxix/components/action-buttons/pay-order/pay-order.component';
import { LanguagesComponent } from '@dotxix/components/languages/languages.component';
import { ServiceTypeButtonComponent } from '@dotxix/components/service-type-button/service-type-button.component';
import { ScrollableContainerComponent } from '@dotxix/components/scrollable-container/scrollable-container.component';
import { BasketProductListComponent } from '@dotxix/components/basket-product-list/basket-product-list.component';
import { ProductCardDetailsComponent } from '@dotxix/components/product-card/product-card-details/product-card-details.component';
import { ProductCardSubCardItemComponent } from '@dotxix/components/product-card/product-card-subcard-item/product-card-sub-card-item.component';
import { CustomThemeModule } from '@dotxix/custom-theme/custom-theme.module';
import { BasketSuggestionsComponent } from '@dotxix/components/basket/basket-suggestions/basket-suggestions.component';
import { ItemPackIndicatorComponent } from '@dotxix/components/item-pack-indicator/item-pack-indicator.component';
import { ButtonItemPackComponent } from '@dotxix/components/button-item-pack/button-item-pack.component';
import { ButtonItemComponent } from '@dotxix/components/button-item/button-item.component';
import { ComplementModifierComponent } from '@dotxix/components/complement-modifier/complement-modifier.component';
import { ProductStepperHeaderComponent } from '@dotxix/components/product-stepper-header/product-stepper-header.component';
import { PromosSelectMethodComponent } from '@dotxix/components/promos/select-method/promos-select-method.component';
import { PromoStepperComponent } from '@dotxix/components/promos/promo-stepper/promo-stepper.component';
import { PromosScanBarcodeComponent } from '@dotxix/components/promos/scan-barcode/promos-scan-barcode.component';
import { PromosKeyboardComponent } from '@dotxix/components/promos/keyboard/promos-keyboard.component';
import { ProductInfoComponent } from '@dotxix/components/product-info/product-info.component';
import { TicketPrintSelectionComponent } from '@dotxix/components/ticket-print-selection/ticket-print-selection.component';
import { ModifiersStepperComponent } from '@dotxix/components/modifiers-stepper/modifiers-stepper.component';
import { EditSingleModifierStepComponent } from '@dotxix/components/edit-single-modifier-step/edit-single-modifier-step.component';
import { EditSingleComboStepComponent } from '@dotxix/components/edit-single-combo-step/edit-single-combo-step.component';
import { StepperComponent } from '@dotxix/components/stepper/stepper.component';
import { IceModifierComponent } from '@dotxix/components/ice-modifier/ice-modifier.component';
import { InfoIndicatorComponent } from '@dotxix/components/info-indicator/info-indicator.component';
import { LanguageChangeViewTriggerComponent } from './language-change-view-trigger/language-change-view-trigger.component';
import { RevertOnRtlDirective } from '@dotxix/directives/revert-on-rtl.directive';
import { ItemPackGroupComponent } from './item-pack-group/item-pack-group.component';
import { SkinModule } from '@dotxix/skin/skin.module';
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';
import { ScaleTextToFitContainerDirective } from '@dotxix/directives/scale-text-to-fit-container.directive';
import { LoadingComponent } from '@dotxix/pages/checkout/loading/loading.component';

const COMPONENTS = [
  ButtonComponent,
  ButtonsCounterEditComponent,
  ButtonModifierComponent,
  ProductStepperHeaderComponent,
  FooterComponent,
  VoucherInfoComponent,
  BasketTogglerComponent,
  ProductCardComponent,
  DropdownComponent,
  StatusComponent,
  CollapsibleComponent,
  NavSliderComponent,
  ButtonModifierListComponent,
  BasketSuggestionsComponent,
  SpinnerComponent,
  KeyboardComponent,
  ScanComponent,
  PreorderComponent,
  PaymentLogosComponent,
  SlideShowComponent,
  AdminZoneComponent,
  StartOverComponent,
  ConfirmOrderComponent,
  GoBackComponent,
  PayOrderComponent,
  ServiceTypeButtonComponent,
  ScrollableContainerComponent,
  BasketProductListComponent,
  ProductCardDetailsComponent,
  ProductCardSubCardItemComponent,
  ItemPackIndicatorComponent,
  ButtonItemPackComponent,
  ButtonItemComponent,
  ComplementModifierComponent,
  PromosSelectMethodComponent,
  PromosScanBarcodeComponent,
  PromosKeyboardComponent,
  TicketPrintSelectionComponent,
  StepperComponent,
  IceModifierComponent,
  InfoIndicatorComponent,
  LanguageChangeViewTriggerComponent,
  RevertOnRtlDirective,
  ScaleTextToFitContainerDirective,
  ConfirmDialogComponent,
  BasketComponent,
  ComboStepperComponent,
  ModifiersStepperComponent,
  ButtonDetailsComponent,
  CaloriesComponent,
  AllergenSelectionComponent,
  SuggestionSalesComponent,
  MakeItAMealComponent,
  AdaBannersComponent,
  InfoDialogComponent,
  PromoStepperComponent,
  ButtonModifierSubgroupComponent,
  LanguagesComponent,
  ProductInfoComponent,
  EditSingleModifierStepComponent,
  EditSingleComboStepComponent,
  ItemPackGroupComponent,
  LoadingComponent,
];
@NgModule({
  imports: [CommonModule, PipesModule, SkinModule, CustomThemeModule, QRCodeModule],
  declarations: [COMPONENTS],
  exports: [COMPONENTS, QRCodeComponent],
})
export class ComponentsModules {}
