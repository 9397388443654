import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { StatusService } from '@dotxix/services/status.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { KioskStatusColor } from '@dotxix/models/enums/kiosk-status-color';

@Component({
  selector: 'acr-status',
  templateUrl: './status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent implements OnDestroy {
  public kioskStatusColor$ = this.statusService.state$.pipe(
    map((state) => (state.deviceStatusChecked ? state.statusColor : KioskStatusColor.NO_COLOR))
  );
  public messages$ = new BehaviorSubject<string[]>([]);

  private subscription = new Subscription();
  constructor(
    private statusService: StatusService,
    private translationsService: TranslationsService
  ) {
    this.subscription.add(
      this.statusService.state$.subscribe((state) => {
        if (state.deviceStatusChecked) {
          this.messages$.next([
            ...state.statusMessages,
            ...(state.mandatoryCardEnabledInBSButMissingInATP ? [this.translationsService.translate('2004000')] : []),
            ...(state.mandatoryCashEnabledInBSButMissingInATP ? [this.translationsService.translate('2004001')] : []),
            ...(state.mandatoryEpayEnabledInBSButMissingInATP ? [this.translationsService.translate('2004002')] : []),
            ...(state.dataLoading ? [this.translationsService.translate('2004003')] : []),
            ...(state.channelIsUpdating ? [this.translationsService.translate('2004004')] : []),
            ...(state.kioskBlockedDueToServiceType ? [this.translationsService.translate('2004005')] : []),
            ...(state.blockKiosk ? [this.translationsService.translate('2020122101')] : []),
          ]);
        } else {
          this.messages$.next([this.translationsService.translate('2022111501')]);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
