import { Component } from '@angular/core';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';

@Component({
  selector: 'acr-go-back',
  templateUrl: './go-back.component.html',
})
export class GoBackComponent {
  constructor(private checkoutFlowService: CheckoutFlowService) {}

  public click() {
    this.checkoutFlowService.returnFromCheckout();
  }
}
