import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppFlowService } from '@dotxix/services/app-flow.service';
import { map } from 'rxjs/operators';
import { BannersService } from '@dotxix/services/banners.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { AnimationType } from '@dotxix/models/enums/animation-type';
import { StatusService } from '@dotxix/services/status.service';

@Component({
  selector: 'acr-banners',
  templateUrl: './banners.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex-full banners' },
})
export class BannersComponent {
  public fullHdBanners$ = this.bannersService.fullHdBanners$;
  public enableTouchToOrderSection$ = this.appSettings.settings$.pipe(map((settings) => settings.enableTouchToOrderSection));
  public canStartOrdering$ = this.statusService.canStartOrdering$;
  public bannersPath$ = this.appSettings.settings$.pipe(map((settings) => settings.bannersPath));
  public AnimationType = AnimationType;

  constructor(
    private bannersService: BannersService,
    private appSettings: ApplicationSettingsService,
    private statusService: StatusService,
    private appFlowService: AppFlowService
  ) {}

  public click() {
    this.appFlowService.onBannersClicked();
  }
}
