import { Component, OnInit } from '@angular/core';
import { DotButton, DotCombo, DotModifier, DotSessionService, getCombosCatalogButton } from 'dotsdk';
import * as _ from 'lodash';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ButtonDetailsDataParams } from '@dotxix/models/interfaces/button-details-data-params';
import {
  addAutoCompleteModifiers,
  forceShowModifierGroupsThatAreNotReachingMinQuantity,
  isModifierSelectionValidForBasket,
} from '@dotxix/helpers/modifiers.helper';
import {
  computeAutoPopFeatDefaults,
  computeButtonDisplayPrice,
  isItemPackButton,
  isNotItemPackButton,
} from '@dotxix/helpers/button.helper';
import {
  computeUpsizeButtonListWithPrices,
  isNotUpSizeComboStep,
  isUpSizeComboStep,
  replaceItemsOnResize,
  selectComboSize,
} from '@dotxix/helpers/combo-up-size.helper';
import {
  addComputedPrice,
  autoSelectComboHiddenSteps,
  comboCustomizableModifiers,
  comboInitialStartSize,
  computeComboStepComponentsMinPrice,
  computeComboStepVisibleComponents,
  deselectComboStepComponent,
  filterComboVisibleSteps,
  getSelectedComboStepComponent,
  hasComboCustomizableModifiers,
  initializeComboButton,
  isComboStep,
  selectComponent,
} from '@dotxix/helpers/combo.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { UpSizeDisplayButton } from '@dotxix/models/interfaces/up-size-display-button';
import { computeProductStepItemPackVisibleComponents } from '@dotxix/helpers/item-pack.helper';
import { Subject } from 'rxjs';
import { StepperStep } from '@dotxix/models/interfaces/stepper-step';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';
import { updateButtonPromoCustomForcePrice } from '../../helpers/promo.helper';

enum ComboStepState {
  CHOOSE_COMPONENT = 'chooseComponent',
  CHOOSE_UPSIZE = 'chooseUpsize',
  CUSTOMIZE = 'customize',
  SUBGROUP = 'subgroup',
}

@Component({
  selector: 'acr-combo-stepper',
  templateUrl: './combo-stepper.component.html',
})
export class ComboStepperComponent
  extends AbstractDynamicAnimatedPopup<ButtonDetailsDataParams, DotButton | null | undefined>
  implements OnInit
{
  public ComboStepState = ComboStepState;
  public button!: DotButton<ButtonState>;
  public catalogButton!: DotButton;
  public comboTitle!: string;
  public comboSubTitle!: string;

  public displayBackButton = false;
  public disableConfirmButton = false;

  public currentSize: string | undefined;
  public initialSize: string | undefined;
  public upsizeComboStep: DotCombo | undefined;
  public upsizeDisplayButtons: UpSizeDisplayButton[] | undefined;
  public hasUpSizeStep: boolean | undefined;
  public selectedUpsizeButton: DotButton | undefined;

  public dynamicHeaderImages: boolean | undefined;
  public headerImages: string[] | undefined;

  public currentComboStepState: ComboStepState | undefined;
  public visibleComboSteps: DotCombo[] = [];
  public stepperSteps: StepperStep[] = [];
  public currentComboStepIndex = 0;
  public isLastStep = false;
  public currentComboStep: DotCombo | undefined;
  public currentComboStepComponents: DotButton[] = [];
  public currentComboStepComponentsMinPrice: number | undefined;
  public currentComboStepComponentFeatures: DotModifier[] = [];
  public itemPackVisibleComponents: DotButton[] = [];

  public scrollToTop$ = new Subject<void>();

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private productTranslationsService: ProductTranslationsService,
    private productCustomizationService: ProductCustomizationService
  ) {
    super();
  }

  public ngOnInit() {
    this.button = _.cloneDeep(this.dataParams!.button);
    initializeComboButton(this.button);

    this.initialSize = comboInitialStartSize(this.button);
    this.setCurrentSize(this.button.selectedSize || this.initialSize);
    qaAutomationLog('button: ', this.button);

    this.catalogButton = getCombosCatalogButton(this.button.ComboPage.ID.toString());
    this.comboTitle = this.catalogButton ? this.translationsService.getTranslatedButtonCaption(this.catalogButton) ?? '' : '';

    this.upsizeComboStep = this.button.ComboPage.Combos.find((combo) => isUpSizeComboStep(combo));
    this.hasUpSizeStep = !!this.upsizeComboStep;
    if (this.hasUpSizeStep) {
      this.upsizeDisplayButtons = computeUpsizeButtonListWithPrices(this.button);
    }
    this.updateSizeRelatedProps();

    this.dynamicHeaderImages = this.appSettings.settings$.value.enableComboAnimation;
    this.headerImages = this.computeHeaderImages();

    this.listComboStepComponents(0);
    this.autoSelectCurrentComboStepComponent();
  }

  public computeComponentDisplayPrice(component: DotButton): number {
    return computeButtonDisplayPrice(component, this.currentComboStepComponentsMinPrice ?? 0);
  }

  public currentComboStepComponentFeaturesChanged() {
    this.updateNavigationButtonStates();
  }

  public isComboStepComponentSelected(component: DotButton) {
    return isNotItemPackButton(component) ? component.Selected : component.Page.Buttons.some((itemPackButton) => itemPackButton.Selected);
  }

  public onBackButtonClicked() {
    switch (this.currentComboStepState) {
      case ComboStepState.CHOOSE_COMPONENT:
        this.previousVisibleStep();
        break;
      case ComboStepState.CHOOSE_UPSIZE:
        this.previousVisibleStep();
        break;
      case ComboStepState.CUSTOMIZE:
        this.currentComboStepState = ComboStepState.CHOOSE_COMPONENT;
        // deselect components without minimum required customization
        this.deselectButtonsWithoutMinimumRequiredModifiers(this.currentComboStepComponents);
        break;
      case ComboStepState.SUBGROUP:
        this.currentComboStepState = ComboStepState.CHOOSE_COMPONENT;
        break;
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onComponentButtonClicked(component: DotButton) {
    if (isItemPackButton(component)) {
      this.listComboStepItemPack(component);
    } else {
      this.selectCurrentComboStepComponent(component);
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onConfirmClicked() {
    if (this.currentComboStepState === ComboStepState.CUSTOMIZE) {
      const selectedComboStepComponent = this.currentComboStep ? getSelectedComboStepComponent(this.currentComboStep) : null;
      if (selectedComboStepComponent) {
        addAutoCompleteModifiers(selectedComboStepComponent);
      }
    }
    this.goToNextVisibleStepOrFinishCombo();
  }

  public cancelClick(): void {
    this.productCustomizationService.onCancelButton(undefined, this);
  }

  public onItemPackComponentButtonClicked(component: DotButton) {
    this.selectCurrentComboStepComponent(component);
  }

  public selectUpSizeButton(upSizeButton: DotButton) {
    this.setCurrentSizeAndReplaceFeaturesInComponents(upSizeButton.Link);
    this.updateDynamicHeaderImages();
    this.updateSizeRelatedProps();
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private setCurrentSize(size: string) {
    selectComboSize(size, this.button);
    this.currentSize = size;
  }

  setCurrentSizeAndReplaceFeaturesInComponents(size: string) {
    this.setCurrentSize(size);
    replaceItemsOnResize(size, this.button);
  }

  private listComboStepComponents(comboStepIndex: number) {
    this.currentComboStepIndex = comboStepIndex;
    this.updateIsLastStep();
    this.currentComboStep = this.visibleComboSteps[this.currentComboStepIndex];
    if (isUpSizeComboStep(this.currentComboStep)) {
      this.currentComboStepState = ComboStepState.CHOOSE_UPSIZE;
    } else {
      this.currentComboStepState = ComboStepState.CHOOSE_COMPONENT;
      this.updateCurrentComboStepComponents();
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private updateCurrentComboStepComponents() {
    if (this.currentComboStep && isNotUpSizeComboStep(this.currentComboStep)) {
      this.currentComboStepComponents = computeComboStepVisibleComponents(this.currentComboStep, this.currentSize ?? '');
      this.currentComboStepComponentsMinPrice = computeComboStepComponentsMinPrice(this.currentComboStepComponents, this.currentSize ?? '');
    }
  }

  private autoSelectCurrentComboStepComponent() {
    if (this.currentComboStep && isUpSizeComboStep(this.currentComboStep)) {
      this.autoSelectUpSize();
    } else {
      if (this.currentComboStepComponents.length === 1 && isNotItemPackButton(this.currentComboStepComponents.first())) {
        this.selectCurrentComboStepComponent(this.currentComboStepComponents.first());
        if (this.currentComboStepState !== ComboStepState.CUSTOMIZE) {
          this.nextVisibleStep();
        }
      }
    }
  }

  private autoSelectUpSize() {
    if (this.upsizeDisplayButtons?.length === 1) {
      const singleUpSizeDisplayButton = this.upsizeDisplayButtons[0];
      singleUpSizeDisplayButton.button.Selected = true;
      singleUpSizeDisplayButton.button.quantity = 1;

      this.nextVisibleStep();
    }
  }

  private nextVisibleStep() {
    if (this.visibleComboSteps[this.currentComboStepIndex + 1]) {
      this.listComboStepComponents(this.currentComboStepIndex + 1);
      this.autoSelectCurrentComboStepComponent();
    }
  }

  private previousVisibleStep() {
    if (this.visibleComboSteps[this.currentComboStepIndex - 1]) {
      this.listComboStepComponents(this.currentComboStepIndex - 1);
      if (this.currentComboStepComponents.length === 1) {
        const selectedComboStepComponent = this.currentComboStep ? getSelectedComboStepComponent(this.currentComboStep) : null;
        if (selectedComboStepComponent) {
          this.customizeComponentFeaturesIfAny(selectedComboStepComponent);
        }
      }
    }
  }

  private selectCurrentComboStepComponent(componentButton: DotButton) {
    deselectComboStepComponent(this.currentComboStepComponents);
    selectComponent(componentButton);
    computeAutoPopFeatDefaults(componentButton);
    this.updateDynamicHeaderImages();
    this.updateNavigationButtonStates();

    if (componentButton.hasModifiers) {
      forceShowModifierGroupsThatAreNotReachingMinQuantity(componentButton);
      this.customizeComponentFeaturesIfAny(componentButton);
    }
  }

  private customizeComponentFeaturesIfAny(component: DotButton) {
    if (hasComboCustomizableModifiers(component, !this.dataParams!.isUpdate)) {
      this.customizeComponentModifiers(component);
    }
  }

  private customizeComponentModifiers(component: DotButton) {
    this.currentComboStepComponentFeatures = comboCustomizableModifiers(component, !this.dataParams!.isUpdate);
    this.currentComboStepState = ComboStepState.CUSTOMIZE;
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private listComboStepItemPack(component: DotButton) {
    this.currentComboStepState = ComboStepState.SUBGROUP;
    this.itemPackVisibleComponents = computeProductStepItemPackVisibleComponents(component, this.currentSize ?? '');
  }

  private goToNextVisibleStepOrFinishCombo() {
    if (this.visibleComboSteps[this.currentComboStepIndex + 1]) {
      this.nextVisibleStep();
    } else {
      this.comboFinished();
    }
  }

  private comboFinished() {
    this.button.selectedSize = this.currentSize ?? '';
    autoSelectComboHiddenSteps(this.button);
    this.addComputedPrice();

    this.close(this.button);
  }

  private addComputedPrice(): void {
    updateButtonPromoCustomForcePrice(
      this.button,
      DotSessionService.getInstance().currentPosServingLocation,
      this.appSettings.settings$.value.promoDiscountsMode
    );
    addComputedPrice(this.button);
  }

  private updateSizeRelatedProps() {
    this.visibleComboSteps = filterComboVisibleSteps(this.button, this.currentSize ?? '');
    this.stepperSteps = this.visibleComboSteps.map((comboStep) => ({
      name: this.translationsService.translateComboStepName(comboStep) ?? '',
      image: this.productTranslationsService.translateComboImage(comboStep) ?? '',
    }));
    this.updateCurrentComboStepComponents();
    this.updateUpsizeSelectedButton();
    this.updateSubtitle();
  }

  private updateUpsizeSelectedButton() {
    if (this.hasUpSizeStep) {
      this.selectedUpsizeButton = this.upsizeComboStep?.Buttons.find((button) => button.Link === this.currentSize);
    }
  }

  private updateIsLastStep() {
    this.isLastStep = this.currentComboStepIndex === this.visibleComboSteps.length - 1;
  }

  private updateSubtitle() {
    this.comboSubTitle = this.hasUpSizeStep
      ? this.selectedUpsizeButton
        ? this.translationsService.getTranslatedButtonCaption(this.selectedUpsizeButton) ?? ''
        : ''
      : this.translationsService.getTranslatedButtonCaption(this.button) ?? '';
  }

  private updateDynamicHeaderImages() {
    if (this.dynamicHeaderImages) {
      this.headerImages = this.computeDynamicHeaderImages();
    }
  }

  private computeHeaderImages() {
    if (this.dynamicHeaderImages) {
      return this.computeDynamicHeaderImages();
    } else {
      return this.computeStaticHeaderImages();
    }
  }

  private computeStaticHeaderImages() {
    const headerImageButton = this.catalogButton ? this.catalogButton : this.button;
    const buttonHeaderImage = this.productTranslationsService.translatePicture(headerImageButton);
    return buttonHeaderImage ? [buttonHeaderImage] : [];
  }

  private computeDynamicHeaderImages() {
    return this.visibleComboSteps
      .filter((combo) => isComboStep(combo))
      .reduce((acc, combo) => {
        const selectedButton = getSelectedComboStepComponent(combo);
        const picturePath = selectedButton ? this.productTranslationsService.translatePicture(selectedButton) : null;
        return picturePath ? [...acc, picturePath] : acc;
      }, [] as string[]);
  }

  private updateNavigationButtonStates() {
    this.displayBackButton =
      this.currentComboStepIndex > 0 ||
      (this.currentComboStepState === ComboStepState.CUSTOMIZE && this.currentComboStepComponents.length > 1);

    this.disableConfirmButton = !this.isCurrentStepValid();
  }

  private isCurrentStepValid() {
    if (!this.currentComboStep) {
      return false;
    }
    const selectedComboStepComponent = getSelectedComboStepComponent(this.currentComboStep);
    switch (this.currentComboStepState) {
      case ComboStepState.CHOOSE_COMPONENT:
      case ComboStepState.SUBGROUP:
      case ComboStepState.CHOOSE_UPSIZE:
        return !!selectedComboStepComponent;
      case ComboStepState.CUSTOMIZE:
        return selectedComboStepComponent ? isModifierSelectionValidForBasket(selectedComboStepComponent) : false;
    }
    return false;
  }
  private isButtonConfiguredWithMandatoryModifiers(button: DotButton): boolean {
    if (button.hasModifiers === false) {
      return true;
    }

    for (let i = 0; i < button.ModifiersPage.Modifiers.length; i++) {
      const minQty = button.ModifiersPage.Modifiers[i].PageInfo.MinQuantity;
      if (!minQty) {
        continue;
      }
      const cumulatedQty = button.ModifiersPage.Modifiers[i].Buttons.reduce((acc, button) => {
        return acc + button.quantity;
      }, 0);
      if (cumulatedQty < minQty) {
        return false;
      }
    }
    return true;
  }

  private deselectButtonsWithoutMinimumRequiredModifiers(buttons: DotButton[]) {
    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      if (this.isButtonConfiguredWithMandatoryModifiers(button) === false) {
        button.deselect(true);
        button.quantity = 0;
      }
    }
  }

  private scrollToTop() {
    this.scrollToTop$.next();
  }
}
