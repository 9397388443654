import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-glory-cash-payment-progress-error-print',
  templateUrl: './glory-cash-payment-progress-error-print.component.html',
  host: { class: 'flex flex--column flex--1' },
})
export class GloryCashPaymentProgressErrorPrintComponent {
  public paymentServiceState$ = this.paymentService.state$;

  constructor(private paymentService: PaymentService) {}
}
