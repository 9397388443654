<language-change-view-trigger></language-change-view-trigger>
<ng-container *ngIf="state$ | async as state">
  <main *ngIf="state.page as page" class="page-container menu" skin="menuPage">
    <acr-nav-slider></acr-nav-slider>

    <header class="flex justify-center place-center menu__header">
      <button
        *ngIf="!state.isMainPage"
        class="ui-button ui-button--primary ui-button--tiny ui-button--iconLeft"
        (click)="back()"
        data-automation="Button: Back"
      >
        <span class="ui-button__wrapper">
          <img skin-part="image" src="/Channels/skin/icon-chevronLeft-back.svg" class="size--20" alt="" acrRevertOnRtl />
          <span>{{ '9' | translate }}</span>
        </span>
      </button>
      <h4 class="fs--50">{{ page | pageTitle }}</h4>
    </header>

    <acr-scrollable-container #scrollRef (scrollArrowClick)="onVerticalScrollButtonClick()">
      <section class="full">
        <!-- Highlighted Buttons START -->
        <div *ngIf="displayHighlightedButtons" class="highlighted-items menu-buttons-grid cols--2" skin-part="highlightedButtonList">
          <acr-button
            *ngFor="let button of highlightedButtons"
            skin="menuButton"
            [button]="button"
            [extraClasses]="state.dropdownPage === button ? 'selected' : ''"
            [displayBackground]="page.PageTemplate === 'ButtonsWithBackground'"
            [unavailableButton]="button | isButtonVisuallyUnavailable"
            (selectProduct)="select(button, false)"
          ></acr-button>
        </div>
        <!-- Highlighted Buttons END -->

        <!-- Suggestions Buttons START -->
        <ng-container *ngIf="(state$ | async)!.isMainPage">
          <div *ngIf="(loadingPopularSuggestions$ | async) === false && (popularSuggestions$ | async)!.length > 0">
            <h3 class="menu__populars-title">{{ '85' | translate }}</h3>
            <div skin-part="suggestionButtonList" class="menu-buttons-grid cols--3 pb--20">
              <acr-button
                *ngFor="let button of popularSuggestions$ | async"
                skin="menuButton"
                [button]="button"
                [unavailableButton]="button | isButtonVisuallyUnavailable"
                (selectProduct)="selectPopularSuggestion(button)"
                [extraClasses]="'product__popular-button'"
              ></acr-button>
            </div>
          </div>
        </ng-container>
        <!-- Suggestions Buttons END -->

        <!-- Default Buttons START -->
        <div class="menu-buttons-grid cols--3" skin-part="buttonList">
          <acr-button
            *ngFor="let button of defaultSizeButtons"
            skin="menuButton"
            [button]="button"
            [extraClasses]="state.dropdownPage === button ? 'selected' : ''"
            [displayBackground]="page.PageTemplate === 'ButtonsWithBackground'"
            [unavailableButton]="button | isButtonVisuallyUnavailable"
            (selectProduct)="select(button, false)"
          ></acr-button>

          <acr-dropdown
            *ngIf="state.dropdownPage"
            [buttons]="state.dropdownPage.Page.Buttons | filterCatalogAvailable"
            class="dropdown__modifiers"
            [ngClass]="'row-' + dropdownPageRow"
            (dropdownButtonClick)="select($event, true)"
          ></acr-dropdown>
        </div>
        <!-- Default Buttons END -->
      </section>
    </acr-scrollable-container>

    <acr-voucher-info [class.voucher-info--positioned-top]="basketOpened"></acr-voucher-info>

    <acr-basket *ngIf="basketOpened" (suggestionSelect)="selectBasketSuggestion($event)"></acr-basket>

    <acr-footer>
      <acr-start-over left-slot></acr-start-over>
      <acr-basket-toggler order-button-slot [(basketOpened)]="basketOpened"></acr-basket-toggler>
      <acr-confirm-order right-slot [isDisabled]="(allowToCheckout$ | async) === false"></acr-confirm-order>
    </acr-footer>
  </main>
</ng-container>
