// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  useMocksForEnvironment: true,
  environmentMocksPath: '/assets/dot-sdk-mocks/atp-environment/',

  useMocksForPay: true,
  payMocksPath: '/assets/dot-sdk-mocks/atp-pay/',

  useMocksForPos: true,
  posMocksPath: '/assets/dot-sdk-mocks/pos-injector/',

  useMocksForIOBoard: true,
  IOBoardMocksPath: '/assets/dot-sdk-mocks/atp-io-board',

  useMocksForTweet: true,
  tweetMocksPath: '/assets/dot-sdk-mocks/atp-tweet',

  useMocksForBridgeStatusSetter: true,
  bridgeStatusSetterPath: '/assets/dot-sdk-mocks/bridge',

  useMocksForPrint: true,
  printMocksPath: '/assets/dot-sdk-mocks/atp-print',

  useMocksForElectronicReceiptSender: true,
  electronicReceiptSenderMocksPath: '/assets/dot-sdk-mocks/electronic-receipt-sender',

  useMocksForScan: true,
  scanMocksPath: '/assets/dot-sdk-mocks/atp-scan',

  useMocksForOsiris: true,
  osirisMocksPath: '/assets/dot-sdk-mocks/osiris/',

  diagnosticsPath: 'shared\\exports\\diagnostics\\',
  analyticsPath: 'shared\\exports\\analytics\\',

  useMocksForFaceRecall: true,
  faceRecallPath: '/assets/dot-sdk-mocks/face-recall',

  useMocksForRecommendations: true,
  recommendationsMocksPath: '/assets/dot-sdk-mocks/recommendations-engine',

  automation: false,

  k: 'v8y/B?D(G+KbPeSh',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
