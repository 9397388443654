import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardPaymentPageComponent } from '@dotxix/payment/components/card-payment/card-payment-page.component';
import { GloryCashPaymentPageComponent } from '@dotxix/payment/components/glory-cash-payment/glory-cash-payment-page.component';
import { GloryLegalRequirementsComponent } from '@dotxix/payment/components/glory-cash-payment/legal-requirements/glory-legal-requirements.component';
import { PaymentSelectionPageComponent } from '@dotxix/payment/components/payment-selection/payment-selection-page.component';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { ElectronicPaymentComponent } from '@dotxix/payment/components/electronic-payment/electronic-payment.component';
import { PayTowerPaymentComponent } from '@dotxix/payment/components/pay-tower-payment/pay-tower-payment.component';

const routes: Routes = [
  {
    path: PaymentRoutes.PaymentSelection,
    component: PaymentSelectionPageComponent,
  },
  {
    path: PaymentRoutes.CardPayment,
    component: CardPaymentPageComponent,
  },
  {
    path: PaymentRoutes.GloryLegalRequirements,
    component: GloryLegalRequirementsComponent,
  },
  {
    path: PaymentRoutes.GloryPayment,
    component: GloryCashPaymentPageComponent,
  },
  {
    path: PaymentRoutes.ElectronicPayment,
    component: ElectronicPaymentComponent,
  },
  {
    path: PaymentRoutes.PayTower,
    component: PayTowerPaymentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class PaymentRoutingModule {}
