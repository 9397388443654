import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DotAvailabilityService, DotButton, DotPage, DotPromoPagesLoader, getMainPage, getPage } from 'dotsdk';
import { ContentService } from '@dotxix/services/content.service';
import { HomeButtonDisplayType } from '@dotxix/models/enums/home-button-display-type';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { filterCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { pageContainsLinkInDepth } from '@dotxix/helpers/page.helper';
import { SessionService } from '@dotxix/services/session.service';
import { PromoFlowService } from '@dotxix/services/promo-flow.service';
import { MenuState } from '@dotxix/models/interfaces/menu-state';
import { appLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public emptyPage = new DotPage({ Buttons: [], NavigationButtons: [] });
  public state$: BehaviorSubject<MenuState> = new BehaviorSubject<MenuState>({
    history: [],
    page: this.emptyPage,
    availableButtons: [],
    selectedNavButtonLink: '',
    dropdownPage: null,
    isMainPage: true,
    navigationButtons: [],
    mainPage: this.emptyPage,
    displayHomePage: true,
    displayHomePageDocked: true,
  });

  constructor(
    private appSettings: ApplicationSettingsService,
    private contentService: ContentService,
    private sessionService: SessionService,
    private promoFlowService: PromoFlowService
  ) {
    this.updateMainPage();
    this.updateDisplayHomePageState();
    this.contentService.dataChanged$.subscribe(() => {
      this.updateMainPage();
    });
    this.appSettings.settings$.subscribe(() => {
      this.updateDisplayHomePageState();
    });
    this.sessionService.onRestartSession.subscribe(() => this.state$.next({ ...this.state$.value, page: this.emptyPage }));
  }

  public loadPage(page: DotPage) {
    appLogger.debug(`display page ${page.ID}`);
    qaAutomationLog('page: ', page);
    if (!page) {
      page = this.emptyPage;
    }
    if (this.state$.value.page === page) {
      this.hideDropdownPage();
    } else {
      this.state$.next({
        ...this.state$.value,
        ...this.computePageState(page),
        history: this.state$.value.mainPage === page ? [] : [...this.state$.value.history, this.state$.value.page.ID],
        dropdownPage: null,
      });
    }
  }

  public loadPageById(pageId: string) {
    const page = getPage(pageId) || getMainPage();
    this.loadPage(page);
  }

  public back() {
    const history = this.state$.value.history;
    if (history.length === 0) {
      return;
    }
    const previousPageId = history[history.length - 1];
    const page = previousPageId === this.state$.value.mainPage.ID ? getMainPage() : getPage(previousPageId);
    this.state$.next({
      ...this.state$.value,
      ...this.computePageState(page),
      history: history.slice(0, -1),
      dropdownPage: null,
    });
  }

  public onButtonSelected(button: DotButton) {
    if (
      this.state$.value.dropdownPage &&
      !this.state$.value.dropdownPage.Page.Buttons.find((dropdownPageButton) => dropdownPageButton === button)
    ) {
      this.hideDropdownPage();
    }
  }

  public hideDropdownPage() {
    this.state$.next({ ...this.state$.value, dropdownPage: null });
  }

  public toggleDropdown(button: DotButton) {
    qaAutomationLog('toggle dropdown: ', button);
    if (this.state$.value.dropdownPage === button) {
      this.hideDropdownPage();
    } else {
      this.state$.next({ ...this.state$.value, dropdownPage: button });
    }
  }

  public onMenuItemAddedToTheBasket(button: DotButton) {
    this.hideDropdownPage();
    this.checkJumpToPage(button);
  }

  public usePromoCode(promoCode: string) {
    const barcode =
      DotPromoPagesLoader.getInstance().loadedModel?.PromoList?.find((page) => page.PageID === promoCode)?.Description?.Barcode ?? '';
    this.promoFlowService.findByPageIDAndApply(barcode, promoCode);
  }

  private computePageState(page: DotPage) {
    const navigationButtons = this.computePageNavigationButtons(page);
    const isMainPage = this.state$.value.mainPage === page;
    const selectedNavButtonLink = isMainPage
      ? page.ID
      : navigationButtons.find((btn) => pageContainsLinkInDepth(page.ID, getPage(btn.Link)))?.Link ?? '';
    return {
      page: page,
      selectedNavButtonLink: selectedNavButtonLink,
      navigationButtons: navigationButtons,
      isMainPage: isMainPage,
      availableButtons: filterCatalogAvailable(page.Buttons),
    };
  }

  private updateMainPage() {
    this.state$.next({ ...this.state$.value, mainPage: getMainPage() });
  }

  private updateDisplayHomePageState() {
    this.state$.next({
      ...this.state$.value,
      displayHomePage: this.appSettings.settings$.value.homeButtonDisplay !== HomeButtonDisplayType.DO_NOT_DISPLAY,
      displayHomePageDocked: this.appSettings.settings$.value.homeButtonDisplay === HomeButtonDisplayType.DISPLAY_DOCKED,
    });
  }

  private computePageNavigationButtons(page: DotPage) {
    return page.NavigationButtons.filter((btn) => DotAvailabilityService.getInstance().isNavbarButtonAvailable(btn)).filter(
      (btn) => !!getPage(btn.Link)
    );
  }

  private checkJumpToPage(button: DotButton) {
    if (button.JumpToPage) {
      this.loadPageById(button.JumpToPage);
    }
  }
}
