import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DotButton, DotPrefixId } from 'dotsdk';
import { hasPrefixes } from '@dotxix/helpers/prefixes.helper';
import {
  isAboveChargeThreshold,
  modifierButtonDisplayPrice,
  modifierButtonHasDefaultQuantity,
  modifierButtonIsSelected,
  modifierButtonMaxQuantity,
  modifierButtonMinQuantity,
} from '@dotxix/helpers/modifiers.helper';
import { isButtonDisabled } from '@dotxix/helpers/button.helper';
import { ModifierButtonState } from '@dotxix/models/interfaces/modifier-button-state';
import { AllergensService } from '@dotxix/services/allergens.service';
import { ButtonInfoService } from '@dotxix/button-info.service';

@Component({
  selector: 'acr-button-modifier',
  templateUrl: './button-modifier.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonModifierComponent implements OnInit, OnChanges {
  @Input() public modifierButton!: DotButton;
  @Input() public groupMaxQuantity!: number;
  @Input() public disableIncrease!: boolean;
  @Input() public chargeThresholdGroup!: number;
  @Output() public clicked: EventEmitter<void> = new EventEmitter();
  @Output() public quantityChanged: EventEmitter<1 | -1> = new EventEmitter();

  public disabled!: boolean;
  public displayPrice!: number;
  public isAboveChargeThreshold!: boolean;
  public showCounterEdit!: boolean;
  public hasPrefixes!: boolean;
  public isSelected!: boolean;
  public minQuantity!: number;
  public maxQuantity!: number;
  public hasDefaultQuantity!: boolean;
  public disableIncreaseQuantity!: boolean;
  public disableDecreaseQuantity!: boolean;
  public showInfoButton!: boolean;

  constructor(
    private allergenService: AllergensService,
    private buttonInfoService: ButtonInfoService
  ) {}

  public ngOnInit(): void {
    if (!(this.modifierButton as DotButton<ModifierButtonState>).state.$$initialized) {
      console.error('Using button modifier on uninitialized modifier. See $$initialized usages');
    }
    this.showInfoButton = this.buttonInfoService.showProductInfo(this.modifierButton);
  }

  public ngOnChanges() {
    this.disabled = isButtonDisabled(this.modifierButton);
    this.displayPrice = modifierButtonDisplayPrice(this.modifierButton, this.chargeThresholdGroup);
    this.isAboveChargeThreshold = isAboveChargeThreshold(this.modifierButton, this.chargeThresholdGroup);
    this.hasPrefixes = hasPrefixes(this.modifierButton);
    this.isSelected = modifierButtonIsSelected(this.modifierButton);
    this.minQuantity = modifierButtonMinQuantity(this.modifierButton);
    this.maxQuantity = modifierButtonMaxQuantity(this.modifierButton);
    this.hasDefaultQuantity = modifierButtonHasDefaultQuantity(this.modifierButton);
    this.showCounterEdit = this.computeShowCounterEdit();
    this.disableIncreaseQuantity = this.isIncrementQuantityDisabled();
    this.disableDecreaseQuantity = this.isDecrementQuantityDisabled();
  }

  public async onButtonClicked() {
    const confirmed = await this.allergenConfirmation();
    if (!confirmed) {
      return;
    }

    this.clicked.emit();
  }

  public async onQuantityUpdate(count: 1 | -1) {
    if (count > 0) {
      if (false === (await this.allergenConfirmation())) {
        return;
      }
    }

    this.quantityChanged.emit(count);
  }

  private async allergenConfirmation() {
    if (this.hasPrefixes || this.modifierButton.Selected) {
      return true;
    }

    return await this.allergenService.confirmAllergenSelection(this.modifierButton);
  }

  private computeShowCounterEdit() {
    if (hasPrefixes(this.modifierButton)) {
      return true;
    }

    const maxQuantity = this.groupMaxQuantity ? Math.min(this.maxQuantity, this.groupMaxQuantity) : this.maxQuantity;
    return maxQuantity > 1;
  }

  private isDecrementQuantityDisabled(): boolean {
    if (this.hasPrefixes) {
      return this.modifierButton.selectedPrefixId === DotPrefixId.NO;
    } else {
      return this.modifierButton.quantity <= this.minQuantity;
    }
  }

  private isIncrementQuantityDisabled(): boolean {
    if (this.hasPrefixes) {
      return this.modifierButton.selectedPrefixId === DotPrefixId.EXTRA;
    }

    if (this.disableIncrease) {
      return true;
    }

    if (this.groupMaxQuantity && this.modifierButton.quantity >= this.groupMaxQuantity) {
      return true;
    }

    return this.modifierButton.quantity >= this.maxQuantity;
  }
}
