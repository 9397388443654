import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'acr-promos-scan-barcode',
  templateUrl: `promos-scan-barcode.component.html`,
})
export class PromosScanBarcodeComponent {
  @Output() public cancel = new EventEmitter<void>();
  public cancelClicked() {
    this.cancel.next();
  }
}
