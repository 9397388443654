import { CalculateTotalMode } from '@dotxix/models/enums/calculate-total-mode';
import { CheckoutType } from '@dotxix/models/enums/checkout-type';
import { HomeButtonDisplayType } from '@dotxix/models/enums/home-button-display-type';
import { MandatoryTableServiceSelection } from '@dotxix/table-service/models/enums/mandatory-table-service-selection';
import { PaymentFailType } from '@dotxix/models/enums/payment-fail-type';
import { NutritionalInformationDisplayType } from '@dotxix/models/enums/nutritional-information-display-type';
import { PromoDiscountsMode } from '@dotxix/models/enums/promos';
import { PromoInputOption } from '@dotxix/models/enums/promo-input-option';
import { RoundingStrategies } from '@dotxix/models/enums/rounding-strategies';
import { TableServiceType } from '@dotxix/table-service/models/enums/table-service-type';
import { TableServiceTier } from '@dotxix/table-service/models/enums/table-service-tier';
import { OrderCheckInFlow } from '@dotxix/models/enums/order-check-in-flow';
import { PeripheralCheckMode, PosServingLocation } from 'dotsdk';
import { AppSettings } from '@dotxix/models/interfaces/app-settings';
import { OrderRecallModes } from '@dotxix/models/enums/order-recall-modes';
import { DemoMode } from '@dotxix/models/enums/demo-mode';

export const appSettingsDefaultValues: AppSettings = {
  sharedFolderPath: '',
  bridgeAssetsPath: '',
  bannersPath: '',
  skipPrecalculate: false,
  posInjectionFlow: CheckoutType.PAY_AFTER_POS,
  maxPaymentRetries: 2,
  paymentFailRedirect: PaymentFailType.PAY_RETRY,
  languages: [],
  defaultLanguage: 'en',
  currencySymbol: '$',
  currencySymbolBefore: true,
  currencyDecimals: 2,
  decimalSeparator: '.',
  posInjectorPath: '',
  posInjectorPathTest: '',
  reverseBasketOrder: false,
  orderCheckInFlow: OrderCheckInFlow.BOTH,
  isServiceTypeStartScreen: false,
  serviceType: PosServingLocation.ALL,
  enableTouchToOrderSection: true,
  peripheralsCheckTimer: 30000,
  paymentCheckMode: PeripheralCheckMode.DoNotTest,
  printerCheckMode: PeripheralCheckMode.DoNotTest,
  scannerCheckMode: PeripheralCheckMode.DoNotTest,
  POSCheckMode: PeripheralCheckMode.DoNotTest,
  ignoreGloryDispensableEmptyStatus: false,
  kioskId: 0,
  paymentTypes: [],
  payTowerOrderApiUrl: '',
  payTowerOrderApiAuthKeys: [],
  isPayTower: false,
  payTowerTimeout: 5000,
  productRemovalWarning: false,
  quantityCalculated: false,
  homeButtonDisplay: HomeButtonDisplayType.DISPLAY_DOCKED,
  calculateTotalMode: CalculateTotalMode.DO_NOT_VOID_ON_RETURN,
  skipSinglePaymentSelection: true,
  viewBasketAfterProductSelection: false,
  nutritionalInformationDisplayType: NutritionalInformationDisplayType.DO_NOT_DISPLAY,
  enableComboAnimation: false,
  tableServiceMode: TableServiceType.DISABLED,
  mandatoryTSS: MandatoryTableServiceSelection.NONE,
  tableServiceTier: TableServiceTier.BRONZE,
  bunjeeAddress: '',
  inactivityWarningTimer: 300000,
  inactivityCloseTimer: 300000,
  printStoreDetails: true,
  storeName: '',
  storeCode: '',
  storeAddress1: '',
  storeAddress2: '',
  storeTelephone: '',
  printerMaxCharsPerRow: 40,
  printPOSReceipt: true,
  paymentRetryCounter: 10,
  receiptEFTPartialCut: false,
  ReceiptSmsEnabled: false,
  ReceiptEmailEnabled: false,
  ReceiptEmailFrom: '',
  ReceiptElectronicEnabled: false,
  promoInputOption: PromoInputOption.BOTH,
  gloryPayableAmount: 1000000,
  enableOMSModule: false,
  enableOMSRecall: false,
  OMSSendForPreOrder: false,
  tableServiceItem: '',
  promoDiscountsMode: PromoDiscountsMode.FULL_DISCOUNT,
  roundingStrategy: RoundingStrategies.ROUND_TO_NEAREST_OR_AWAY_FROM_ZERO,
  unlockOrder: true,
  tableServiceNumberPrefix: RoundingStrategies.ROUND_TO_NEAREST_OR_AWAY_FROM_ZERO,
  sendModifiersWithZeroQuantity: false,
  reloadAfterCompletedOrders: 0,
  touchlessMode: false,
  SubtotalIncludesVAT: 1,
  infiniteNavbarScroll: false,
  orderRecallMode: OrderRecallModes.Disabled,
  availableOsirisLoginMethods: [],
  osirisBaseUrl: '',
  osirisAuthKeys: [],
  faceRecallBaseUrl: '',
  faceRecallAuthToken: '',
  faceRecallRegisterUrl: '',
  osirisOrdersCount: 0,
  osirisFaceIdShowFullErrorMessages: false,
  addFailedOrdersIntoOsiris: false,
  hesetMobileBaseUrl: '',
  hesetWebSocketUrl: '',
  hesetApiUrl: '',
  showInfoButton: false,
  enableRecoModule: false,
  demoMode: DemoMode.DISABLED,
  demoDelay: 3000,
  smallTicket: false,
  allowNoCCReceipt: false,
  timeoutTicketQuestion: 30,
  addPaymentDetailsForSmallReceipt: false,
  addDefaultModifiersToElog: true,
  tentNumberMinimumValue: 1,
  tentNumberMaximumValue: 99,
  tentNumberFormatLength: 3,
  replaceTextOnReceipt: [],
  receiptHeaderText: '',
  minOrderAmount: 10,
};
