import { Component } from '@angular/core';
import { AllergensService } from '@dotxix/services/allergens.service';
import { DotAllergen } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';

@Component({
  selector: 'acr-allergen-selection',
  templateUrl: './allergen-selection.component.html',
})
export class AllergenSelectionComponent extends AbstractDynamicAnimatedPopup<undefined> {
  public supportedAllergenIcons: string[] = [
    'CER',
    'LUP',
    'SOJ',
    'LAI',
    'OEU',
    'CEL',
    'FRU',
    'ARA',
    'SES',
    'MOU',
    'POI',
    'MOL',
    'CRU',
    'SUL',
  ];
  public displayTabbedContent = false;

  public get allergens() {
    return this.allergensService.allergens;
  }

  constructor(private allergensService: AllergensService) {
    super();
  }

  public isAllergenIconAvailable(allergen: DotAllergen): boolean {
    return this.supportedAllergenIcons.includes(allergen.Name);
  }

  public isAllergenSelected(allergen: DotAllergen): boolean {
    return this.allergensService.selectedAllergens.some((a) => a.Name === allergen.Name);
  }

  public resetFilterClick() {
    this.allergensService.resetAllergens();
  }

  public closeClick() {
    this.close();
  }

  public allergensClick(allergen: DotAllergen) {
    if (this.allergensService.selectedAllergens.some((a) => a.Name === allergen.Name)) {
      this.allergensService.removeAllergen(allergen);
    } else {
      this.allergensService.addAllergen(allergen);
    }
  }
  public tabbedContentClick() {
    this.displayTabbedContent = !this.displayTabbedContent;
  }
}
