import { NutritionalInformationDisplayType } from '@dotxix/models/enums/nutritional-information-display-type';
import { PromoInputOption } from '@dotxix/models/enums/promo-input-option';

import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { StatusService } from '@dotxix/services/status.service';
import { CaloriesComponent } from '@dotxix/components/calories/calories.component';
import { AllergenSelectionComponent } from '@dotxix/components/allergen-selection/allergen-selection.component';
import { DotPageType, getHiddenPageList } from 'dotsdk';
import { Router } from '@angular/router';
import { OsirisRoutes } from '@dotxix/osiris/osiris-routes';
import { OrderRecallModes } from '@dotxix/models/enums/order-recall-modes';
import { PromoFlowService } from '@dotxix/services/promo-flow.service';

@Component({
  selector: 'acr-voucher-info',
  templateUrl: './voucher-info.component.html',
  host: { class: 'voucher-info' },
})
export class VoucherInfoComponent implements OnInit {
  public displayPromoButton: boolean | undefined;
  public displayOrderHistoryButton: boolean | undefined;

  public get displayCaloriesButton(): boolean {
    return (
      this.appSettings.settings$.value.nutritionalInformationDisplayType === NutritionalInformationDisplayType.ONLY_CALORIES ||
      this.appSettings.settings$.value.nutritionalInformationDisplayType === NutritionalInformationDisplayType.ALL
    );
  }

  public get displayAllergensButton(): boolean {
    return (
      this.appSettings.settings$.value.nutritionalInformationDisplayType === NutritionalInformationDisplayType.ONLY_ALLERGENS ||
      this.appSettings.settings$.value.nutritionalInformationDisplayType === NutritionalInformationDisplayType.ALL
    );
  }

  constructor(
    protected dynamicContentService: DynamicContentService,
    protected appSettings: ApplicationSettingsService,
    protected statusService: StatusService,
    protected router: Router,
    private promoFlowService: PromoFlowService
  ) {}

  public ngOnInit() {
    this.displayOrderHistoryButton =
      this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.Full &&
      this.appSettings.settings$.value.availableOsirisLoginMethods.some((method) => method.IsEnabled);
    this.displayPromoButton =
      this.appSettings.settings$.value.promoInputOption !== PromoInputOption.NONE &&
      getHiddenPageList(true)?.some((page) => page.PageType === DotPageType.MAIN_PROMO) &&
      (this.appSettings.settings$.value.promoInputOption === PromoInputOption.BOTH ||
        this.appSettings.settings$.value.promoInputOption === PromoInputOption.KEYBOARD ||
        (this.appSettings.settings$.value.promoInputOption === PromoInputOption.SCANNER &&
          this.statusService.state$.value.isScannerAvailableForApp));
  }

  public onVouchersButtonClicked() {
    this.promoFlowService.showVouchersScreen();
  }

  public toCalories(): void {
    this.dynamicContentService.openContent(CaloriesComponent, undefined);
  }

  public allergensClick() {
    this.dynamicContentService.openContent(AllergenSelectionComponent, undefined);
  }
  public orderHistoryClick() {
    this.router.navigate([OsirisRoutes.OrderHistory]);
    this.dynamicContentService.closeAllDialogs();
  }
}
