import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DotButton, DotButtonType, DotPage, generateUUID } from 'dotsdk';

import * as _ from 'lodash';
import { filterSuggestiveItems } from '@dotxix/helpers/suggestive-items.helper';
import { SuggestionSalesComponent } from '@dotxix/components/suggestion-sales/suggestion-sales.component';
import { RecommendationActionView } from '@dotxix/services/suggestions/models/recommendation-action-view';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import { DlgMessageService } from '@dotxix/services/dlg-message.service';
import { AllergensService } from '@dotxix/services/allergens.service';
import { MakeItAMealService } from '@dotxix/services/make-it-a-meal.service';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { RecoParameterKey } from '@dotxix/services/suggestions/models/reco-parameter-key';
import { hasMakeItAMeal } from '@dotxix/helpers/button.helper';
import { appLogger } from '@dotxix/log-manager';
import { initializeButtonWithModifiers } from '@dotxix/helpers/modifiers.helper';

export interface SuggestionsState {
  currentSuggestionPage: DotPage | undefined;
  currentRootPage: DotPage | undefined;
  isRootPage: boolean;
  isLastPage: boolean;
  disableAddToBag: boolean;
  disableButtons: boolean;
}

export const initialState: SuggestionsState = {
  currentSuggestionPage: undefined,
  currentRootPage: undefined,
  isRootPage: true,
  isLastPage: false,
  disableAddToBag: true,
  disableButtons: false,
};

@Injectable({
  providedIn: 'root',
})
export class SuggestionMenuService {
  public state$ = new BehaviorSubject<SuggestionsState>(initialState);

  private _suggestionPages: DotPage[] = [];
  private _currentIndex = 0;
  private _parentLinkUUID: string | undefined;
  private _originalSuggestionPages: DotPage[] = [];
  private _pagesHistory: DotPage[] = [];
  private _originalPagesHistory: DotPage[] = [];

  constructor(
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private applicationSettingsService: ApplicationSettingsService,
    private basketService: BasketService,
    private recommendationService: RecommendationService,
    private dlgMessageService: DlgMessageService,
    private allergensService: AllergensService,
    private makeItAMealService: MakeItAMealService,
    private productCustomizationService: ProductCustomizationService
  ) {}

  public async displayOrderSuggestionsAndWaitForSelection(suggestions: DotPage[]): Promise<void> {
    await this.displaySuggestionsPages(suggestions, RecommendationActionView.OrderSuggestion);
  }

  public async displayButtonSuggestionsAndWaitForSelection(button: DotButton<ButtonState>, suggestions: DotPage[]): Promise<void> {
    await this.displaySuggestionsPages(
      suggestions,
      button.hasCombos ? RecommendationActionView.MenuSuggestion : RecommendationActionView.ItemSuggestion,
      button
    );
  }

  public next(): void {
    if (!this._suggestionPages) {
      return;
    }
    if (this._currentIndex < this._suggestionPages.length - 1) {
      this._currentIndex++;
      this.updateState();
    }
  }

  public back(): void {
    if (this._currentIndex > 0) {
      this._currentIndex--;
      this.updateState();
    } else if (this._currentIndex === 0 && this._pagesHistory.length > 1) {
      this._pagesHistory.pop();
      this.updateState();
    }
  }

  public replaceButtonInCurrentPage(index: number, button: DotButton): void {
    this.getCurrentSuggestionPage().Buttons.splice(index, 1, button);

    this.updateState();
  }

  public revertToInitialButton(index: number): void {
    const currentPage = this.getCurrentSuggestionPage();
    const initialButton = this.getCurrentPageInitialButton(index);

    if (initialButton) {
      currentPage.Buttons.splice(index, 1, initialButton);
    }

    this.updateState();
  }

  public setSuggestionsSubPage(page: DotPage): void {
    this._pagesHistory.push(page);

    if (!this._originalPagesHistory.find((p) => p.ID === page.ID)) {
      this._originalPagesHistory.push(_.cloneDeep(page));
    }

    this.updateState();
  }

  public buttonClick(index: number, button: DotButton): void {
    if (button.ButtonType === DotButtonType.PAGE_BUTTON || button.ButtonType === DotButtonType.ITEM_PACK_BUTTON) {
      this.setSuggestionsSubPage(button.Page);
    } else if (!this.state$.value.disableButtons) {
      if (button.quantity === 0) {
        this.selectButton(index, button).then();
      } else {
        this.customizeButton(index, button);
      }
    }
  }

  public quantityChanged(index: number, button: DotButton, changedQuantity: 1 | -1): void {
    if (changedQuantity > 0) {
      if (button.quantity === 0) {
        this.selectButton(index, button).then();
      } else {
        button.quantity++;
        this.updateState();
      }
    } else if (changedQuantity < 0) {
      button.quantity--;
      if (button.quantity === 0) {
        this.revertToInitialButton(index);
      } else {
        this.updateState();
      }
    }
  }

  public addSelectedButtons(page: DotPage, recommendationAction: RecommendationActionView): void {
    page.Buttons.filter(
      (b) =>
        (b.ButtonType !== DotButtonType.PAGE_BUTTON && b.ButtonType !== DotButtonType.ITEM_PACK_BUTTON && b.Selected && b.quantity > 0) ||
        b.ButtonType === DotButtonType.PAGE_BUTTON ||
        b.ButtonType === DotButtonType.ITEM_PACK_BUTTON
    ).forEach((btn: DotButton<ButtonState>) => {
      if (btn.ButtonType === DotButtonType.PAGE_BUTTON || btn.ButtonType === DotButtonType.ITEM_PACK_BUTTON) {
        this.addSelectedButtons(btn.Page, recommendationAction);
      } else {
        if (btn.metadata?.suggestionMetadata?.shouldLinkToAParent) {
          btn.state.childLinkUUID = this._parentLinkUUID;
        }
        if (this.applicationSettingsService.settings$.value.enableRecoModule) {
          this.recommendationService.userAction(recommendationAction, btn, btn.quantity).catch(() => {});
        }
        this.basketService.addButtonToBasket(btn);
      }
    });
  }

  private displaySuggestionsPages(
    suggestions: DotPage[],
    recommendationActionView: RecommendationActionView,
    button: DotButton<ButtonState> | null = null
  ): Promise<void> {
    return new Promise((resolve) => {
      suggestions = suggestions.filter((suggestion) => suggestion.Buttons.length > 0);
      if (suggestions && suggestions.length > 0) {
        suggestions = filterSuggestiveItems(suggestions);
        if (suggestions.length > 0) {
          appLogger.debug(`displaying suggestions`);
          if (button) {
            button.state.parentLinkUUID = button.state.parentLinkUUID || generateUUID();
          }
          this.addDefaultSuggestionTitleForPages(suggestions);
          this.setSuggestionMenu(suggestions, button ? button.state.parentLinkUUID ?? '' : '');
          const contentRef = this.dynamicContentService.openContent(SuggestionSalesComponent, {
            recommendationAction: recommendationActionView,
          });
          contentRef.afterClosed.subscribe(() => {
            this.state$.next(initialState);
            this.reset();

            resolve();
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  private getCurrentPageInitialButton(index: number): DotButton | null {
    let initialButton: DotButton | undefined;

    if (this._pagesHistory.length > 1) {
      initialButton = this._originalPagesHistory.find((p) => p.ID === this.getCurrentSuggestionPage().ID)?.Buttons[index];
    } else {
      initialButton = this._originalSuggestionPages[this._currentIndex].Buttons[index];
    }

    return initialButton ? _.cloneDeep(initialButton) : null;
  }

  private setSuggestionMenu(suggestionPages: DotPage[], parentLinkUUID: string): void {
    suggestionPages.forEach((page) => {
      page.Buttons = page.Buttons.map((button) => initializeButtonWithModifiers(button, false));
    });

    this._originalSuggestionPages = suggestionPages;
    this._suggestionPages = _.cloneDeep(suggestionPages);
    this._parentLinkUUID = parentLinkUUID;
    this._pagesHistory.push(suggestionPages[0]);
    this._originalPagesHistory.push(_.cloneDeep(suggestionPages[0]));

    this.updateState();

    if (
      this.state$.value.currentSuggestionPage &&
      this.state$.value.currentSuggestionPage.Buttons.length === 1 &&
      (this.state$.value.currentSuggestionPage.Buttons[0].ButtonType === DotButtonType.PAGE_BUTTON ||
        this.state$.value.currentSuggestionPage.Buttons[0].ButtonType === DotButtonType.ITEM_PACK_BUTTON)
    ) {
      this.buttonClick(0, this.state$.value.currentSuggestionPage.Buttons[0]);
    }
  }

  private addDefaultSuggestionTitleForPages(pages: DotPage[]): void {
    pages.forEach((page) => {
      if (!page.Title && !page.TitleDictionary) {
        page.Title = this.translationsService.translate('85');
      }
    });
  }

  private isRootPage(): boolean {
    return this._pagesHistory.length === 1;
  }

  private getCurrentSuggestionPage(): DotPage {
    if (this._pagesHistory.length > 1) {
      return this._pagesHistory[this._pagesHistory.length - 1];
    } else {
      return this._suggestionPages[this._currentIndex];
    }
  }

  private checkNumberOfSelectedItems(page: DotPage): number {
    let numberOfSelectedItems = 0;

    page.Buttons.forEach((btn) => {
      if (btn.ButtonType === DotButtonType.PAGE_BUTTON || btn.ButtonType === DotButtonType.ITEM_PACK_BUTTON) {
        numberOfSelectedItems += this.checkNumberOfSelectedItems(btn.Page);
      } else {
        numberOfSelectedItems += btn.Selected && btn.quantity > 0 ? btn.quantity : 0;
      }
    });

    return numberOfSelectedItems;
  }

  private getDisableButtons(): boolean {
    if (!this.getCurrentRootPage().MaxQty || this.getCurrentRootPage().MaxQty === 0) {
      return false;
    }

    return this.checkNumberOfSelectedItems(this.getCurrentRootPage()) >= this.getCurrentRootPage().MaxQty;
  }

  private getCurrentRootPage(): DotPage {
    return this._suggestionPages[this._currentIndex];
  }

  private isLastPage(): boolean {
    return this._currentIndex === this._suggestionPages.length - 1;
  }

  private async selectButton(index: number, button: DotButton<ButtonState>): Promise<void> {
    if (button.quantity === 0) {
      let selectedButton = button;
      selectedButton.quantity = selectedButton.quantity ? selectedButton.quantity : 1;
      selectedButton.MaxQuantity =
        this.state$.value.currentRootPage &&
        this.state$.value.currentRootPage.MaxQty !== null &&
        this.state$.value.currentRootPage.MaxQty !== 0
          ? this.state$.value.currentRootPage.MaxQty - this.checkNumberOfSelectedItems(this.state$.value.currentRootPage)
          : this.state$.value.currentRootPage?.MaxQty ?? 0;
      if (this.applicationSettingsService.settings$.value.enableRecoModule) {
        if (
          this.recommendationService.sessionParameters[RecoParameterKey.RecommendationEnableUpgrade] &&
          !button.hasCombos &&
          hasMakeItAMeal(button)
        ) {
          const makeItAMealSelection = await this.makeItAMealService.selectMakeItAMealButton(button);
          if (makeItAMealSelection) {
            selectedButton = makeItAMealSelection;
            selectedButton.metadata.suggestionMetadata = button.metadata.suggestionMetadata;
            selectedButton.state.recommendationMode = button.state.recommendationMode;
          }
        }
      }

      if (false === (await this.dlgMessageService.confirm(selectedButton))) {
        this.updateState();

        return;
      }
      if (false === (await this.allergensService.confirmAllergenSelection(selectedButton))) {
        this.updateState();

        return;
      }

      const { customized, resultButton } = await this.productCustomizationService.customizeProduct({
        product: selectedButton,
        updatingProduct: false,
      });
      if (customized) {
        if (resultButton) {
          resultButton.Selected = resultButton.quantity > 0;
          this.replaceButtonInCurrentPage(index, resultButton);
        } else {
          selectedButton.quantity = 0;
        }
      } else {
        selectedButton.Selected = true;
        selectedButton.quantity = 1;
        this.replaceButtonInCurrentPage(index, selectedButton);
      }
    } else {
      button.quantity++;
      this.updateState();
    }
  }

  private async customizeButton(index: number, button: DotButton): Promise<void> {
    button.MaxQuantity =
      this.state$.value.currentRootPage &&
      this.state$.value.currentRootPage.MaxQty !== null &&
      this.state$.value.currentRootPage.MaxQty !== 0
        ? button.quantity === 0
          ? this.state$.value.currentRootPage.MaxQty - this.checkNumberOfSelectedItems(this.state$.value.currentRootPage)
          : this.state$.value.currentRootPage.MaxQty - this.checkNumberOfSelectedItems(this.state$.value.currentRootPage) + button.quantity
        : this.state$.value.currentRootPage?.MaxQty ?? 0;
    const { customized, resultButton } = await this.productCustomizationService.customizeProduct({
      product: button,
      updatingProduct: true,
    });
    if (customized) {
      if (resultButton) {
        this.replaceButtonInCurrentPage(index, resultButton);
      }
    } else {
      button.quantity++;
      this.updateState();
    }
  }

  private updateState(): void {
    this.state$.next({
      ...this.state$.value,
      currentSuggestionPage: this.getCurrentSuggestionPage(),
      currentRootPage: this.getCurrentRootPage(),
      isRootPage: this.isRootPage(),
      isLastPage: this.isLastPage(),
      disableAddToBag: this.checkNumberOfSelectedItems(this.getCurrentRootPage()) < 1,
      disableButtons: this.getDisableButtons(),
    });
  }

  private reset(): void {
    this._suggestionPages = [];
    this._currentIndex = 0;
    this._parentLinkUUID = undefined;
    this._originalSuggestionPages = [];
    this._pagesHistory = [];
    this._originalPagesHistory = [];
  }
}
