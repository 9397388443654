import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { AtpEnvironmentService, AtpFilesService } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';

@Component({
  selector: 'acr-calories',
  templateUrl: './calories.component.html',
})
export class CaloriesComponent extends AbstractDynamicAnimatedPopup<undefined> implements OnInit {
  public images!: string[];
  public currentIndex = 0;

  public get currentImage(): string {
    return this.images && this.images.length > 0 ? this.images[this.currentIndex] : '';
  }

  constructor(protected applicationSettingsService: ApplicationSettingsService) {
    super();
  }
  public async ngOnInit(): Promise<void> {
    let allergenFileList = [];
    if (AtpEnvironmentService.getInstance().mBirdIsConnected()) {
      allergenFileList = await AtpFilesService.getInstance().getDirectoryContent('shared\\assets\\skins\\fullHD\\DAHComponent\\allergens');
      const regex = new RegExp('(jpg|png|gif|webp)$');
      this.images = allergenFileList
        ? allergenFileList?.Files?.filter((f: string) => regex.test(f)).map(
            (f: string) =>
              `${
                this.applicationSettingsService.settings$.value.bridgeAssetsPath
              }\\skins\\fullHD\\DAHComponent\\allergens\\${f}?t=${new Date().getTime()}`
          )
        : [];
    }
  }
  public closeClick(): void {
    this.close();
  }
  public previousImage(): void {
    this.currentIndex--;
  }
  public nextImage(): void {
    this.currentIndex++;
  }
}
