import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DotButton, DotButtonDisplayMode } from 'dotsdk';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import * as _ from 'lodash';
import { MenuService } from '@dotxix/services/menu.service';
import { AdaptabilityService } from '@dotxix/services/adaptability.service';
import { findClosestIndex } from '@dotxix/helpers/array.helper';

@Component({
  selector: 'acr-nav-slider',
  templateUrl: './nav-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavSliderComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('scrollRef') public scrollRef!: ElementRef;
  @ViewChildren('navList') navList!: QueryList<ElementRef>;

  public mainPageId = '';
  public dockHomePage = true;
  public displayNavButtons: DotButton[] = [];
  public hasNavArrows = false;
  public disableRightArrow = false;
  public disableLeftArrow = true;
  public selectedNavButtonLink!: string;

  private subscriptions: Subscription[] = [];
  private displayHomePage = true;
  private buttonPaddingSize = 40;
  private buttonSize = 280;
  private originalNavButtons: DotButton[] = [];

  private readonly infiniteScrollCheckDebounceTime = 50;
  private readonly debouncedInfiniteScrollCheck: () => void = () => {};

  constructor(
    private menuService: MenuService,
    private orderFlowService: OrderFlowService,
    private adaptabilityService: AdaptabilityService,
    private appSettings: ApplicationSettingsService,
    private changeDetector: ChangeDetectorRef
  ) {
    if (this.appSettings.settings$.value.infiniteNavbarScroll) {
      this.debouncedInfiniteScrollCheck = _.debounce(() => this.infiniteScrollCheck(), this.infiniteScrollCheckDebounceTime, {
        leading: true,
        maxWait: this.infiniteScrollCheckDebounceTime,
        trailing: true,
      });
    }
  }

  public ngOnInit(): void {
    this.hasNavArrows = this.appSettings.settings$.value.touchlessMode;
    this.updateButtonSize(this.adaptabilityService.isAdaEnabled$.value);
    this.subscriptions.push(
      this.adaptabilityService.isAdaEnabled$.subscribe((enabled) => {
        this.updateButtonSize(enabled);
        this.debouncedInfiniteScrollCheck();
        this.evaluateDisable();
        setTimeout(() => this.scrollIntoViewClosestSelectedOrFirstNavButton(), 0);
      })
    );
    this.subscriptions.push(
      this.menuService.state$.subscribe((state) => {
        this.dockHomePage = state.displayHomePageDocked;
        this.displayHomePage = state.displayHomePage;
        this.mainPageId = state.mainPage.ID;
        this.setNavButtons(state.navigationButtons);
        this.setSelectedNavButtonLink(state.selectedNavButtonLink);
        this.changeDetector.detectChanges();
      })
    );
  }

  public ngAfterViewChecked() {
    this.evaluateDisable();
  }

  public updateButtonSize(adaEnabled: boolean) {
    const newButtonSize = adaEnabled ? 224 : 280;
    if (this.buttonSize !== newButtonSize) {
      this.buttonSize = newButtonSize;
      this.scrollIntoViewClosestSelectedOrFirstNavButton();
    }
  }

  public trackByIndex(index: number) {
    return index;
  }

  public scrollRight() {
    const scrollRef = this.scrollRef.nativeElement;
    scrollRef.scrollTo({ left: scrollRef.scrollLeft + this.buttonSize });
  }

  public scrollLeft() {
    const scrollRef = this.scrollRef.nativeElement;
    scrollRef.scrollTo({ left: scrollRef.scrollLeft - this.buttonSize });
  }

  public onNavButtonClicked(button: DotButton) {
    this.orderFlowService.onNavigationButtonClicked(button.Link).then(() => {});
  }

  public goToHomePage() {
    this.orderFlowService.onNavigationButtonClicked(this.mainPageId).then(() => {});
  }

  public onScrollContainerScrolled() {
    this.debouncedInfiniteScrollCheck();
    this.evaluateDisable();
  }

  public evaluateDisable() {
    if (this.scrollRef) {
      const nativeElement = this.scrollRef.nativeElement;
      const disableLeft = nativeElement.scrollLeft === 0;
      const disableRight = nativeElement.scrollLeft + nativeElement.clientWidth === nativeElement.scrollWidth;
      if (disableLeft !== this.disableLeftArrow || disableRight !== this.disableRightArrow) {
        setTimeout(() => {
          this.disableLeftArrow = disableLeft;
          this.disableRightArrow = disableRight;
          this.changeDetector.markForCheck();
        }, 0);
      }
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public displayMode(button: DotButton, imageOnlyClass?: string, textOnlyClass?: string) {
    switch (button.DisplayMode) {
      case DotButtonDisplayMode.IMAGE_ONLY:
        return imageOnlyClass;
      case DotButtonDisplayMode.TEXT_ONLY:
        return textOnlyClass;
      default:
        return '';
    }
  }

  private setNavButtons(navButtons: DotButton[]) {
    let displaySetButtons;
    if (this.displayHomePage) {
      if (this.dockHomePage) {
        displaySetButtons = navButtons;
      } else {
        displaySetButtons = [this.mainPageScrolledButton(), ...navButtons];
      }
    } else {
      displaySetButtons = navButtons;
    }
    if (!_.isEqual(displaySetButtons, this.originalNavButtons)) {
      this.displayNavButtons = displaySetButtons;
      this.originalNavButtons = displaySetButtons;
      this.debouncedInfiniteScrollCheck();
      this.evaluateDisable();
    }
  }

  private setSelectedNavButtonLink(value: string) {
    this.selectedNavButtonLink = value;
    setTimeout(() => {
      this.scrollIntoViewClosestSelectedOrFirstNavButton();
      this.evaluateDisable();
    }, 0);
  }

  private scrollIntoViewClosestSelectedOrFirstNavButton() {
    if (this.navList && this.navList.length > 0) {
      if (this.appSettings.settings$.value.infiniteNavbarScroll) {
        let availableIndexes;
        const navButtonsWithSelectedNavButtonLinkIndexes = this.navList
          .map((el, index) => ({ element: el, index }))
          .filter(({ element }) => element.nativeElement.dataset.link === this.selectedNavButtonLink)
          .map(({ index }) => index);
        if (navButtonsWithSelectedNavButtonLinkIndexes.length === 0) {
          const firstItemLink = this.navList.get(0)?.nativeElement.dataset.link;
          availableIndexes = this.navList
            .map((el, index) => ({ element: el, index }))
            .filter(({ element }) => element.nativeElement.dataset.link === firstItemLink)
            .map(({ index }) => index);
        } else {
          availableIndexes = navButtonsWithSelectedNavButtonLinkIndexes;
        }
        if (availableIndexes.length === 0) {
          availableIndexes = [0];
        }
        const buttonSizeWithoutPadding = this.buttonSize - this.buttonPaddingSize;
        const scrollIndex = Math.ceil(this.scrollRef.nativeElement.scrollLeft / buttonSizeWithoutPadding);
        const closestIndex = findClosestIndex(scrollIndex, availableIndexes);
        this.scrollRef.nativeElement.scrollTo({
          left: closestIndex * buttonSizeWithoutPadding,
        });
      } else {
        let focusButtonEl;
        const selectedNavButton = this.navList.find((element) => element.nativeElement.dataset.link === this.selectedNavButtonLink);
        if (selectedNavButton) {
          focusButtonEl = selectedNavButton.nativeElement;
        } else {
          focusButtonEl = this.navList.get(0)?.nativeElement;
        }
        if (focusButtonEl) {
          focusButtonEl.scrollIntoView({ inline: 'start' });
        }
      }
    }
  }

  private infiniteScrollCheck() {
    if (this.scrollRef) {
      const nativeElement = this.scrollRef.nativeElement;
      if (nativeElement) {
        if (nativeElement.clientWidth > this.buttonSize * this.displayNavButtons.length) {
          return;
        }

        if (nativeElement.scrollWidth - nativeElement.scrollLeft < this.buttonSize * this.originalNavButtons.length) {
          this.displayNavButtons = [...this.displayNavButtons, ...this.originalNavButtons];
        }
      }
    }
  }

  private mainPageScrolledButton() {
    return new DotButton({
      Link: this.mainPageId,
    });
  }
}
