import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from '@dotxix/app-routing.module';
import { AppComponent } from '@dotxix/app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PagesModule } from '@dotxix/pages/pages.module';
import { ComponentsModules } from '@dotxix/components/components.module';
import { OsirisModule } from '@dotxix/osiris/osiris.module';
import { ReceiptContentBuilder } from 'dotsdk';
import { SetAsDynamicContainerDirective } from '@dotxix/services/dynamic-content/set-as-dynamic-container.directive';
import { TableServiceModule } from '@dotxix/table-service/table-service.module';
import { CustomThemeModule } from '@dotxix/custom-theme/custom-theme.module';
import { AppInitService } from '@dotxix/services/app-init.service';
import { PaymentModule } from '@dotxix/payment/payment.module';
import { SkinModule } from '@dotxix/skin/skin.module';
import { GlobalErrorHandler } from '@dotxix/services/global-error-handler.service';

@NgModule({
  declarations: [AppComponent, SetAsDynamicContainerDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    ComponentsModules,
    SkinModule,
    OsirisModule,
    TableServiceModule,
    CustomThemeModule,
    PaymentModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => {
        return () => appInitService.initialize();
      },
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: 'ReceiptContentBuilder',
      useClass: ReceiptContentBuilder,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
