import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BasketService } from '@dotxix/services/basket.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'acr-basket-toggler',
  templateUrl: './basket-toggler.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketTogglerComponent implements OnInit, OnDestroy {
  @Input() basketOpened!: boolean;
  @Output() basketOpenedChange = new EventEmitter<boolean>();

  public numberOfItems!: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private basketService: BasketService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.subscriptions.push(this.basketService.openBasketRequested.subscribe(() => this.setBasketOpened(true)));
    this.subscriptions.push(this.basketService.closeBasketRequested.subscribe(() => this.setBasketOpened(false)));
    this.subscriptions.push(
      this.basketService.numberOfItems$.subscribe((value) => {
        this.numberOfItems = value;
        this.changeDetector.detectChanges();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public click() {
    this.setBasketOpened(!this.basketOpened);
  }

  private setBasketOpened(newValue: boolean) {
    this.basketOpened = newValue;
    this.basketOpenedChange.emit(this.basketOpened);
  }
}
