import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { GloryCashFlowService } from '@dotxix/payment/services/glory-cash-flow.service';

@Component({
  selector: 'acr-glory-cash-payment-progress',
  templateUrl: './glory-cash-payment-progress.component.html',
  host: { class: 'flex flex--column flex--1' },
})
export class GloryCashPaymentProgressComponent {
  public paymentServiceState$ = this.paymentService.state$;
  public cashEndAcceptMoneyOngoing$ = this.paymentServiceState$.pipe(map((state) => state.cash.endAcceptMoneyOngoing));
  public amountDue$ = this.paymentServiceState$.pipe(
    map((state) => state.cash.maximumAmountThatCanBePaidAtTransactionStart - state.cash.currentTransactionPaidAmount)
  );

  constructor(
    private paymentService: PaymentService,
    private gloryCashFlowService: GloryCashFlowService
  ) {}

  public cancelClick() {
    this.gloryCashFlowService.cancelCurrentCashCurrentTransaction();
  }

  public async finishWithOtherPayment() {
    this.gloryCashFlowService.finishWithOtherPayment();
  }
}
