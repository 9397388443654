import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-glory-cash-cash-back-failed',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001025'"
      [amounts]="[
        { label: '2001032', value: amountRefunded },
        { label: '2001037', value: failedToRefundAmount }
      ]"
    ></acr-glory-cash-message>

    <!-- Amount refunded: {amountRefunded}-->
    <!-- Failed to refund: {failedToRefundAmount}-->

    <!-- Your refund amount will be printed.|Please take your receipt to front counter|for full refund. --> `,
})
export class GloryCashCashBackFailedComponent {
  public amountRefunded;
  public failedToRefundAmount;
  constructor(private paymentService: PaymentService) {
    this.amountRefunded = this.paymentService.state$.value.cash.cashBack.cashBackRefundedAmount;
    this.failedToRefundAmount = this.paymentService.state$.value.cash.cashBack.cashBackFailedToRefundAmount;
  }
}
