import { Injectable } from '@angular/core';
import { AtpScannerService } from 'dotsdk';
import { Subject, Subscription } from 'rxjs';
import { SessionService } from '@dotxix/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class PromoScannerService {
  public barcode$ = new Subject<string>();
  private scanSubscription: Subscription | null = null;

  constructor(private sessionService: SessionService) {
    this.sessionService.onRestartSession.subscribe(() => this.stopScanning());
  }

  public startScanning() {
    if (this.scanSubscription) {
      return;
    }
    this.scanSubscription = AtpScannerService.getInstance()
      .startContinuouslyScan()
      .subscribe({
        next: (code) => {
          this.barcode$.next(code);
        },
        error: () => {
          this.barcode$.error(null);
          this.stopScanning();
        },
      });
  }

  public stopScanning() {
    if (this.scanSubscription) {
      this.scanSubscription.unsubscribe();
      this.scanSubscription = null;
    }
  }
}
