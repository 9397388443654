import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DotButton, DotPage } from 'dotsdk';
import { combineLatest, Subscription } from 'rxjs';
import { MenuService } from '@dotxix/services/menu.service';
import { BasketService } from '@dotxix/services/basket.service';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import { AdaptabilityService } from '@dotxix/services/adaptability.service';
import { RecommendationActionView } from '@dotxix/services/suggestions/models/recommendation-action-view';
import { OrderFlowService } from '@dotxix/services/order-flow.service';

@Component({
  selector: 'acr-menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnDestroy {
  public allowToCheckout$ = this.basketService.allowToCheckout$;
  public state$ = this.menuService.state$;

  public loadingPopularSuggestions$ = this.recommendationService.loadingPopularSuggestions$;
  public popularSuggestions$ = this.recommendationService.popularSuggestions$;

  @ViewChild('scrollRef') public scrollRef: ElementRef | undefined;
  public dropdownPageRow = 0;
  public subscriptions: Subscription[] = [];
  public basketOpened = false;
  public displayHighlightedButtons = false;
  public highlightedButtons: DotButton[] = [];
  public defaultSizeButtons: DotButton[] = [];

  private lastPage: DotPage | undefined;

  constructor(
    public basketService: BasketService,
    private changeDetector: ChangeDetectorRef,
    private menuService: MenuService,
    private orderFlowService: OrderFlowService,
    private recommendationService: RecommendationService,
    private adaptabilityService: AdaptabilityService
  ) {
    this.subscriptions.push(
      combineLatest([this.state$, this.adaptabilityService.isAdaEnabled$]).subscribe(([state, isAdaEnabled]) => {
        this.highlightedButtons = isAdaEnabled ? [] : state.availableButtons.filter((btn) => btn.Highlight === '1');
        this.defaultSizeButtons = isAdaEnabled
          ? state.availableButtons
          : state.availableButtons.filter((btn) => btn.Highlight === undefined || btn.Highlight === null || btn.Highlight === '0');
        this.displayHighlightedButtons = this.highlightedButtons.length > 0;
        const { page, dropdownPage } = state;
        const dropdownIndex = dropdownPage ? this.defaultSizeButtons.findIndex((b) => b === dropdownPage) : 0;
        this.dropdownPageRow = Math.trunc(dropdownIndex / 3) + 2;
        if (page !== this.lastPage) {
          this.scrollToTop();
        }
        this.lastPage = page;
        this.changeDetector.markForCheck();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public select(button: DotButton, isDropDownButton: boolean) {
    const view = this.state$.value.isMainPage ? RecommendationActionView.Home : RecommendationActionView.Page;
    this.orderFlowService.onMenuButtonSelected(button, view, !isDropDownButton).then();
  }

  public selectPopularSuggestion(button: DotButton) {
    this.orderFlowService.onPopularSuggestionSelected(button).then();
  }

  public selectBasketSuggestion(button: DotButton) {
    this.orderFlowService.onBasketSuggestionSelected(button).then();
  }

  public back() {
    this.menuService.back();
  }

  public onVerticalScrollButtonClick() {
    this.menuService.hideDropdownPage();
  }

  private scrollToTop() {
    this.scrollRef?.nativeElement?.scrollTo({ top: 0 });
  }
}
