import { Component, Input } from '@angular/core';
import { OrderFlowService } from '@dotxix/services/order-flow.service';

@Component({
  selector: 'acr-confirm-order',
  templateUrl: './confirm-order.component.html',
})
export class ConfirmOrderComponent {
  @Input() public isDisabled = false;

  constructor(private orderFlowService: OrderFlowService) {}
  public async click() {
    this.orderFlowService.onCheckoutButtonClicked().then(() => {});
  }
}
