import { Injectable } from '@angular/core';
import { AtpApplicationSettings, PosRefintService, SDK_VERSION } from 'dotsdk';
import { Meta } from '@angular/platform-browser';
import { SessionService } from '@dotxix/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  constructor(
    private meta: Meta,
    private sessionService: SessionService
  ) {}

  public initialize() {
    this.updateMetaTags();
    this.sessionService.onRestartSession.subscribe(() => this.updateMetaTags());
    this.sessionService.sessionStarted.subscribe(() => this.updateMetaTags());
  }

  public updateMetaTags() {
    const refInt = PosRefintService.getInstance()._refInt;
    this.meta.addTags([
      { name: 'app-version', content: AtpApplicationSettings.getInstance().applicationDetailsJson.AppVersion },
      { name: 'sdk-version', content: SDK_VERSION },
      ...(typeof refInt === 'number' ? [{ name: 'refint', content: refInt.toString() }] : []),
    ]);
  }
}
