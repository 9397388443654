import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-glory-cash-cash-back-progress',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001034'"
      [amounts]="[{ label: '2001035', value: toBeRefundedAmount }]"
    ></acr-glory-cash-message>

    <!-- Refunding: {toBeRefundedAmount}-->

    <!-- Your refund is in the works. |Please wait for your transaction to be processed. --> `,
})
export class GloryCashCashBackProgressComponent {
  public toBeRefundedAmount;
  constructor(private paymentService: PaymentService) {
    this.toBeRefundedAmount =
      this.paymentService.state$.value.cash.cashPaidAmount + this.paymentService.state$.value.cash.cashFailedToRefundAmount;
  }
}
