import { Component, OnInit } from '@angular/core';
import { BasketService } from '@dotxix/services/basket.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { PromosService } from '@dotxix/services/promos.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

import { DotButton, DotButtonType, generateUUID, PromoNode } from 'dotsdk';

import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { PromoStepperDataParams } from '@dotxix/models/interfaces/promo-stepper-data-params';
import { RecommendationActionView } from '@dotxix/services/suggestions/models/recommendation-action-view';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';
import { StepperStep } from '@dotxix/models/interfaces/stepper-step';
import { computeStepperSteps, selectedPromotionButtons, selectPromoButton } from '@dotxix/helpers/promo.helper';
import { ButtonRecommendationMode } from '@dotxix/services/suggestions/models/button-recommendation-mode';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { initializeModifiersQuantities } from '@dotxix/helpers/modifiers.helper';

@Component({
  selector: 'acr-promo-stepper',
  templateUrl: './promo-stepper.component.html',
})
export class PromoStepperComponent extends AbstractDynamicAnimatedPopup<PromoStepperDataParams> implements OnInit {
  public DotButtonType = DotButtonType;
  public stepperSteps: StepperStep[] | undefined;
  public promotionState$ = this.promosService.state$;
  public currentStepIndex$ = this.promosService.currentStepIndex$;

  protected currentPromoNode$ = this.promosService.currentPromoNode$;
  private firstPromoNode: PromoNode | undefined;

  constructor(
    private dynamicContentService: DynamicContentService,
    protected promosService: PromosService,
    private basketService: BasketService,
    private translationsService: TranslationsService,
    private recommendationService: RecommendationService,
    private productCustomizationService: ProductCustomizationService
  ) {
    super();
  }

  public ngOnInit() {
    this.firstPromoNode = this.dataParams?.promoNode;
    if (this.firstPromoNode) {
      this.stepperSteps = computeStepperSteps(this.firstPromoNode, this.translationsService.translate('2021050702'));
      this.promosService.loadPromoNode(this.firstPromoNode, []);
    }
  }

  public async promoButtonClicked(button: DotButton, index: number) {
    if (button.ButtonType === DotButtonType.PAGE_BUTTON || button.ButtonType === DotButtonType.ITEM_PACK_BUTTON) {
      this.promosService.setPromotionSubPage(button.Page);
      return;
    }
    if (!this.promotionState$.value.disableButtons) {
      if (button.quantity > 0) {
        await this.updatePromotionButton(button, index);
      } else {
        await this.selectWithCustomizeScreen(button, index);
      }
      this.promosService.updateState();
    } else if (button.Selected) {
      this.deselectPromoButton(button);
      this.promosService.updateState();
    }
  }

  public async onQuantityUpdate(button: DotButton, index: number, change: 1 | -1) {
    if (change > 0) {
      if (!this.promotionState$.value.disableButtons) {
        if (button.quantity === 0) {
          await this.selectWithCustomizeScreen(button, index);
        } else {
          button.quantity++;
        }
      }
    } else {
      if (button.quantity === 1) {
        this.deselectPromoButton(button);
      } else if (button.quantity > 0) {
        button.quantity--;
      }
    }
    this.promosService.updateState();
  }

  public nextStep() {
    if (this.currentPromoNode$.value?.next) {
      this.loadNextPromoNode();
    } else {
      this.closeAndAddToBasket();
    }
  }

  public previousStep() {
    this.promosService.goToPreviousStep();
  }

  private loadNextPromoNode() {
    this.promosService.goToNextStep();
  }

  public cancelClick(): void {
    this.closeWithConfirmation();
  }

  public computeMaxQuantity(button: DotButton) {
    return Math.min(button.MaxQuantity || Number.MAX_SAFE_INTEGER, this.promotionState$.value.allowedQuantity);
  }

  private async updatePromotionButton(button: DotButton, index: number) {
    const { customized, resultButton } = await this.productCustomizationService.customizeProduct({
      product: button,
      updatingProduct: true,
    });
    if (customized) {
      if (resultButton) {
        resultButton.quantity = button.quantity;
        this.promotionState$.value.currentPromotionPage?.Buttons.splice(index, 1, resultButton);
      }
    } else {
      button.quantity++;
    }
  }

  private async selectWithCustomizeScreen(button: DotButton, index: number) {
    const selectedButton = await this.openAddCustomizeScreen(button);
    if (selectedButton) {
      selectPromoButton(selectedButton);
      this.promotionState$.value.currentPromotionPage?.Buttons.splice(index, 1, selectedButton);
    }
  }

  private async openAddCustomizeScreen(button: DotButton) {
    button.isPromo = true;
    const { customized, resultButton } = await this.productCustomizationService.customizeProduct({
      product: button,
      updatingProduct: false,
    });

    if (customized) {
      if (resultButton) {
        return resultButton;
      }
    } else {
      initializeModifiersQuantities(button);
      return button;
    }
    return null;
  }

  private closeAndAddToBasket() {
    const promotionUUID = generateUUID();
    const promotionName = this.dataParams?.promoName ?? '';
    const promotionPageID = this.dataParams?.promoPageID ?? '';
    const selectedProducts = this.getAllSelectedProducts();
    selectedProducts.forEach((btn: DotButton<ButtonState>) => {
      btn.isPromo = true;
      btn.state.promoMetadata = {
        name: promotionName,
        barcode: this.dataParams?.barcode ?? '',
        UUID: promotionUUID,
        pageID: promotionPageID,
      };
      btn.state.recommendationMode = ButtonRecommendationMode.EMPTY;
    });
    qaAutomationLog('promotion products added to basket', selectedProducts);
    selectedProducts.forEach((btn: DotButton<ButtonState>) => {
      this.recommendationService.userAction(RecommendationActionView.KioskOffer, btn, btn.quantity).then();
      this.basketService.addButtonToBasket(btn);
    });
    this.promosService.addActivePromo({
      name: promotionName,
      barcode: this.dataParams?.barcode ?? '',
      UUID: promotionUUID,
      pageID: promotionPageID,
    });
    this.promosService.resetPromoState();
    this.close();
  }

  private getAllSelectedProducts() {
    return this.firstPromoNode ? [...selectedPromotionButtons(this.firstPromoNode)] : [];
  }

  private closeWithConfirmation() {
    this.dynamicContentService
      .openContent(ConfirmDialogComponent, {
        title: this.translationsService.translate('2021050701'),
        leftButtonText: this.translationsService.translate('32'),
        rightButtonText: this.translationsService.translate('33'),
      })
      .afterClosed.subscribe((response) => {
        if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
          this.promosService.resetPromoState();
          this.close();
        }
      });
  }

  private deselectPromoButton(button: DotButton) {
    if (button.hasCombos) {
      button.ComboPage.Combos.forEach((combo) => combo.Buttons.forEach((btn) => this.deselectPromoButton(btn)));
    }
    if (button.hasModifiers) {
      button.ModifiersPage.Modifiers.forEach((modifier) => modifier.Buttons.forEach((btn) => this.deselectPromoButton(btn)));
    }
    button.quantity = 0;
    button.Selected = false;
  }
}
