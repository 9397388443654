import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { Component, Input } from '@angular/core';
import { BasketService } from '@dotxix/services/basket.service';

@Component({
  selector: 'acr-pay-order',
  templateUrl: './pay-order.component.html',
})
export class PayOrderComponent {
  @Input() public isDisabled = false;
  constructor(
    public basketService: BasketService,
    private checkoutFlowService: CheckoutFlowService
  ) {}

  public onClick() {
    this.checkoutFlowService.checkAvailablePaymentsAndSelectTableService().then(() => {});
  }
}
