import { Component } from '@angular/core';
import { PosElogHandler } from 'dotsdk';

@Component({
  selector: 'acr-preorder',
  templateUrl: './preorder.component.html',
})
export class PreorderComponent {
  public get isTableServiceActive(): boolean {
    return !!PosElogHandler.getInstance().posConfig.posHeader?.cvars?.TS_No;
  }
}
