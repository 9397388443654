import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { filter, map } from 'rxjs/operators';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { AppRoutes } from '@dotxix/app-routes';
import {
  CoreProducerTypes,
  DotSessionEndType,
  InactivityMessageProducer,
  InactivityNotificationType,
  InactivityProducerMessage,
  InactivityWatchProducerStates,
  MessageBroker,
  MessageController,
} from 'dotsdk';
import { InactivityExceptionRule } from '@dotxix/models/interfaces/inactivity-exception-rule';
import { POP_UP_ANIMATION_TIME } from '@dotxix/animation/animation';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { StatusService } from '@dotxix/services/status.service';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { appLogger } from '@dotxix/log-manager';
import { DynamicContentRef } from '@dotxix/services/dynamic-content/models/dynamic-content.ref';
import { ConfirmDialogDataParams } from '@dotxix/models/interfaces/confirm-dialog-data-params';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  /* Define a list of route segments on which the app will ignore inactivity */
  protected inactivityRouteExceptionRules: InactivityExceptionRule[] = [
    { route: AppRoutes.Banners, onlyIfCondition: () => true },
    { route: AppRoutes.ServiceTypeSelection, onlyIfCondition: () => this.appSettings.settings$.value.isServiceTypeStartScreen },
    { route: AppRoutes.PayTowerStartPage, onlyIfCondition: () => this.appSettings.settings$.value.isServiceTypeStartScreen },
    { route: AppRoutes.CheckoutError, onlyIfCondition: () => true },
    { route: AppRoutes.OrderNumber, onlyIfCondition: () => true },
    { route: AppRoutes.BridgeOperation, onlyIfCondition: () => true },
    { route: AppRoutes.CodView, onlyIfCondition: () => this.statusService.state$.value.availablePayments.length < 1 },
    { route: AppRoutes.Preorder, onlyIfCondition: () => true },
    { route: PaymentRoutes.PaymentSelection, onlyIfCondition: () => true },
    { route: PaymentRoutes.CardPayment, onlyIfCondition: () => true },
    { route: PaymentRoutes.GloryPayment, onlyIfCondition: () => true },
    { route: PaymentRoutes.ElectronicPayment, onlyIfCondition: () => true },
    { route: PaymentRoutes.PayTower, onlyIfCondition: () => true },
    { route: AppRoutes.ReceiptOptions, onlyIfCondition: () => true },
    { route: AppRoutes.SmsReceipt, onlyIfCondition: () => true },
    { route: AppRoutes.EmailReceipt, onlyIfCondition: () => true },
    { route: AppRoutes.ElectronicReceipt, onlyIfCondition: () => true },
  ];
  protected isInactivityWarningComponentVisible = false;
  private inactivityWarningModalRef: DynamicContentRef<ConfirmDialogDataParams, ConfirmDialogButtons> | undefined;

  constructor(
    private translationsService: TranslationsService,
    private dynamicContentService: DynamicContentService,
    private statusService: StatusService,
    private router: Router,
    private appSettings: ApplicationSettingsService,
    private checkoutService: CheckoutService
  ) {}

  public initialize() {
    this.handleInactivityProducerStateByRoute();
    this.handleInactivityMessages();
  }

  public resetInactivityProducerTimer() {
    (
      MessageController.getInstance().getRegisteredMessageProducer(CoreProducerTypes.INACTIVITY) as InactivityMessageProducer
    ).manuallyTriggerActivity();
  }

  protected handleInactivityProducerStateByRoute() {
    // filter the routes and set the state of the Inactivity Message Producer to:
    //   - InactivityWatchProducerStates.WATCH on routes that are not included in this.inactivityExceptionScreens
    //   - InactivityWatchProducerStates.IGNORE on routes that are included in this.inactivityExceptionScreens
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((navigationEndEvent: NavigationEnd) => navigationEndEvent.urlAfterRedirects.replace(/^\//, ''))
      )
      .subscribe((urlSegment: string) => {
        appLogger.debug(`url changed to ${JSON.stringify(urlSegment)}`);
        const activeExceptionIndex = this.inactivityRouteExceptionRules.findIndex(
          (inactivityRouteExceptionRule) =>
            urlSegment === inactivityRouteExceptionRule.route && inactivityRouteExceptionRule.onlyIfCondition()
        );
        if (activeExceptionIndex === -1) {
          appLogger.debug('start inactivity');
          MessageController.getInstance().changeMessageProducerState(CoreProducerTypes.INACTIVITY, InactivityWatchProducerStates.WATCH);
        } else {
          appLogger.debug('stop inactivity');
          MessageController.getInstance().changeMessageProducerState(CoreProducerTypes.INACTIVITY, InactivityWatchProducerStates.IGNORE);
        }
      });
  }

  protected handleInactivityMessages() {
    // listen to Inactivity Producer messages and, according to message type, show a warning popup or reset session
    MessageBroker.getInstance().subscribe(CoreProducerTypes.INACTIVITY, async (message: InactivityProducerMessage) => {
      switch (message.inactivityNotificationType) {
        case InactivityNotificationType.WARN:
          if (!this.isInactivityWarningComponentVisible) {
            this.showInactivityWarning();
          }
          break;
        case InactivityNotificationType.CLOSE:
          this.goHome();
          break;
        case InactivityNotificationType.RESET:
          this.closeInactivityWarning();
          break;
      }
    });
  }

  protected goHome() {
    this.isInactivityWarningComponentVisible = false;
    this.dynamicContentService.closeAllDialogs();
    setTimeout(async () => {
      await this.checkoutService.voidOrderAndRestartSession(DotSessionEndType.APP_TIMEOUT);
    }, POP_UP_ANIMATION_TIME);
  }

  protected showInactivityWarning() {
    this.isInactivityWarningComponentVisible = true;
    this.inactivityWarningModalRef = this.dynamicContentService.openContent(ConfirmDialogComponent, {
      title: this.translationsService.translate('2021020901'),
      rightButtonText: this.translationsService.translate('2021020902'),
      leftButtonText: this.translationsService.translate('5'),
      alwaysOnTop: true,
    });

    this.inactivityWarningModalRef.afterClosed.subscribe(async (response) => {
      this.isInactivityWarningComponentVisible = false;
      delete this.inactivityWarningModalRef;
      if (response === ConfirmDialogButtons.LEFT_BUTTON) {
        this.dynamicContentService.closeAllDialogs();
        await this.checkoutService.voidOrderAndRestartSession(DotSessionEndType.CANCEL_ORDER);
      } else if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
        // Will continue ordering. The Inactivity Message Producer will take care of the rest.
      }
    });
  }

  private closeInactivityWarning() {
    if (this.inactivityWarningModalRef) {
      this.inactivityWarningModalRef.close();
      delete this.inactivityWarningModalRef;
    }
  }
}
