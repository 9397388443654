import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { GloryCashFlowService } from '@dotxix/payment/services/glory-cash-flow.service';

@Component({
  selector: 'acr-glory-cash-payment-progress-error',
  templateUrl: './glory-cash-payment-progress-error.component.html',
  host: { class: 'flex flex--column flex--1' },
})
export class GloryCashPaymentProgressErrorComponent {
  public paymentServiceState$ = this.paymentService.state$;

  constructor(
    private paymentService: PaymentService,
    private gloryCashFlowService: GloryCashFlowService
  ) {}

  public onPrintReceiptClicked() {
    this.gloryCashFlowService.printCashPaymentErrorReceipt();
  }
}
