import { Component } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { SessionService } from '@dotxix/services/session.service';
import { TableServiceSelectionService } from '@dotxix/table-service/pages/table-service-selection/table-service-selection.service';

@Component({
  selector: 'acr-table-service-selection',
  templateUrl: './table-service-selection.component.html',
})
export class TableServiceSelectionComponent {
  public get disableConfirmButton(): boolean {
    return Number(this.selectionService.userInputTentNumber) < this.applicationSettingsService.settings$.value.tentNumberMinimumValue;
  }

  public get hideOnMandatoryTSS() {
    return !this.sessionService.hasMandatoryTSS();
  }

  constructor(
    protected checkoutFlowService: CheckoutFlowService,
    private selectionService: TableServiceSelectionService,
    private sessionService: SessionService,
    private applicationSettingsService: ApplicationSettingsService
  ) {}

  public updateTableServiceNumber(inputValue: string) {
    this.selectionService.userInputTentNumber = inputValue;
  }

  public async confirmTableServiceNumber() {
    this.convertToTentFormat();
    this.selectionService.setTableServiceToElog();
    this.checkoutFlowService.proceedToPayment().then();
  }

  public cancel() {
    this.checkoutFlowService.proceedToPayment().then();
  }

  private convertToTentFormat(): void {
    const zerosToAdd =
      this.applicationSettingsService.settings$.value.tentNumberFormatLength - this.selectionService.userInputTentNumber.length;
    if (zerosToAdd > 0 && Number(this.selectionService.userInputTentNumber) > 0) {
      this.selectionService.userInputTentNumber = '0'.repeat(zerosToAdd) + this.selectionService.userInputTentNumber;
    }
  }
}
