import { Injectable } from '@angular/core';
import { DotButton, DotPage, DotPageType, DotSessionEndType, getMainPage } from 'dotsdk';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { AppRoutes } from '@dotxix/app-routes';
import { MenuService } from '@dotxix/services/menu.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ButtonDetailsComponent } from '@dotxix/components/button-details/button-details.component';
import { BasketService } from '@dotxix/services/basket.service';
import { BehaviorSubject } from 'rxjs';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { SuggestionsSaleService } from '@dotxix/services/suggestion-sale.service';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { RecommendationActionView } from '@dotxix/services/suggestions/models/recommendation-action-view';
import { isButtonVisuallyUnavailable } from '@dotxix/helpers/button-status.helper';
import { hasMakeItAMeal, isItemPackButton, sortByMinPrice } from '@dotxix/helpers/button.helper';
import { MakeItAMealService } from '@dotxix/services/make-it-a-meal.service';
import { AllergensService } from '@dotxix/services/allergens.service';
import { DlgMessageService } from '@dotxix/services/dlg-message.service';
import { RecoParameterKey } from '@dotxix/services/suggestions/models/reco-parameter-key';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';
import { SuggestionMenuService } from '@dotxix/services/suggestion-menu.service';
import { EditSingleModifierStepComponent } from '@dotxix/components/edit-single-modifier-step/edit-single-modifier-step.component';
import { EditSingleComboStepComponent } from '@dotxix/components/edit-single-combo-step/edit-single-combo-step.component';
import { appLogger } from '@dotxix/log-manager';
import { ItemPackGroupComponent } from '@dotxix/components/item-pack-group/item-pack-group.component';

@Injectable({
  providedIn: 'root',
})
export class OrderFlowService {
  public showBlockingLoader$ = new BehaviorSubject<boolean>(false);

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private dynamicContentService: DynamicContentService,
    private checkoutFlowService: CheckoutFlowService,
    private router: Router,
    private suggestionsSaleService: SuggestionsSaleService,
    private translationsService: TranslationsService,
    private checkoutService: CheckoutService,
    private menuService: MenuService,
    private basketService: BasketService,
    private recommendationService: RecommendationService,
    private makeItAMealService: MakeItAMealService,
    private allergenService: AllergensService,
    private dlgMessageService: DlgMessageService,
    private productCustomizationService: ProductCustomizationService,
    private suggestionMenuService: SuggestionMenuService
  ) {
    this.checkoutFlowService.cancelOrder$.subscribe(() => this.onCancelOrder());
    this.checkoutFlowService.displayMainMenu$.subscribe(() => this.displayMainMenu());
  }

  public async displayMainMenu() {
    this.menuService.loadPage(getMainPage());
    return this.router.navigate([AppRoutes.Menu]);
  }

  public async onCancelOrder(): Promise<void> {
    return lastValueFrom(
      this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: this.translationsService.translate('38'),
        leftButtonText: this.translationsService.translate('32'),
        rightButtonText: this.translationsService.translate('33'),
      }).afterClosed
    ).then(async (response) => {
      if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
        appLogger.debug(`cancel order after confirmation`);
        this.dynamicContentService.closeAllDialogs();
        return this.checkoutService.voidOrderAndRestartSession(DotSessionEndType.CANCEL_ORDER);
      }
    });
  }

  public async onNavigationButtonClicked(pageId: string) {
    this.basketService.closeBasket();
    this.dynamicContentService.closeAllDialogs();
    return this.displayMenu(pageId);
  }

  public async onMenuButtonSelected(button: DotButton, view: RecommendationActionView, showMakeItAMeal: boolean) {
    if (button.Page && !isItemPackButton(button)) {
      if (button.Page?.PageType === DotPageType.PROMO) {
        this.menuService.usePromoCode(button.Link);
        return;
      }
      if (button.Page.PageTemplate?.toLocaleLowerCase() === 'dropdown') {
        this.menuService.toggleDropdown(button);
        return;
      }
      this.menuService.loadPage(button.Page);
      return;
    }

    this.menuService.onButtonSelected(button);
    const resultButton = await this.openButtonScreen(button, showMakeItAMeal);
    if (resultButton) {
      this.menuService.onMenuItemAddedToTheBasket(resultButton);

      const basketButton = this.basketService.addButtonToBasket(resultButton);
      qaAutomationLog('button added:', basketButton);

      await this.loadAndDisplayItemSuggestions(resultButton, basketButton, view);

      if (this.applicationSettingsService.settings$.value.viewBasketAfterProductSelection && this.basketService.numberOfItems$.value > 0) {
        this.basketService.openBasket();
      }
    }
  }

  public async customizeBasketProduct(product: DotButton) {
    appLogger.debug(`customize basket button link: ${product.Link}`);
    const { customized, resultButton } = await this.productCustomizationService.customizeProduct({
      product,
      updatingProduct: true,
    });
    if (customized && resultButton) {
      this.basketService.updateButton(resultButton);
    }
  }

  public async customizeBasketSingleProductComponent(button: DotButton, index: number, isCombo = false) {
    appLogger.debug(`customize basket button link: ${button.Link}, index: ${index}, isCombo: ${isCombo ? 'true' : 'false'}`);
    const resultButton = await lastValueFrom(
      this.dynamicContentService.openContent(isCombo ? EditSingleComboStepComponent : EditSingleModifierStepComponent, {
        button,
        isUpdate: true,
        buttonIndex: index,
      }).afterClosed
    );
    if (resultButton) {
      this.basketService.updateButton(resultButton);
    }
  }

  public async onBasketSuggestionSelected(button: DotButton) {
    appLogger.debug(`basket suggestion selected link: ${button.Link}`);
    const resultButton = await this.openButtonScreen(
      button,
      this.recommendationService.sessionParameters[RecoParameterKey.RecommendationEnableUpgrade]
    );
    if (resultButton) {
      const basketButton = this.basketService.addButtonToBasket(resultButton);
      qaAutomationLog('button added:', basketButton);
      this.recommendationService.userAction(RecommendationActionView.BasketSuggestion, resultButton, resultButton.quantity).then();
    }
  }

  public async onPopularSuggestionSelected(button: DotButton) {
    appLogger.debug(`popular suggestion selected link: ${button.Link}`);
    const resultButton = await this.openButtonScreen(
      button,
      this.recommendationService.sessionParameters[RecoParameterKey.RecommendationEnableUpgrade]
    );
    if (resultButton) {
      const basketButton = this.basketService.addButtonToBasket(resultButton);
      qaAutomationLog('button added:', basketButton);
      this.recommendationService.userAction(RecommendationActionView.Home, resultButton, resultButton.quantity).then();
    }
  }

  public async onCheckoutButtonClicked() {
    appLogger.debug(`checkout button clicked`);
    this.dynamicContentService.closeAllDialogs();
    let suggestions;
    if (this.applicationSettingsService.settings$.value.enableRecoModule) {
      suggestions = await this.recommendationService.getOrderSuggestions(12, 6);
    } else {
      suggestions = this.suggestionsSaleService.getOrderSuggestions();
    }

    await this.suggestionMenuService.displayOrderSuggestionsAndWaitForSelection(suggestions);
    await this.checkoutFlowService.startCheckoutTunnel();
  }

  private async displayMenu(pageId: string) {
    this.menuService.loadPageById(pageId);
    return this.router.navigate([AppRoutes.Menu]);
  }

  private async openButtonScreen(button: DotButton, showMakeItAMeal: boolean): Promise<DotButton | null> {
    if (isButtonVisuallyUnavailable(button)) {
      return null;
    }

    let selectedButton: DotButton | null | undefined = button;

    if (isItemPackButton(button)) {
      selectedButton = await this.selectItemPackGroupButton(button);
      if (!selectedButton) {
        return null;
      }
    }
    if (showMakeItAMeal && !selectedButton.hasCombos && hasMakeItAMeal(selectedButton)) {
      selectedButton = await this.makeItAMealService.selectMakeItAMealButton(selectedButton);

      if (selectedButton === null) {
        return null;
      }
    }

    if (false === (await this.allergenService.confirmAllergenSelection(selectedButton))) {
      return null;
    }

    if (false === (await this.dlgMessageService.confirm(selectedButton))) {
      return null;
    }

    const { customized, resultButton } = await this.productCustomizationService.customizeProduct({
      product: selectedButton,
      updatingProduct: false,
    });

    if (customized) {
      if (resultButton) {
        return resultButton;
      } else {
        return null;
      }
    } else {
      return (
        (await this.productCustomizationService.customizeWithComponent(ButtonDetailsComponent, {
          button: selectedButton,
          isUpdate: false,
        })) ?? null
      );
    }
  }

  private selectItemPackGroupButton(button: DotButton) {
    return new Promise<DotButton | null | undefined>((resolve) => {
      this.dynamicContentService
        .openContent(ItemPackGroupComponent, { buttons: sortByMinPrice(button.Page.Buttons) })
        .afterClosed.subscribe((response) => {
          resolve(response);
        });
    });
  }

  private async executeWithBlockingLoader<T>(functionReturningPromise: () => Promise<T>): Promise<T | null> {
    this.showUiBlockingLoader();
    const result = await functionReturningPromise().catch(() => null);
    this.hideUiBlockingLoader();
    return result;
  }

  private async loadAndDisplayItemSuggestions(button: DotButton, basketButton: DotButton, view: RecommendationActionView) {
    let suggestions;
    if (this.applicationSettingsService.settings$.value.enableRecoModule) {
      suggestions = await this.executeWithBlockingLoader<DotPage[]>(() => {
        return this.recommendationService.getItemSuggestionsAfterUserAction(view, 12, 6, button);
      });
    } else {
      suggestions = this.suggestionsSaleService.getItemSuggestions(button);
    }

    await this.suggestionMenuService.displayButtonSuggestionsAndWaitForSelection(basketButton, suggestions ?? []);
  }

  private showUiBlockingLoader() {
    this.showBlockingLoader$.next(true);
  }

  private hideUiBlockingLoader() {
    this.showBlockingLoader$.next(false);
  }
}
