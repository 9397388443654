import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-glory-cash-overpay-refund-success',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001033'"
      [amounts]="[{ label: '2001032', value: (paymentServiceState$ | async)!.cash.lastTransactionRefundedAmount }]"
    ></acr-glory-cash-message>

    <!-- Amount refunded: -->

    <!--  Your refund was processed. |Please take your cash and proceed with your order. --> `,
})
export class GloryCashOverpayRefundSuccessComponent {
  public paymentServiceState$ = this.paymentService.state$;
  constructor(private paymentService: PaymentService) {}
}
