import { Component } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-glory-cash-cancel-transaction-refund-progress',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001034'"
      [amounts]="[{ label: '2001027', value: (paymentServiceState$ | async)!.cash.currentTransactionPaidAmount }]"
    ></acr-glory-cash-message>

    <!-- Cash Paid: -->

    <!-- Your refund is in the works. |Please wait for your transaction to be processed. -->`,
})
export class GloryCashCancelTransactionRefundProgressComponent {
  public paymentServiceState$ = this.paymentService.state$;
  constructor(private paymentService: PaymentService) {}
}
