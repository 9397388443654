import { Injectable, NgZone } from '@angular/core';
import { AtpPaymentService, IPayProgress, PosPaidState, PosRefintService, PosTenderType } from 'dotsdk';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { getTenderMediaId, insertPaymentOperation, insertTender } from '@dotxix/payment/helpers/payment-elog.helper';
import { getPaymentName } from '@dotxix/payment/helpers/payment-type.helper';
import { electronicPaymentLogger } from '@dotxix/log-manager';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { PayProgressInterface } from '@dotxix/payment/models/pay-progress-message.interface';
import { EPayQrCodeScreenSteps } from '@dotxix/payment/models/qr-code-screen-steps.enum';

@Injectable({
  providedIn: 'root',
})
export class ElectronicPaymentService {
  public ePayMessage$ = new BehaviorSubject<PayProgressInterface>({
    IsCancelAvailable: false,
    CancelAvailabilityTimeout: null,
    QRData: '',
  });
  public ePayScreenStep$ = new BehaviorSubject<EPayQrCodeScreenSteps>(EPayQrCodeScreenSteps.ConnectingToPayment);
  public canceling$ = new BehaviorSubject<boolean>(false);
  public autoCancelCounter$ = new BehaviorSubject<null | number>(null);
  private ePaymentProgressSubscription: Subscription | undefined;
  private autoCancelCountdownSubscription: Subscription | undefined;
  private paymentType: PaymentType | undefined;

  constructor(private ngZone: NgZone) {}

  public async ePay(amountOwned: number, paymentType: PaymentType) {
    electronicPaymentLogger.debug(`ElectronicPayment initiated ${amountOwned} ${JSON.stringify(paymentType)}`);
    this.paymentType = paymentType;
    this.canceling$.next(false);
    const ePayResult = await AtpPaymentService.getInstance()
      .electronicPay(
        amountOwned,
        `${PosRefintService.getInstance()._refInt}`,
        getPaymentName(paymentType),
        this.computePaymentParams(paymentType)
      )
      .catch(() => null);

    this.removePaymentProgressSubscription();
    this.stopAutoCancelCountdown();

    if (ePayResult?.PaidAmount) {
      electronicPaymentLogger.debug(`ElectronicPayment successfully executed`);
      insertPaymentOperation(true, getPaymentName(paymentType));
      insertTender(PosTenderType.CARD, PosPaidState.PAID, ePayResult.PaidAmount, getTenderMediaId(ePayResult, paymentType));
    } else {
      electronicPaymentLogger.error(`ElectronicPayment failed`);
      insertPaymentOperation(false, getPaymentName(paymentType));
    }

    return ePayResult;
  }

  public startListeningPaymentProgress() {
    electronicPaymentLogger.debug(`ElectronicPayment Pay progress subscription starting...`);

    this.ePaymentProgressSubscription = AtpPaymentService.getInstance()
      .payProgress()
      .subscribe((paymentInfo: IPayProgress) => {
        this.ngZone.run(() => {
          electronicPaymentLogger.debug(`ElectronicPayment Pay progress response: ${JSON.stringify(paymentInfo)}`);
          if (paymentInfo.MessageClass === 'QRCode' || paymentInfo.MessageClass === 'Error') {
            this.removePaymentProgressSubscription();
          }
          if (paymentInfo.MessageClass === 'QRCode') {
            let paymentMessage: PayProgressInterface;
            try {
              paymentMessage = JSON.parse(paymentInfo.Message);
              this.ePayMessage$.next(paymentMessage);
              if (typeof this.ePayMessage$.value.CancelAvailabilityTimeout === 'number') {
                this.startAutoCancelCountdown(this.ePayMessage$.value.CancelAvailabilityTimeout);
              }
            } catch (error) {
              this.ePayMessage$.next({ ...this.ePayMessage$.value, QRData: paymentInfo.Message });
            }
            this.ePayScreenStep$.next(EPayQrCodeScreenSteps.ShowingQrCode);
          } else if (paymentInfo.MessageClass === 'Error') {
            electronicPaymentLogger.error(`ElectronicPayment Pay progress Error`);
          }
        });
      });
  }

  public async cancelEPay() {
    electronicPaymentLogger.debug(`ElectronicPayment Cancel payment initiated ${JSON.stringify(this.paymentType)}`);
    this.removePaymentProgressSubscription();
    this.stopAutoCancelCountdown();
    this.canceling$.next(true);
    const response = await AtpPaymentService.getInstance()
      .cancelPay(
        `${PosRefintService.getInstance()._refInt}`,
        this.paymentType ? getPaymentName(this.paymentType) : '',
        this.paymentType ? this.computePaymentParams(this.paymentType) : ''
      )
      .catch(() => false);
    if (response === true) {
      electronicPaymentLogger.debug(`ElectronicPayment Successfully canceled payment ${JSON.stringify(this.paymentType)}: ${response}`);
    } else {
      electronicPaymentLogger.error(
        `ElectronicPayment Error on cancel electronic payment ${JSON.stringify(this.paymentType)}: ATP returned an error response.`
      );
    }
  }

  private removePaymentProgressSubscription() {
    if (this.ePaymentProgressSubscription) {
      this.ePaymentProgressSubscription.unsubscribe();
      delete this.ePaymentProgressSubscription;
    }
    electronicPaymentLogger.debug(`ElectronicPayment Pay progress subscription stopped`);
  }

  private startAutoCancelCountdown(timeoutInSeconds: number) {
    electronicPaymentLogger.debug(`ElectronicPayment Start auto cancel countdown ${timeoutInSeconds}`);
    this.stopAutoCancelCountdown();
    this.autoCancelCounter$.next(timeoutInSeconds);
    this.autoCancelCountdownSubscription = timer(1000, 1000).subscribe((tick) => {
      const newCountDownValue = timeoutInSeconds - 1 - tick;
      this.autoCancelCounter$.next(newCountDownValue);
      if (newCountDownValue <= 0) {
        electronicPaymentLogger.debug(`ElectronicPayment Auto cancel countdown reached, canceling payment`);
        this.cancelEPay();
      }
    });
  }

  private stopAutoCancelCountdown() {
    if (this.autoCancelCountdownSubscription) {
      this.autoCancelCountdownSubscription.unsubscribe();
      delete this.autoCancelCountdownSubscription;
    }
    this.autoCancelCounter$.next(null);
  }

  private computePaymentParams(paymentType: PaymentType) {
    return paymentType.PaymentApplication
      ? JSON.stringify({
          OrderDetails: {
            PaymentApplication: paymentType.PaymentApplication,
          },
        })
      : '';
  }
}
