import { AutoPopFeatMode } from '@dotxix/models/enums/auto-pop-feat';
import { calculateButtonPrice, DotButton, DotModifier, DotPrefixId, PosElogHandler } from 'dotsdk';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import * as _ from 'lodash';
import { computeAutoPopFeatDefaults, isItemPackButton, isNotItemPackButton } from '@dotxix/helpers/button.helper';
import { computeItemPackSelectedQuantity } from '@dotxix/helpers/item-pack.helper';
import { hasPrefixes } from '@dotxix/helpers/prefixes.helper';
import { filterCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { ModifierButtonState } from '@dotxix/models/interfaces/modifier-button-state';
import { modifierButtonAddOne } from '@dotxix/helpers/modifier-group.helper';
import { initializeChargeThreshold } from './charge-threshold.helper';
import { ModifierState } from '@dotxix/models/interfaces/modifier-state';

export const modifierButtonDisplayPrice = (modifierButton: DotButton, chargeThresholdGroup: number): number => {
  if (hasPrefixes(modifierButton)) {
    if (modifierButton.selectedPrefixId === DotPrefixId.EXTRA) {
      return modifierButtonPrice(modifierButton, chargeThresholdGroup);
    } else {
      return 0;
    }
  } else {
    if (modifierButton.DefaultQuantity && modifierButton.DefaultQuantity > 0) {
      if (modifierButton.DefaultQuantity < modifierButton.quantity) {
        return (modifierButton.quantity - modifierButton.DefaultQuantity) * modifierButtonPrice(modifierButton, chargeThresholdGroup);
      } else {
        return 0;
      }
    } else {
      return modifierButtonPrice(modifierButton, chargeThresholdGroup);
    }
  }
};

export const modifierButtonIsSelected = (modifierButton: DotButton): boolean => {
  if (hasPrefixes(modifierButton)) {
    return modifierButton.selectedPrefixId !== DotPrefixId.NO;
  }
  return modifierButton.Selected && modifierButton.quantity > 0;
};

export const modifierButtonPrice = (modifierButton: DotButton, chargeThresholdGroup: number): number => {
  if (isBelowChargeThreshold(modifierButton, chargeThresholdGroup)) {
    return 0;
  }
  return calculateButtonPrice(modifierButton, PosElogHandler.getInstance().posConfig.posHeader.servingLocation);
};

export const isAboveChargeThreshold = (button: DotButton, chargeThresholdGroup: number) =>
  !button.ChargeThreshold ||
  button.ChargeThreshold <= button.quantity ||
  (hasGroupChargeThreshold(chargeThresholdGroup) && button.quantity > chargeThresholdGroup);
export const isBelowChargeThreshold = (button: DotButton, chargeThresholdGroup: number) =>
  !isAboveChargeThreshold(button, chargeThresholdGroup);
export const hasGroupChargeThreshold = (chargeThresholdGroup: number) => Number.isInteger(chargeThresholdGroup) && chargeThresholdGroup > 0;

export const modifierButtonHasDefaultQuantity = (button: DotButton): boolean => !!button.DefaultQuantity && button.DefaultQuantity > 0;

export const initializeButtonWithModifiers = (button: DotButton<ButtonState>, initializeQuantity: boolean) => {
  button = _.cloneDeep(button);
  if (!button.state.$$initialized) {
    button.state.$$initialized = true;

    if (initializeQuantity) {
      button.quantity = Math.max(button.quantity, button.MinQuantity || 1);
      button.Selected = true;
    }

    if (button.hasModifiers) {
      filterCatalogAvailableModifiers(button);
      forceShowModifierGroupsThatAreNotReachingMinQuantity(button);
      initializeModifiersQuantities(button);
      computeAutoPopFeatDefaults(button);
    }
  }
  return button;
};

export const hideModifiersWithQuantityEqualToDefaultQuantityInElog = (button: DotButton) => {
  if (button.hasModifiers) {
    mutateDeselectModifiersWithQuantityEqualToDefaultQuantity(button);
  }
  if (button.hasCombos) {
    button.ComboPage.Combos.forEach((dotCombo) => {
      dotCombo.Buttons.forEach((button) => {
        if (button.hasModifiers) mutateDeselectModifiersWithQuantityEqualToDefaultQuantity(button);
      });
    });
  }
};

export const mutateDeselectModifiersWithQuantityEqualToDefaultQuantity = (button: DotButton) => {
  button.ModifiersPage.Modifiers.forEach((modifier) => {
    modifier.Buttons.forEach((modifierButton) => {
      modifierButton.includeFeatureInElog = !(modifierButton.DefaultQuantity && modifierButton.DefaultQuantity === modifierButton.quantity);
    });
  });
};

export const initializeModifiersQuantities = (button: DotButton) => {
  if (!button.hasModifiers) return;
  button.ModifiersPage.Modifiers.forEach((modifier) => {
    modifier.PageInfo.MinQuantity = modifier.PageInfo.MinQuantity || 0;
    const initialModifierButtonsListQuantity = computeModifierButtonsListQuantity(modifier.Buttons);
    modifier.Buttons.forEach((modifierButton) => {
      if (isNotItemPackButton(modifierButton)) {
        initializeModifierButton(
          modifierButton as DotButton<ModifierButtonState>,
          modifierButtonMaxAllowedQuantityInModifier(modifierButton, modifier)
        );
      } else {
        modifierButton.Page.Buttons.forEach((itemPackModifierButton) =>
          initializeModifierButton(
            itemPackModifierButton as DotButton<ModifierButtonState>,
            modifierButtonMaxAllowedQuantityInModifier(modifierButton, modifier)
          )
        );
      }
    });
    initializeChargeThreshold(modifier, initialModifierButtonsListQuantity, computeModifierButtonsListQuantity(modifier.Buttons));
  });
};

export const initializeModifierButton = (modifierButton: DotButton<ModifierButtonState>, maxAllowedQuantityInModifier: number) => {
  if (modifierButton.state.$$initialized) {
    return;
  }

  modifierButton.state.$$initialized = true;
  modifierButton.quantity = modifierButton.quantity || 0;
  modifierButton.MinQuantity = modifierButton.MinQuantity || 0;
  modifierButton.quantity = Math.max(modifierButton.MinQuantity, modifierButton.quantity);
  modifierButton.DefaultQuantity = modifierButton.DefaultQuantity || 0;
  modifierButton.quantity = Math.min(Math.max(modifierButton.DefaultQuantity, modifierButton.MinQuantity), maxAllowedQuantityInModifier);
  initializeIncludedQuantityButton(modifierButton, maxAllowedQuantityInModifier);
  modifierButton.Selected = modifierButton.quantity > 0;
};

export const initializeIncludedQuantityButton = (modifierButton: DotButton, maxAllowedQuantityInModifier: number) => {
  if (modifierButton.DefaultQuantity <= 0) {
    modifierButton.IncludedQuantity = modifierButton.IncludedQuantity || 0;
    modifierButton.quantity = Math.min(modifierButton.IncludedQuantity, maxAllowedQuantityInModifier);
  }
};

export const updateRemoveFlagForModifiersWithDefaultQuantity = (button: DotButton) => {
  if (button.hasModifiers) {
    updateRemoveFlagForModifiersWithDefaultQuantityForButtonWithModifiers(button);
  }
  if (button.hasCombos) {
    updateRemoveFlagForModifiersWithDefaultQuantityForCombo(button);
  }
};

export const updateRemoveFlagForModifiersWithDefaultQuantityForCombo = (button: DotButton) =>
  button.ComboPage.Combos.forEach((comboStep) =>
    comboStep.Buttons.forEach((comboStepButton) => {
      if (comboStepButton.hasModifiers) {
        updateRemoveFlagForModifiersWithDefaultQuantityForButtonWithModifiers(comboStepButton);
      }
      if (isItemPackButton(comboStepButton)) {
        updateRemoveFlagForModifiersWithDefaultQuantityForItemPackButtons(comboStepButton);
      }
    })
  );

export const updateRemoveFlagForModifiersWithDefaultQuantityForButtonWithModifiers = (button: DotButton) =>
  button.ModifiersPage.Modifiers.forEach((modifier) =>
    modifier.Buttons.forEach((modifierButton) => {
      if (isItemPackButton(modifierButton)) {
        updateRemoveFlagForModifiersWithDefaultQuantityForItemPackButtons(modifierButton);
      } else {
        modifierButton.removeItem = !!(
          modifierButton.DefaultQuantity &&
          modifierButton.DefaultQuantity > 0 &&
          modifierButton.quantity <= 0
        );
      }
    })
  );

export const updateRemoveFlagForModifiersWithDefaultQuantityForItemPackButtons = (button: DotButton) =>
  button.Page.Buttons.forEach((itemPackButton) => updateRemoveFlagForModifiersWithDefaultQuantity(itemPackButton));

export const isModifierSelectionValidForBasket = (button: DotButton): boolean =>
  !hasIceModifierUnselected(button) &&
  (!button?.hasModifiers ||
    button?.ModifiersPage?.Modifiers?.every(
      (modifier) =>
        hasAutoCompleteButtonInModifier(modifier) ||
        !modifier.PageInfo.MinQuantity ||
        (modifier.PageInfo.MinQuantity && computeModifierSelectedQuantity(modifier) >= modifier.PageInfo.MinQuantity)
    ));

export const hasIceModifierUnselected = (button: DotButton): boolean => {
  return button?.ModifiersPage?.Modifiers?.some(
    (modifier: DotModifier<ModifierState>) =>
      modifier.modifierTemplate.toLowerCase() === 'ice' &&
      modifier.PageInfo.AutoPopFeat !== AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY &&
      modifier.Buttons.every((b) => b.Tags?.length > 0) &&
      !modifier.state.iceModifierSelected
  );
};

export const hasAutoCompleteButtonInModifier = (modifier: DotModifier) =>
  modifier.Buttons.some((modifierButton) => modifierButton.AutoComplete === 1);

export const hasNoAutoCompleteButtonInModifier = (modifier: DotModifier) =>
  modifier.Buttons.every((modifierButton) => modifierButton.AutoComplete !== 1);

export const findAutoCompleteButtonFromModifier = (modifier: DotModifier): DotButton | undefined =>
  modifier.Buttons.find((modifierButton) => modifierButton.AutoComplete === 1);

export const computeModifierSelectedQuantity = (modifier: DotModifier) => computeModifierButtonsListQuantity(modifier.Buttons);

export const computeModifierButtonsListQuantity = (buttons: DotButton[]) =>
  buttons.reduce((quantity, button) => quantity + (hasPrefixes(button) ? 0 : button.quantity + computeItemPackSelectedQuantity(button)), 0);

export const modifierButtonMaxAllowedQuantityInModifier = (modifierButton: DotButton, modifier: DotModifier): number => {
  let maxQuantityGroup = modifier.PageInfo.MaxQuantity;
  if (maxQuantityGroup) {
    const selectedModifierButtonsQuantity = computeModifierSelectedQuantity(modifier);
    maxQuantityGroup = Math.max(0, maxQuantityGroup - selectedModifierButtonsQuantity);
    return Math.min(modifierButtonMaxQuantity(modifierButton), maxQuantityGroup);
  } else {
    return modifierButtonMaxQuantity(modifierButton);
  }
};

export const modifierButtonMaxQuantity = (modifierButton: DotButton) => {
  if (modifierButton.MaxQuantity) {
    return modifierButton.MaxQuantity;
  } else {
    return Number.MAX_SAFE_INTEGER;
  }
};

export const modifierButtonMinQuantity = (modifierButton: DotButton) => (modifierButton.MinQuantity ? modifierButton.MinQuantity : 0);

/**
 * If modifier group MinQty not reached and there is no AutoComplete on any modifier
 * force AutoPopFeat = 1 so the modifiers are visible and the appropriate quantities can be selected
 *
 * @param button DotButton
 */
export const forceShowModifierGroupsThatAreNotReachingMinQuantity = (button: DotButton) => {
  for (const modifier of button.ModifiersPage.Modifiers) {
    if (modifier.PageInfo.MinQuantity) {
      const selectedQuantity = modifier.Buttons.reduce((quantity: number, button: DotButton) => quantity + button.quantity, 0);
      if (modifier.PageInfo.MinQuantity > selectedQuantity) {
        if (hasNoAutoCompleteButtonInModifier(modifier)) {
          modifier.PageInfo.AutoPopFeat = AutoPopFeatMode.POP_ON_ORDER_POP_ON_MODIFY;
        }
      }
    }
  }
};

export const addAutoCompleteModifiers = (button: DotButton) => {
  if (!button || !button.hasModifiers) {
    return;
  }
  const modifiers = button.ModifiersPage.Modifiers;
  for (const modifier of modifiers) {
    const modifiersSelectedQuantity = modifier.selectedButtons.reduce((acc, mod) => {
      acc += mod.quantity;
      return acc;
    }, 0);
    const autoCompleteModifierButton = findAutoCompleteButtonFromModifier(modifier);
    if (
      autoCompleteModifierButton &&
      modifier.PageInfo.MinQuantity > modifiersSelectedQuantity &&
      (!autoCompleteModifierButton.ComplementId ||
        (!hasComplementModifierSelected(modifiers, autoCompleteModifierButton.ComplementId) && modifier.PageInfo.MinQuantity))
    ) {
      const modifierButton = modifier.Buttons.find((buttonModifier) => buttonModifier.Link === autoCompleteModifierButton.Link);
      if (modifierButton) {
        modifierButton.Selected = true;
        modifierButton.quantity += modifier.PageInfo.MinQuantity - modifiersSelectedQuantity;
      }
    }
  }
};

export const hasComplementModifierSelected = (modifiers: DotModifier[], complementId: number): boolean => {
  for (const modifier of modifiers) {
    if (modifier.Buttons.some((buttonModifier) => Number(buttonModifier.Link) === complementId && buttonModifier.Selected)) {
      return true;
    }
  }
  return false;
};

export const filterCatalogAvailableModifiers = (button: DotButton) => {
  const modifiers = button.ModifiersPage.Modifiers;
  modifiers.forEach((modifier) => {
    modifier.Buttons = filterCatalogAvailable(modifier.Buttons);
    modifier.Buttons.forEach((modifierButton) => {
      if (isItemPackButton(modifierButton)) {
        modifierButton.Page.Buttons = filterCatalogAvailable(modifierButton.Page.Buttons);
      }
    });
    modifier.Buttons = modifier.Buttons.filter((modifierButton) => {
      if (isItemPackButton(modifierButton)) {
        return modifierButton.Page.Buttons.length > 0;
      }
      return true;
    });
  });
  button.ModifiersPage.Modifiers = modifiers.filter((modifier) => modifier.Buttons.length > 0);
};

export const modifierButtonAddDefaultQuantity = (modifierButton: DotButton, maxAllowedIncreaseQuantity: number): void => {
  const addQuantity = Math.max(1, modifierButton.DefaultQuantity);
  modifierButton.quantity = Math.min(maxAllowedIncreaseQuantity, addQuantity);
  modifierButton.Selected = modifierButton.quantity > 0;
};

export const modifierButtonIncreaseQuantity = (modifierButton: DotButton, maxQuantityGroup: number, selectedGroupQuantity: number) => {
  if (modifierButton.quantity === 0) {
    modifierButtonAddDefaultQuantity(modifierButton, maxQuantityGroup - selectedGroupQuantity);
  } else {
    modifierButtonAddOne(modifierButton, maxQuantityGroup);
  }
};

export const modifierButtonSetQuantity = (modifierButton: DotButton, quantity: number) => {
  modifierButton.quantity = quantity;
  modifierButton.Selected = modifierButton.quantity > 0;
};

export const initializeProductModifiersQuantities = (product: DotButton) => {
  product.quantity = product.quantity || 0;
  product.IncludedQuantity = product.IncludedQuantity || 0;
  product.MinQuantity = product.MinQuantity || 0;
  if (product.hasModifiers) {
    filterCatalogAvailableModifiers(product);
    forceShowModifierGroupsThatAreNotReachingMinQuantity(product);
    initializeModifiersQuantities(product);
  }
};
