import { Router } from '@angular/router';
import { Component } from '@angular/core';
@Component({
  selector: 'acr-checkout-error',
  templateUrl: 'checkout-error.component.html',
})
export class CheckoutErrorComponent {
  public displayPaymentImage = false;

  constructor(private router: Router) {
    this.displayPaymentImage = this.router.getCurrentNavigation()?.extras.state?.displayPaymentImage;
  }
}
