import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { DotButton, DotButtonDisplayMode, DotNutritionalValue } from 'dotsdk';
import { SessionService } from '@dotxix/services/session.service';
import { price } from '@dotxix/helpers/price.helper';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { extractCaloriesByButtonType } from '@dotxix/helpers/callories.helper';
import { ButtonInfoService } from '@dotxix/button-info.service';

@Component({
  selector: 'acr-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Input() public button!: DotButton;
  @Input() public extraClasses = '';
  @Input() public displayBackground = false;
  @Input() public unavailableButton = false;
  @Output() public selectProduct = new EventEmitter<void>();

  public DotButtonDisplayMode = DotButtonDisplayMode;
  public price!: number;
  public calories: DotNutritionalValue | undefined;
  public showInfoButton!: boolean;

  constructor(
    protected sessionService: SessionService,
    private dynamicContentService: DynamicContentService,
    private buttonInfoService: ButtonInfoService
  ) {}

  public ngOnInit(): void {
    this.price = price(this.button, this.sessionService.serviceType);
    this.calories = extractCaloriesByButtonType(this.button);
    this.showInfoButton = this.buttonInfoService.showProductInfo(this.button);
  }

  public onProductInfoClick(event: MouseEvent): void {
    event.stopPropagation();
    this.buttonInfoService.openProductInfo(this.button);
  }

  @HostListener('click')
  public onClick() {
    if (!this.unavailableButton) {
      this.selectProduct.next();
    }
  }
}
