import { DotButton, DotModifier } from 'dotsdk';
import { modifierButtonAddDefaultQuantity } from '@dotxix/helpers/modifiers.helper';

export const selectModifierButtonInGroup = (modifierButton: DotButton, modifier: DotModifier, selectedGroupQuantity: number): void => {
  const maxQuantityGroup = modifier.PageInfo.MaxQuantity || Number.MAX_SAFE_INTEGER;

  if (modifierButton.quantity === 1 && (modifierButton.MaxQuantity === 1 || maxQuantityGroup === 1)) {
    modifierButtonRemoveOne(modifierButton);
    return;
  }

  if (selectedGroupQuantity < maxQuantityGroup) {
    if (modifierButton.quantity === 0) {
      modifierButtonAddDefaultQuantity(modifierButton, maxQuantityGroup - selectedGroupQuantity);
    } else {
      modifierButtonAddOne(modifierButton, maxQuantityGroup);
    }
  }
};

export const selectModifierButtonAndDeselectOtherSelectionInAGroupWithMaxQuantity1 = (
  modifierButton: DotButton,
  groupModifierButtons: DotButton[]
) => {
  if (modifierButton.quantity > 0) {
    modifierButtonRemoveOne(modifierButton);
  } else {
    const selectedModifierButton = groupModifierButtons.find((groupModifierButton) => groupModifierButton.quantity > 0);
    if (selectedModifierButton) {
      modifierButtonRemoveOne(selectedModifierButton);
      if (selectedModifierButton.quantity === 0) {
        modifierButtonAddOne(modifierButton, 1);
      }
    } else {
      modifierButtonAddOne(modifierButton, 1);
    }
  }
};

export const modifierButtonRemoveOne = (modifierButton: DotButton) => {
  if (modifierButton.quantity > modifierButton.MinQuantity) {
    modifierButton.quantity--;
    modifierButton.Selected = modifierButton.quantity > 0;
  }
};

export const modifierButtonAddOne = (modifierButton: DotButton, maxQuantityGroup: number) => {
  if (modifierButton.quantity < modifierButton.MaxQuantity && modifierButton.quantity < maxQuantityGroup) {
    modifierButton.Selected = true;
    modifierButton.quantity++;
  }
};

export const modifierButtonAddOneAndRemoveFromAnotherModifierButtonWhenMaxReached = (
  modifierButton: DotButton,
  anotherModifierButton: DotButton,
  selectedGroupQuantity: number,
  maxQuantityGroup: number
) => {
  if (selectedGroupQuantity >= maxQuantityGroup) {
    // maximum reached

    if (modifierButton.quantity >= modifierButton.MaxQuantity || modifierButton.quantity >= maxQuantityGroup) {
      // can't increase modifier button quantity, no need to decrease another modifier button quantity
      return;
    }
    if (anotherModifierButton.quantity <= anotherModifierButton.MinQuantity) {
      // can't decrease another modifier button quantity, can't increase modifier button quantity
      return;
    }

    modifierButtonRemoveOne(anotherModifierButton);
    modifierButtonAddOne(modifierButton, maxQuantityGroup);
  } else {
    // quantity less than group maximum
    modifierButtonAddOne(modifierButton, maxQuantityGroup);
  }
};
