import { Component } from '@angular/core';
import { DotButton, DotButtonType } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { SuggestionSalesDataParams } from '@dotxix/models/interfaces/suggestion-sales-data-params';
import { SuggestionMenuService } from '@dotxix/services/suggestion-menu.service';

@Component({
  selector: 'acr-suggestion-sales',
  templateUrl: './suggestion-sales.component.html',
})
export class SuggestionSalesComponent extends AbstractDynamicAnimatedPopup<SuggestionSalesDataParams> {
  public ButtonType = DotButtonType;

  public suggestionMenuState$ = this.suggestionMenuService.state$;

  constructor(private suggestionMenuService: SuggestionMenuService) {
    super();
  }

  public onButtonClick(index: number, button: DotButton): void {
    this.suggestionMenuService.buttonClick(index, button);
  }

  public cancelClick() {
    this.nextPage();
  }

  public addToBasketClick(): void {
    if (this.suggestionMenuState$.value.currentRootPage) {
      this.suggestionMenuService.addSelectedButtons(this.suggestionMenuState$.value.currentRootPage, this.dataParams!.recommendationAction);
    }
    this.nextPage();
  }

  public onQuantityChanged(index: number, button: DotButton, changedQuantity: 1 | -1) {
    this.suggestionMenuService.quantityChanged(index, button, changedQuantity);
  }

  public onBack(): void {
    this.suggestionMenuService.back();
  }

  private nextPage() {
    if (this.suggestionMenuState$.value.isLastPage) {
      this.close();
    } else {
      this.suggestionMenuService.next();
    }
  }
}
