import { Component } from '@angular/core';
import { PayTowerPaymentFlowService } from '@dotxix/payment/services/pay-tower-payment-flow.service';

@Component({
  selector: 'acr-pay-tower-payment',
  templateUrl: './pay-tower-payment.component.html',
})
export class PayTowerPaymentComponent {
  public $loading = this.payTowerPaymentFlowService.savingOrder$;
  public $orderSaved = this.payTowerPaymentFlowService.orderSaved$;
  constructor(private payTowerPaymentFlowService: PayTowerPaymentFlowService) {}
}
