import { Injectable } from '@angular/core';
import { DotButton, DotCombo, DotI18n, DotI18nLoader, DotModifier, DotNutritionalValue, DotSessionService } from 'dotsdk';
import { BehaviorSubject, Subject } from 'rxjs';
import { Language } from '@dotxix/models/interfaces/language';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { ContentService } from '@dotxix/services/content.service';
import { AppSettings } from '@dotxix/models/interfaces/app-settings';
import { appLogger } from '@dotxix/log-manager';

interface XRayTranslationStateInterface {
  isActive: boolean;
  prevLangCode: string | null;
  dummyXrayLanguage: {
    code: string;
    name: string;
    rtl: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  public currentLanguageCode$ = new BehaviorSubject<string>('en');
  public currentLanguageIsRtl$ = new BehaviorSubject<boolean>(false);
  public defaultLanguageIsRtl$ = new BehaviorSubject<boolean>(false);
  public dictionaryReloaded = new Subject<void>();

  private _dictionary: { [key: string]: { default: string; languages: { [code: string]: string } } } = {};
  private _languages: Language[] = [];
  private _defaultLanguage = 'en';
  private _currentLanguage = 'en';
  private _currentLanguageUpperCase = 'EN';

  private xRayTranslationState: XRayTranslationStateInterface = {
    isActive: false,
    prevLangCode: null,
    dummyXrayLanguage: {
      code: 'xray',
      name: 'Xray',
      rtl: false,
    },
  };

  public get currentLanguageUpperCase() {
    return this._currentLanguageUpperCase;
  }

  constructor(
    private appSettings: ApplicationSettingsService,
    private contentService: ContentService
  ) {}

  public initialize() {
    this.updateDictionary();
    this.appSettings.settings$.subscribe((settings) => this.onAppSettingsChanged(settings));
    this.contentService.dataChanged$.subscribe(() => this.updateDictionary());
    this.listenForXray();
  }

  private onAppSettingsChanged(settings: AppSettings) {
    this._defaultLanguage = settings.defaultLanguage.toLowerCase();
    this._languages = settings.languages.map((language) => ({ ...language, code: language.code.toLowerCase() }));
    appLogger.debug(`setting default language ${this._defaultLanguage}`);
    this.setCurrentLanguage(this._defaultLanguage);

    const defaultLanguage = this._languages.find((language) => language.code === this._defaultLanguage);
    if (defaultLanguage) {
      this.defaultLanguageIsRtl$.next(!!defaultLanguage.rtl);
    }
  }

  /**
   * Will set current language based on language code.
   *
   * If languageCode doesn't exist in _languages array, will go for first language
   *
   * @param languageCode The current selected language code
   */
  public setCurrentLanguage(languageCode: string) {
    const lowerCaseLanguageCode = languageCode.toLowerCase();
    let language = this._languages.find((language) => language.code === lowerCaseLanguageCode);
    if (!language) {
      language = this._languages.first();
    }
    if (language) {
      DotSessionService.getInstance().setCurrentLanguage(language.code);
      this.applyRTL(language);
      this._currentLanguage = language.code;
      this._currentLanguageUpperCase = this._currentLanguage.toUpperCase();
      document.documentElement.lang = lowerCaseLanguageCode;
      this.currentLanguageCode$.next(this._currentLanguage);
    }
  }

  public applyRTL(language: Language) {
    if (language.rtl) {
      document.body.classList.add('rtl');
      this.currentLanguageIsRtl$.next(true);
    } else {
      document.body.classList.remove('rtl');
      this.currentLanguageIsRtl$.next(false);
    }
  }

  public translate(id: string): string {
    if (!this._currentLanguage) {
      return id;
    }
    const translatedText = this.translateFor(this._currentLanguage, id);
    return translatedText.replaceAll('|', '<br/>');
  }

  public translateInDefLang(id: string) {
    return this.translateFor(this._defaultLanguage, id);
  }

  public translateFor(languageCode: string, id: string) {
    if (!id) {
      return '';
    }

    if (this.currentLanguageCode$.value === this.xRayTranslationState.dummyXrayLanguage.code) {
      return id;
    }

    const translationKey = this._dictionary[id];
    if (!translationKey) {
      return id;
    }

    const translation = translationKey.languages[languageCode];
    if (translation) {
      return translation;
    }
    if (translationKey.default) {
      return translationKey.default;
    }
    return id;
  }

  public translateTitle(title: DotI18n | string): string {
    return this.translateTitleInLang(title, this._currentLanguage);
  }

  public translateTitleInLang(title: DotI18n | string, langCode: string): string {
    if (typeof title === 'string') {
      return title;
    }

    if (typeof title === 'object') {
      if (typeof langCode !== 'string' || typeof title[langCode.toUpperCase()] !== 'string') {
        return title.DEF;
      }
      return title[langCode.toUpperCase()];
    }

    return '';
  }

  public translateTitleInDefLang(title: DotI18n | string) {
    return this.translateTitleInLang(title, this.appSettings.settings$.value.defaultLanguage);
  }

  public getTranslatedButtonCaption(item: DotButton): string | null {
    if (item && item.CaptionDictionary) {
      const t = item.CaptionDictionary[this._currentLanguageUpperCase];
      return t ? t : item.CaptionDictionary.DEF;
    } else if (item && item.Caption) {
      return item.Caption;
    } else {
      return null;
    }
  }

  public getTranslatedButtonPicture(item: DotButton): string | null {
    if (item && item.PictureDictionary && Object.keys(item.PictureDictionary).length !== 0) {
      const t = item.PictureDictionary[this._currentLanguageUpperCase];
      return t ? t : item.PictureDictionary.DEF;
    } else if (item && item.Picture) {
      return item.Picture;
    } else {
      return null;
    }
  }

  public getTranslatedDlgMessage(button: DotButton): string {
    if (button && button.DlgMessageDictionary && Object.keys(button.DlgMessageDictionary).length !== 0) {
      const t = button.DlgMessageDictionary[this.currentLanguageUpperCase];
      return t ? t : button.DlgMessageDictionary.DEF;
    } else if (button && button.DlgMessage) {
      return button.DlgMessage;
    } else {
      return '';
    }
  }

  public getTranslatedButtonDescription(button: DotButton): string | null {
    if (button.DescriptionDictionary) {
      if (button.DescriptionDictionary[this.currentLanguageUpperCase]) {
        return button.DescriptionDictionary[this.currentLanguageUpperCase];
      } else if (button.DescriptionDictionary['DEF']) {
        return button.DescriptionDictionary['DEF'];
      }
    }
    return button.Description || null;
  }

  public translateComboStepName(combo: DotCombo): string | null {
    if (combo && combo.ComboStepNameDictionary && Object.keys(combo.ComboStepNameDictionary).length !== 0) {
      const t = combo.ComboStepNameDictionary[this.currentLanguageUpperCase];
      return t ? t : combo.ComboStepNameDictionary.DEF;
    } else if (combo && combo.ComboStepName) {
      return combo.ComboStepName;
    } else {
      return null;
    }
  }

  public translateComboImage(combo: DotCombo): string | null {
    if (combo && combo.ImageDictionary && Object.keys(combo.ImageDictionary).length !== 0) {
      const t = combo.ImageDictionary[this.currentLanguageUpperCase];
      return t ? t : combo.ImageDictionary.DEF;
    } else if (combo && combo.Image) {
      return combo.Image;
    } else {
      return null;
    }
  }

  public translateModifierName(modifier: DotModifier): string | null {
    if (!modifier) {
      return null;
    }
    if (modifier.PageInfo.NameDictionary && Object.keys(modifier.PageInfo.NameDictionary).length !== 0) {
      const t = modifier.PageInfo.NameDictionary[this.currentLanguageUpperCase];
      return t ? t : modifier.PageInfo.NameDictionary.DEF;
    }
    return modifier.PageInfo.Name;
  }

  public translateModifierImage(modifier: DotModifier): string | null {
    if (modifier && modifier.PageInfo && modifier.PageInfo.ImageDictionary && Object.keys(modifier.PageInfo.ImageDictionary).length !== 0) {
      const t = modifier.PageInfo.ImageDictionary[this.currentLanguageUpperCase];
      return t ? t : modifier.PageInfo.ImageDictionary.DEF;
    } else if (modifier.PageInfo && modifier.PageInfo.Image) {
      return modifier.PageInfo.Image;
    } else {
      return null;
    }
  }

  private updateDictionary() {
    this._dictionary = {};
    (DotI18nLoader.getInstance().loadedModel || []).forEach((value: DotI18n) => {
      let id = 0;
      let def = '';
      const lang: { [key: string]: string } = {};
      Object.keys(value).forEach((key) => {
        if (key.toLowerCase() === 'id') {
          id = Number(value[key]);
          return;
        }
        if (key.toLowerCase() === 'def') {
          def = value[key];
          return;
        }
        lang[key.toLowerCase()] = value[key];
        return;
      });
      this._dictionary[id] = {
        default: def,
        languages: lang,
      };
    });
    this.dictionaryReloaded.next();
    appLogger.debug(`Translation dictionary loaded (size: ${Object.keys(this._dictionary).length})`);
  }

  public getTranslatedNutritionalDescription(nutritionalValue: DotNutritionalValue): string {
    if (nutritionalValue && nutritionalValue.DescriptionDictionary && Object.keys(nutritionalValue.DescriptionDictionary).length !== 0) {
      const t = nutritionalValue.DescriptionDictionary[this.currentLanguageUpperCase];
      return t ? t : nutritionalValue.DescriptionDictionary.DEF;
    } else {
      return '';
    }
  }

  public getTranslatedNutritionalLabel(nutritionalValue: DotNutritionalValue): string {
    if (nutritionalValue && nutritionalValue.LabelDictionary && Object.keys(nutritionalValue.LabelDictionary).length !== 0) {
      const t = nutritionalValue.LabelDictionary[this.currentLanguageUpperCase];
      return t ? t : nutritionalValue.LabelDictionary.DEF;
    } else if (nutritionalValue && nutritionalValue.Label) {
      return nutritionalValue.Label;
    }
    return '';
  }

  public getTranslatedNutritionalUnit(nutritionalValue: DotNutritionalValue): string {
    if (nutritionalValue && nutritionalValue.UnitDictionary && Object.keys(nutritionalValue.UnitDictionary).length !== 0) {
      const t = nutritionalValue.UnitDictionary[this.currentLanguageUpperCase];
      return t ? t : nutritionalValue.UnitDictionary.DEF;
    }
    return nutritionalValue?.Label ?? '';
  }

  private listenForXray() {
    document.addEventListener('keypress', (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.code === 'KeyX') {
        if (this.xRayTranslationState.isActive) {
          this.xRayTranslationState.isActive = false;
          this._languages.remove(this.xRayTranslationState.dummyXrayLanguage);
          this.setCurrentLanguage(this.xRayTranslationState.prevLangCode ?? '');
        } else {
          this.xRayTranslationState.isActive = true;
          this._languages.push(this.xRayTranslationState.dummyXrayLanguage);
          this.xRayTranslationState.prevLangCode = this.currentLanguageCode$.value;
          this.setCurrentLanguage(this.xRayTranslationState.dummyXrayLanguage.code);
        }
      }
    });
  }
}
