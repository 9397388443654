import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { Component } from '@angular/core';
import { OrderRecallModes } from '@dotxix/models/enums/order-recall-modes';

@Component({
  selector: 'acr-order-number',
  templateUrl: './order-number.component.html',
})
export class OrderNumberComponent {
  public OrderRecallModes = OrderRecallModes;
  public orderRecallMode = this.applicationSettingsService.settings$.value.orderRecallMode;
  public get orderNumber(): number {
    return this.checkoutService.orderPOSNumber || 999;
  }
  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    protected checkoutService: CheckoutService
  ) {}
}
