import { Component, OnDestroy } from '@angular/core';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { SessionService } from '@dotxix/services/session.service';
import { Subscription } from 'rxjs';
import { OrderFlowService } from '@dotxix/services/order-flow.service';

@Component({
  selector: 'acr-start-over',
  templateUrl: './start-over.component.html',
})
export class StartOverComponent implements OnDestroy {
  public subscriptions: Subscription[] = [];

  constructor(
    protected dynamicContentService: DynamicContentService,
    protected sessionService: SessionService,
    protected checkoutService: CheckoutService,
    private orderFlowService: OrderFlowService
  ) {}

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  public click(): void {
    this.orderFlowService.onCancelOrder().then();
  }
}
