<ng-container *ngIf="firstSuggestionButton">
  <div class="basket-suggestions">
    <div class="basket-suggestions__wrapper">
      <img
        class="basket-suggestions__img"
        [src]="firstSuggestionButton | translatePicture"
        [class.logo]="!(firstSuggestionButton | translatePicture)"
        alt=""
      />
      <div class="flex flex--column">
        <h5 class="basket-suggestions__title">{{ '2022090701' | translate }}</h5>
        <h3 class="basket-suggestions__button-title">{{ firstSuggestionButton | translateCaption }}</h3>
        <h5 class="basket-suggestions__button-price">{{ firstSuggestionButton | calculateButtonPrice | dotCurrency }}</h5>
      </div>
    </div>
    <button class="ui-button ui-button--icon-button" data-automation="Button: Add" (click)="onSuggestionSelect(firstSuggestionButton)">
      <img skin-part="image" src="/Channels/skin/icon-outlinePlus.svg" class="ui-button--icon size--50 color--lightGray" alt="" />
    </button>
  </div>
</ng-container>
