import { Component } from '@angular/core';
import { EPayQrCodeScreenSteps } from '@dotxix/payment/models/qr-code-screen-steps.enum';
import { ElectronicPaymentFlowService } from '@dotxix/payment/services/electronic-payment.flow.service';
import { ElectronicPaymentService } from '@dotxix/payment/services/electronic-payment.service';

@Component({
  selector: 'acr-electronic-payment',
  templateUrl: './electronic-payment.component.html',
})
export class ElectronicPaymentComponent {
  public electronicPayment$ = this.electronicPaymentService.ePayMessage$;
  public qrCodeScreenStep$ = this.electronicPaymentService.ePayScreenStep$;
  public canceling$ = this.electronicPaymentService.canceling$;
  public qrCodeScreenSteps = EPayQrCodeScreenSteps;
  public autoCancelCounter$ = this.electronicPaymentService.autoCancelCounter$;

  constructor(
    private electronicPaymentService: ElectronicPaymentService,
    private electronicPaymentFlowService: ElectronicPaymentFlowService
  ) {}

  public cancelClick() {
    this.electronicPaymentFlowService.onCancelPayClicked();
  }
}
