import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { GloryCashFlowService } from '@dotxix/payment/services/glory-cash-flow.service';
import { lastValueFrom, Subject } from 'rxjs';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { CardPaymentFlowService } from '@dotxix/payment/services/card-payment.flow.service';
import { markFirstPayStartTime } from '@dotxix/payment/helpers/payment-elog.helper';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { CheckoutErrorParams } from '@dotxix/models/interfaces/checkout-error-params';
import { StatusService } from '@dotxix/services/status.service';
import { GloryCashLegalRequirementsService } from '@dotxix/payment/services/glory-cash-legal-requirements.service';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { ElectronicPaymentFlowService } from '@dotxix/payment/services/electronic-payment.flow.service';
import { PayTowerPaymentFlowService } from '@dotxix/payment/services/pay-tower-payment-flow.service';

@Injectable({ providedIn: 'root' })
export class PaymentFlowService {
  public readyToPayWithPayment$ = new Subject<PaymentType>();
  public cancelOrder$ = new Subject<CheckoutErrorParams>();
  public orderCompletelyPaid$ = new Subject<void>();
  public orderFinishedWithPreorder$ = new Subject<void>();
  public orderFinishedWithPayTower$ = new Subject<void>();

  constructor(
    private router: Router,
    private checkoutService: CheckoutService,
    private gloryCashFlowService: GloryCashFlowService,
    private paymentService: PaymentService,
    private cardPaymentFlowService: CardPaymentFlowService,
    private dynamicContentService: DynamicContentService,
    private statusService: StatusService,
    private gloryCashLegalRequirementsService: GloryCashLegalRequirementsService,
    private translationsService: TranslationsService,
    private ePaymentFlowService: ElectronicPaymentFlowService,
    private payTowerPaymentFlowService: PayTowerPaymentFlowService
  ) {
    this.cardPaymentFlowService.cardPaymentWithSuccess$.subscribe(() => this.onPaymentSuccess());
    this.cardPaymentFlowService.displayPaymentSelection$.subscribe(() => this.displayPaymentSelection());
    this.cardPaymentFlowService.cancelOrderOnPaymentError$.subscribe((checkoutErrorParams) =>
      this.cancelOrderOnCardPaymentError(checkoutErrorParams)
    );
    this.gloryCashFlowService.displayPaymentSelection$.subscribe(() => this.displayPaymentSelection());
    this.gloryCashFlowService.cashPaymentTransactionFinished$.subscribe(() => this.onPaymentSuccess());
    this.gloryCashFlowService.cashPaymentStartAcceptMoneyError$.subscribe(() => this.onCashPaymentStartAcceptMoneyError());
    this.ePaymentFlowService.electronicPaymentWithSuccess$.subscribe(() => this.onPaymentSuccess());
    this.ePaymentFlowService.displayPaymentSelection$.subscribe(() => this.displayPaymentSelection());
    this.payTowerPaymentFlowService.printedPayTowerReceiptWithQrCode$.subscribe(() => this.onPrintedPayTowerReceiptWithQrCode());
    this.payTowerPaymentFlowService.error$.subscribe(() => this.onPayTowerError());
  }

  public async displayPaymentSelection() {
    return this.router.navigate([PaymentRoutes.PaymentSelection]);
  }

  public userSelectedPaymentType(paymentType: PaymentType) {
    this.approvePayingWith(paymentType);
  }

  public autoSelectedPaymentType(paymentType: PaymentType) {
    this.approvePayingWith(paymentType);
  }

  public startPayment(paymentType: PaymentType) {
    markFirstPayStartTime();
    switch (paymentType.PaymentType) {
      case PAYMENT_TYPE.CARD: {
        this.cardPaymentFlowService.startPayment(paymentType);
        break;
      }
      case PAYMENT_TYPE.CASH: {
        this.gloryCashFlowService.startPayment(paymentType);
        break;
      }
      case PAYMENT_TYPE.PREORDER: {
        this.orderFinishedWithPreorder$.next();
        break;
      }
      case PAYMENT_TYPE.EPAY: {
        this.ePaymentFlowService.startPayment(paymentType);
        break;
      }
      case PAYMENT_TYPE.PAY_TOWER: {
        this.payTowerPaymentFlowService.startPayment();
        break;
      }
    }
  }

  public async cancelAllPaymentsWithConfirmation() {
    const response = await this.confirmCancelAllPayments();
    if (response) {
      await this.gloryCashFlowService.cashBack();
    }
    return response;
  }

  private async approvePayingWith(paymentType: PaymentType) {
    if (
      this.paymentService.computeMaximumAmountThatCanBePaidAtTransactionStart(this.checkoutService.getAmountOwed()) <
        this.checkoutService.getAmountOwed() &&
      this.checkoutService.paymentType === PAYMENT_TYPE.CASH
    ) {
      if (this.statusService.state$.value.availablePayments.length === 1) {
        this.gloryCashLegalRequirementsService.showUnableToCheckout();
        return;
      }

      if (this.statusService.state$.value.availablePayments.length === 2) {
        // Only cash and preorder payment types are enabled
        const preorderPaymentType = this.statusService.state$.value.availablePayments.find(
          (paymentType) => paymentType.PaymentType === PAYMENT_TYPE.PREORDER && paymentType.PaymentIsEnabled
        );
        if (preorderPaymentType) {
          // Preorder is not a viable payment method for partial payment
          this.gloryCashLegalRequirementsService.showUnableToCheckout();
          return;
        }
      }
    }

    this.checkoutService.paymentType = paymentType.PaymentType;
    let approved = true;
    if (paymentType.PaymentType === PAYMENT_TYPE.CASH) {
      approved = await this.gloryCashLegalRequirementsService.approveOrderTotal();
    }

    if (approved) {
      this.readyToPayWithPayment$.next(paymentType);
    } else {
      this.displayPaymentSelection();
    }
  }

  private onPaymentSuccess() {
    if (this.checkoutService.getAmountOwed() <= 0) {
      this.orderCompletelyPaid$.next();
    } else {
      this.displayPaymentSelection();
    }
  }

  private onPrintedPayTowerReceiptWithQrCode() {
    this.orderFinishedWithPayTower$.next();
  }

  private async cancelOrderOnCardPaymentError(checkoutErrorParams: CheckoutErrorParams = {}) {
    await this.gloryCashFlowService.cashBack();
    this.cancelOrder$.next(checkoutErrorParams);
  }

  private async onCashPaymentStartAcceptMoneyError() {
    this.displayPaymentSelection();
  }

  private async onPayTowerError() {
    const payTowerPayment = this.statusService.state$.value.availablePayments.find(
      (paymentType) => paymentType.PaymentType === PAYMENT_TYPE.PAY_TOWER
    );
    if (payTowerPayment) {
      this.statusService.removePaymentOptionForCurrentSession(payTowerPayment);
    }
    this.displayPaymentSelection();
  }

  private async confirmCancelAllPayments(): Promise<boolean> {
    const response = await lastValueFrom(
      this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: this.translationsService.translate('2001009'),
        rightButtonText: this.translationsService.translate('33'),
        leftButtonText: this.translationsService.translate('32'),
      }).afterClosed
    );
    return response === ConfirmDialogButtons.RIGHT_BUTTON;
  }
}
