import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentState } from '@dotxix/payment/models/payment-state.interface';
import { SessionService } from '@dotxix/services/session.service';
import { GloryCashPaymentView } from '@dotxix/payment/models/glory-cash-payment-view.enum';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public state$ = new BehaviorSubject<PaymentState>(this.initialPaymentState());

  constructor(
    private sessionService: SessionService,
    private applicationSettingsService: ApplicationSettingsService
  ) {
    this.sessionService.onRestartSession.subscribe(() => this.state$.next(this.initialPaymentState()));
  }

  public initialPaymentState(): PaymentState {
    return {
      paidAmount: 0,
      card: {
        cardPaidAmount: 0,
        executedCardPaymentAttempts: {},
      },
      electronicPay: {
        electronicPaidAmount: 0,
        executedElectronicPaymentAttempts: {},
      },
      cash: {
        view: GloryCashPaymentView.PAYMENT_PROGRESS,

        cashPaidAmount: 0,
        cashRefundedAmount: 0,
        cashFailedToRefundAmount: 0,

        currentTransactionPaidAmount: 0,
        lastTransactionRefundedAmount: 0,
        lastTransactionFailedToRefundAmount: 0,
        currentPaymentType: null,

        maximumAmountThatCanBePaidAtTransactionStart: 0,
        currentTransactionNeedsToEndAcceptMoney: false,
        startedAcceptingMoney: false,
        endAcceptMoneyOngoing: false,

        cashBack: {
          cashBackOngoing: false,
          cashBackRefundedAmount: 0,
          cashBackFailedToRefundAmount: 0,
          cashBackParams: null,
        },
      },
    };
  }

  public onCardPaymentAttempt(paymentName: string) {
    this.state$.next({
      ...this.state$.value,
      card: {
        ...this.state$.value.card,
        executedCardPaymentAttempts: {
          ...this.state$.value.card.executedCardPaymentAttempts,
          [paymentName]: (this.state$.value.card.executedCardPaymentAttempts[paymentName] || 0) + 1,
        },
      },
    });
  }

  public onCardPaymentSuccess(amountPaid: number) {
    this.state$.next({
      ...this.state$.value,
      paidAmount: this.state$.value.paidAmount + amountPaid,
      card: {
        ...this.state$.value.card,
        cardPaidAmount: amountPaid,
      },
    });
  }

  public onElectronicPaymentAttempt(paymentName: string) {
    this.state$.next({
      ...this.state$.value,
      electronicPay: {
        ...this.state$.value.electronicPay,
        executedElectronicPaymentAttempts: {
          ...this.state$.value.electronicPay.executedElectronicPaymentAttempts,
          [paymentName]: (this.state$.value.electronicPay.executedElectronicPaymentAttempts[paymentName] || 0) + 1,
        },
      },
    });
  }

  public onElectronicPaymentSuccess(amountPaid: number) {
    this.state$.next({
      ...this.state$.value,
      paidAmount: this.state$.value.paidAmount + amountPaid,
      electronicPay: {
        ...this.state$.value.electronicPay,
        electronicPaidAmount: amountPaid,
      },
    });
  }

  public onStartCashPayment(amountOwned: number) {
    const maximumAmountThatCanBePaidAtTransactionStart = this.computeMaximumAmountThatCanBePaidAtTransactionStart(amountOwned);

    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        lastTransactionRefundedAmount: 0,
        lastTransactionFailedToRefundAmount: 0,
        maximumAmountThatCanBePaidAtTransactionStart: maximumAmountThatCanBePaidAtTransactionStart,
      },
    });
  }

  public computeMaximumAmountThatCanBePaidAtTransactionStart(amountOwned: number) {
    const maxCashPayableAmount = this.applicationSettingsService.settings$.value.gloryPayableAmount - this.state$.value.cash.cashPaidAmount;
    return Math.min(amountOwned, maxCashPayableAmount);
  }

  public onEndCurrentCashTransaction(
    endResultPaidAmount: number,
    endResultRefundedAmount: number,
    paymentName: string,
    transactionReference: string
  ) {
    const cashPaidAmount = Math.min(endResultPaidAmount, this.state$.value.cash.maximumAmountThatCanBePaidAtTransactionStart);
    const cashAmountPaidOverGloryLimit = endResultPaidAmount - cashPaidAmount;

    this.state$.next({
      ...this.state$.value,
      paidAmount: this.state$.value.paidAmount + cashPaidAmount,
      cash: {
        ...this.state$.value.cash,
        currentTransactionPaidAmount: 0,
        endAcceptMoneyOngoing: false,
        cashPaidAmount: this.state$.value.cash.cashPaidAmount + cashPaidAmount,
        cashRefundedAmount: this.state$.value.cash.cashRefundedAmount + endResultRefundedAmount,
        cashFailedToRefundAmount: this.state$.value.cash.cashFailedToRefundAmount + cashAmountPaidOverGloryLimit,
        lastTransactionRefundedAmount: endResultRefundedAmount,
        lastTransactionFailedToRefundAmount: cashAmountPaidOverGloryLimit,
        cashBack: {
          ...this.state$.value.cash.cashBack,
          cashBackParams: { transactionReference, paymentName },
        },
      },
    });
  }

  public onCashPaymentProgressError(errorCurrentPaidAmount: number) {
    const lastCashPaidAmount =
      typeof errorCurrentPaidAmount === 'number' ? errorCurrentPaidAmount : this.state$.value.cash.currentTransactionPaidAmount;

    this.state$.next({
      ...this.state$.value,
      paidAmount: this.state$.value.paidAmount - this.state$.value.cash.cashPaidAmount,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_PROGRESS_ERROR,
        currentTransactionPaidAmount: 0,
        cashPaidAmount: 0,
        cashFailedToRefundAmount:
          this.state$.value.cash.cashFailedToRefundAmount + lastCashPaidAmount + this.state$.value.cash.cashPaidAmount,
        lastTransactionRefundedAmount: 0,
        lastTransactionFailedToRefundAmount: lastCashPaidAmount,
      },
    });
  }

  public onCashAcceptingMoneyStarted(paymentType: PaymentType) {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_PROGRESS,
        startedAcceptingMoney: true,
        currentPaymentType: paymentType,
      },
    });
  }

  public onAcceptingMoneySuccessfully() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        startedAcceptingMoney: false,
        currentTransactionNeedsToEndAcceptMoney: true,
        lastTransactionRefundedAmount: 0,
        lastTransactionFailedToRefundAmount: 0,
      },
    });
  }

  public onCashCurrentTransactionPaidAmountUpdated(paidAmount: number) {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        currentTransactionPaidAmount: paidAmount,
      },
    });
  }

  public onPrintingCashPaymentErrorReceipt() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_PROGRESS_ERROR_PRINT,
      },
    });
  }

  public onEndAcceptMoneyStarted() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        endAcceptMoneyOngoing: true,
        currentTransactionNeedsToEndAcceptMoney: false,
      },
    });
  }

  public onCashBackStarted() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.CASHBACK_PROGRESS,
        cashBack: {
          ...this.state$.value.cash.cashBack,
          cashBackOngoing: true,
        },
      },
    });
  }

  public onCashBackFinished(cashBackRefundedAmount: number) {
    let cashFailedToRefundAmount = this.state$.value.cash.cashFailedToRefundAmount;
    let refundedFromCashPaid;
    if (this.state$.value.cash.cashFailedToRefundAmount > 0) {
      if (this.state$.value.cash.cashFailedToRefundAmount > cashBackRefundedAmount) {
        cashFailedToRefundAmount = this.state$.value.cash.cashFailedToRefundAmount - cashBackRefundedAmount;
        refundedFromCashPaid = 0;
      } else {
        cashFailedToRefundAmount = 0;
        refundedFromCashPaid = cashBackRefundedAmount - this.state$.value.cash.cashFailedToRefundAmount;
      }
    } else {
      refundedFromCashPaid = cashBackRefundedAmount;
    }

    const cashPaidAmount = this.state$.value.cash.cashPaidAmount - refundedFromCashPaid;

    this.state$.next({
      ...this.state$.value,
      paidAmount: this.state$.value.paidAmount - refundedFromCashPaid,
      cash: {
        ...this.state$.value.cash,
        view: cashPaidAmount === 0 ? GloryCashPaymentView.CASHBACK_SUCCESS : GloryCashPaymentView.CASHBACK_FAILED,
        cashPaidAmount,
        cashFailedToRefundAmount,
        cashBack: {
          ...this.state$.value.cash.cashBack,
          cashBackOngoing: false,
          cashBackRefundedAmount: cashBackRefundedAmount,
          cashBackFailedToRefundAmount: cashPaidAmount + cashFailedToRefundAmount,
        },
      },
    });
  }

  public onRefundAfterOverpayStarted() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_OVERPAY_REFUND_PROGRESS,
      },
    });
  }

  public onRefundAfterOverpayFailed() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_OVERPAY_REFUND_FAILED,
      },
    });
  }

  public onRefundAfterOverpaySuccess() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_OVERPAY_REFUND_SUCCESS,
      },
    });
  }

  public onRefundAfterCancelTransactionStarted() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_CANCEL_TRANSACTION_REFUND_PROGRESS,
      },
    });
  }

  public onRefundAfterCancelTransactionSuccess() {
    this.state$.next({
      ...this.state$.value,
      cash: {
        ...this.state$.value.cash,
        view: GloryCashPaymentView.PAYMENT_CANCEL_TRANSACTION_REFUND_SUCCESS,
      },
    });
  }
}
